import React, { useState } from "react";
import DownloadFileIcon from "../../images/Frame.png"; // Assurez-vous d'avoir l'image correcte
import FileUpload from "../Layouts/FileUpload";

// Fonction pour normaliser l'URL des fichiers
const sanitizeImageUrl = (url) => {
  if (!url) return "";
  if (url.startsWith("http")) return url;
  return `${process.env.REACT_APP_API_URL_IMAGE}${url}`;
};

export default function FileToDownload({
  titre,
  fichier,
  setFichier,
  errorMessage = "",
  ...props
}) {
  const [label, setLabel] = useState("Télécharger");

  // Gestionnaire de téléchargement
  const handleDownload = async () => {
    const sanitizedFichier = sanitizeImageUrl(fichier);

    if (!sanitizedFichier) {
      alert("Aucun fichier disponible pour le téléchargement !");
      return;
    }

    try {
      // Récupération du fichier sous forme de Blob
      const response = await fetch(sanitizedFichier);
      if (!response.ok) {
        throw new Error("Impossible de télécharger le fichier.");
      }
      const blob = await response.blob();

      // Création d'un lien de téléchargement
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = sanitizedFichier.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors du téléchargement :", error);
      alert("Une erreur est survenue lors du téléchargement du fichier.");
    }
  };

  return (
    <div className="documentATelecharger" {...props}>
      {/* Titre du fichier */}
      <h5 className="documentATelechargerTitre">{titre}</h5>

      <div className="documentATelechargerCarte">
        {/* Composant d'upload */}
        <FileUpload
          setLabel={setLabel}
          label={label}
          acceptedTypes=".png, .jpeg, .pdf"
          existingFile={fichier}
          setExistingFile={setFichier}
        />

        {/* Consignes et actions */}
        <div className="exportDataButton mt-2" onClick={handleDownload}>

          <div
            className=" cursor"
            style={{ marginLeft: "10px" }}
          >
            <span>Télécharger</span>
            <img src={DownloadFileIcon} alt="Download" />

          </div>
        </div>
      </div>

      {/* Message d'erreur */}
      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}

