import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import api from "../../api/axiosInstance";

const Chart4 = ({ selectDash }) => {  // Accept selectedYear as a prop
  const [series, setSeries] = useState([
    {
      name: 'Partenaire', // Static data for this
      data: [15, 50, 16, 19, 9, 11, 21, 12, 13, 8, 30, 40]
    },
    {
      name: 'Etudiants',
      data: Array(12).fill(0)
    },
    {
      name: 'Parents',
      data: Array(12).fill(0)
    }
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'smooth',
      width: [3, 3, 3],
      colors: ['#008FFB', '#01E396', '#FEB019']
    },
    fill: {
      colors: ['#008FFB', '#01E396', '#FEB019'],
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#008FFB', '#01E396', '#FEB019'],
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 100]
      }
    },
    xaxis: {
      categories: [
        "2024-01-01T00:00:00.000Z", "2024-02-01T00:00:00.000Z", "2024-03-01T00:00:00.000Z",
        "2024-04-01T00:00:00.000Z", "2024-05-01T00:00:00.000Z", "2024-06-01T00:00:00.000Z",
        "2024-07-01T00:00:00.000Z", "2024-08-01T00:00:00.000Z", "2024-09-01T00:00:00.000Z",
        "2024-10-01T00:00:00.000Z", "2024-11-01T00:00:00.000Z", "2024-12-01T00:00:00.000Z"
      ],
      labels: {
        formatter: function(value) {
          const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          return monthNames[new Date(value).getMonth()];
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        formatter: (value) => `${value}`
      }
    },
    tooltip: {
      x: { format: 'MMM yyyy' }
    }
  });

  const processMonthlyData = (data, year) => {
    const monthlyCounts = Array(12).fill(0);

    data.forEach(item => {
      const date = new Date(item.created_at);
      if (date.getFullYear() === year) {
        monthlyCounts[date.getMonth()]++;
      }
    });

    return monthlyCounts;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [studentsResponse, parentsResponse] = await Promise.all([
          api.get("/admin/students/"),
          api.get("/admin/parents/")
        ]);

        const students = studentsResponse.data.students;
        const parents = parentsResponse.data;

        const studentMonthlyData = processMonthlyData(students, selectDash);
        const parentMonthlyData = processMonthlyData(parents, selectDash);

        setSeries([
          {
            name: 'Partenaire',
            data: [15, 50, 16, 19, 9, 11, 21, 12, 13, 8, 30, 40] 
          },
          {
            name: 'Etudiants',
            data: studentMonthlyData
          },
          {
            name: 'Parents',
            data: parentMonthlyData
          }
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectDash]); // Update data when year changes

  return (
    <div>
      <ReactApexChart options={options} series={series} type="area" height={260} />
    </div>
  );
};

export default Chart4;