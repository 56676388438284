import React, { useState, useEffect } from "react";
import "./notification.css";
import iconDocument from "../../images/document-logo.png";
import iconSpam from "../../images/icon-spam.png";
import iconBank from "../../images/icon-bank.png";
import { Container } from "react-bootstrap";
import api from "../../StudentModule/api/axiosInstance";
import { FaCheckCircle } from "react-icons/fa"; // Importing a check icon from react-icons

function NotificationList() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false); // State to control the confirmation prompt
  const notificationsPerPage = 10; // Nombre de notifications par page

  const getIcon = (type) => {
    switch (type) {
      case "document":
        return iconDocument;
      case "spam":
        return iconSpam;
      case "bank":
        return iconBank;
      default:
        return iconDocument;
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "Date non disponible";
    const date = new Date(timestamp);
    if (isNaN(date)) return "Date invalide";
    const options = { hour: "2-digit", minute: "2-digit", day: "2-digit", month: "short" };
    return date.toLocaleString("fr-FR", options);
  };

const fetchNotifications = async () => {
    try {
      const { data } = await api.get("chat/notifications/");
      const formattedNotifications = data.map((notification) => ({
        ...notification,
        date:  notification.time_ago ,
        icon: getIcon(notification.type),
        isRead: notification.is_read || false,
      }));
      setNotifications(formattedNotifications);
    } catch (err) {
      setError(err.message || "Une erreur s'est produite.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsRead = async (id) => {
    try {
      await api.patch(`chat/notifications/${id}/`);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === id ? { ...notification, isRead: true } : notification
        )
      );
    } catch (err) {
      console.error("Erreur lors de la mise à jour de la notification", err);
    }
  };


  // Function to handle deleting all notifications
  const deleteAllNotifications = async () => {
    try {
      await api.delete("chat/notifications/");
      setNotifications([]);
      setShowConfirmation(false);
    } catch (err) {
      console.error("Erreur lors de la suppression de toutes les notifications", err);
      setShowConfirmation(false);
    }
  };

  // Show confirmation prompt
  const handleDeleteAllClick = () => {
    setShowConfirmation(true); // Show confirmation prompt
  };

  // Hide confirmation prompt
  const cancelDelete = () => {
    setShowConfirmation(false);
  };

  // Calcul des notifications à afficher pour la page actuelle
  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(
    indexOfFirstNotification,
    indexOfLastNotification
  );

  // Gestion des pages
  const totalPages = Math.ceil(notifications.length / notificationsPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return (
      <Container fluid className="container-notif animParent mt-4">
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="container-notif animParent mt-4">
        <div className="alert alert-danger" role="alert">
          Erreur lors du chargement des notifications : {error}
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="container-notif animParent mt-4">
      <div className="d-flex justify-content-between align-items-start mb-3">
        <h2 id="title-notif">Nouveau pour vous</h2>
       
        {/* Delete All Button */}
        <button
          onClick={handleDeleteAllClick}
          className="btn btn-danger btn-sm" // Make the button small
        >
          Supprimer tout
        </button>
      </div>

      {/* Confirmation Popup */}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="popup-content">
            <p>Êtes-vous sûr de vouloir supprimer toutes les notifications ?</p>
            <div>
              <button
                className="btn btn-danger"
                onClick={deleteAllNotifications}
              >
                Oui, supprimer
              </button>
              <button className="btn btn-secondary" onClick={cancelDelete}>
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="w-100">
        {currentNotifications.map(({ id, message, date, icon, isRead }) => (
          <NotificationItem
            key={id}
            message={message}
            date={date}
            icon={icon}
            isRead={isRead}
            onMarkAsRead={() => markAsRead(id)}
          />
        ))}
      </div>

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          onClick={goToPreviousPage}
          className="btn btn-outline-primary"
          disabled={currentPage === 1}
        >
          Précédent
        </button>
        <span>
          Page {currentPage} sur {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          className="btn btn-outline-primary"
          disabled={currentPage === totalPages}
        >
          Suivant
        </button>
      </div>
    </Container>
  );
}

const NotificationItem = ({ message, date, icon, isRead, onMarkAsRead }) => (
  <div
    className={`notification-bar row d-flex justify-content-between align-items-start ${
      isRead ? "read" : "unread"
    }`}
  >
    <div className="col-2 col-sm-1">
      <img src={icon} alt="Type de notification" className="logo-notif img-fluid" />
    </div>
    <div className="message-notif col-8 col-sm-10">
      <p className="p-bold">{message}</p>
      <p className="p-fit">{date}</p>
    </div>
    <div className="col-2 col-sm-1">
      {!isRead ? (
        <div className="notification-circle" onClick={onMarkAsRead} />
      ) : ("")}
    </div>
  </div>
);

export default NotificationList;