import { useState } from "react";
import InputLabel from "../../../Components/InputLabel";
import SelectLabel from "../../../Components/SelectLabel";
import { useParams } from 'react-router-dom';
import './EditAccountAVI.css'
import Button from "../../../Components/Button";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useEffect } from 'react';
import api from '../../api/axiosInstance';

export default function EditAccountAVI(){
    const { id } = useParams(); 
    const [data, setData] = useState({
        identifiant_etudiant_ufs: '',
        nom_du_candidat: '',
        prenom_du_candidat: '',
        email: '',
        date_de_naissance: '',
        lieu_de_naissance: '',
        statut_du_candidat: '',
        compte_bancaire: '',
        acceptation: '',
        candidat_universitaire: '',
        annee_scolaire: '',
        sexe: '',
        guichet: '',
        numero_compte: '',
        cle_rib: '',
        bic: '',
      });

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get(`/admin/candidates/${id}/`);
            setData(response.data);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }, [id]);

      

      const handleUpdate = async (e) => {
        e.preventDefault();
        console.log(data); // Verify data
        try {
          const response = await api.put(`/admin/candidates/${id}/`, data);
          console.log(response); // Verify response
          navigate(-1);
        } catch (error) {
          console.error(error.response.data); // Detailed error message
        }
      };

    const updateField = e => {
        setData(data => ({
            ...data,
            [e.target.name] : e.target.value
        }))
    }

    
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // Retourne à la page précédente
    };
    
    return(
        <>
    <Col>
      <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
    </Col>
    <Col className="editAccountAVI">
      <h2 className="editAccountAVITitle">Compte AVI</h2>

      <div className="editAccountAVIMain">
        <InputLabel
          name={'identifiant_etudiant_ufs'}
          label={'Identifiant étudiant UFS'}
          placeholder={'Entrez l\'identifiant'}
          value={data.identifiant_etudiant_ufs}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'nom_du_candidat'}
          label={'Nom du candidat'}
          placeholder={'Entrez le nom'}
          value={data.nom_du_candidat}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'prenom_du_candidat'}
          label={'Prénom du candidat'}
          placeholder={'Entrez le prénom'}
          value={data.prenom_du_candidat}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'email'}
          label={'Email'}
          placeholder={'Entrez l\'email'}
          value={data.email}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'date_de_naissance'}
          label={'Date de naissance'}
          type="date"
          value={data.date_de_naissance}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'lieu_de_naissance'}
          label={'Lieu de naissance'}
          placeholder={'Entrez le lieu de naissance'}
          value={data.lieu_de_naissance}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'statut_du_candidat'}
          label={'Statut du candidat'}
          placeholder={'Entrez le statut'}
          value={data.statut_du_candidat}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'compte_bancaire'}
          label={'Compte bancaire'}
          placeholder={'Entrez le compte bancaire'}
          value={data.compte_bancaire}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'acceptation'}
          label={'Acceptation'}
          placeholder={'Entrez l\'acceptation'}
          value={data.acceptation}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'candidat_universitaire'}
          label={'Université'}
          placeholder={'Entrez l\'université'}
          value={data.candidat_universitaire}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'annee_scolaire'}
          label={'Année d\'études'}
          placeholder={'Entrez l\'année d\'études'}
          value={data.annee_scolaire}
          onChange={(e) => updateField(e)}
        />
        <SelectLabel
          name={'sexe'}
          label={'Genre'}
          options={['M', 'F']}
          value={data.sexe}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'guichet'}
          label={'Ticket d\'office'}
          placeholder={'Entrez le ticket d\'office'}
          value={data.guichet}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'numero_compte'}
          label={'Numero de compte'}
          placeholder={'Entrez le numéro de compte'}
          value={data.numero_compte}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'cle_rib'}
          label={'Clé RIB'}
          placeholder={'Entrez la clé RIB'}
          value={data.cle_rib}
          onChange={(e) => updateField(e)}
        />
        <InputLabel
          name={'bic'}
          label={'BIC'}
          placeholder={'Entrez le BIC'}
          value={data.bic}
          onChange={(e) => updateField(e)}
        />
      </div>

      <Button value={'Sauvegarder'} onClick={(e) => handleUpdate(e)}/>
    </Col>
  </>
    )
}
