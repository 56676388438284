import React, { useRef, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import api from '../../api/axiosInstance';
import "../../Student_Admin/Student.css";
import next from "../../../images/nextAdd.png";
import profile from "../../../images/inpProfil.png";
import add from "../../../images/add.png";
import { useNavigate } from 'react-router';
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect';

function AdminUpdate() {
    const fileInputRef = useRef(null);
    const [codeCountry, setCodeCountry] = useState("");
    const [universities, setUniversities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [picture, setPicture] = useState(null);
    const [profil, setProfil] = useState(profile);
    const [country, setCountry] = useState('');  // New state to store the country name

    const navigate = useNavigate();

    useEffect(() => {
        fetch("http://universities.hipolabs.com/search?country=France")
            .then(response => response.json())
            .then(data => {
                const sortedUniversities = data.sort((a, b) => a.name.localeCompare(b.name));
                setUniversities(sortedUniversities);
            })
            .catch(error => console.error('Erreur lors de la récupération des universités:', error));
    }, []);

    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then(response => response.json())
            .then(data => {
                const sortedCountries = data.sort((a, b) =>
                    a.name.common.localeCompare(b.name.common)
                );
                setCountries(sortedCountries);
            })
            .catch(error => console.error('Error fetching countries:', error));
    }, []);

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setPicture(file); // Update picture state
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfil(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCodeCountry = (code) => {
        setCodeCountry(code);
    };

    const handleCountryChange = (countryName) => {
        setCountry(countryName);  // Update country state with the selected country name
    };

    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent form reload
    
        // Create the FormData object to handle file uploads
        const formData = new FormData();
        formData.append("first_name", name);
        formData.append("last_name", surname);
        formData.append("date_of_birth", birthdate);
        formData.append("country", country);  // Send the country name, not the country code
        formData.append("phone_number", phone);
        formData.append("country_code", codeCountry);  // Country code from the country select component
        formData.append("email", email);
        formData.append("role", "admin");  // Static value for the role
        formData.append("password", password);
        formData.append("password_confirm", confirmPassword);
        formData.append("gender", gender);
        if (picture) formData.append("picture", picture);  // Append the picture if exists
    
        try {
            // Send the form data (including file) as multipart/form-data
            const response = await api.post("/admin/list_Admin/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",  // Set content type to multipart/form-data
                },
            });
            console.log('Admin created successfully:', response.data);
            navigate("/administrateur/administrateurs/");  // Redirect after successful submission
        } catch (error) {
            console.error('Error creating admin:', error.response ? error.response.data : error);
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <span className='RouteStu'>
                        <p className='routeStu'>Administrateur</p>
                        <p className='RouteStuRoute'>/ Ajouter Administrateur </p>
                    </span>
                </Col>
                <Col>
                    <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
                </Col>
            </Row>
            <Row>
                <Col className="addStu1">
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={6}>
                                <p className='titleEtu'>Compte Administrateur</p>
                            </Col>
                            <Col sm={6}>
                                <span className='spanInpImg'>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        className='imgInpModif'
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                    <img
                                        src={profil}
                                        alt="Cliquez pour changer l'image"
                                        className="spanInpImg img-fluid img-rounded"
                                        onClick={() => fileInputRef.current.click()}
                                        style={{ cursor: 'pointer', borderRadius: '50%' }}
                                    />
                                    <img src={add} className='addImg' onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }} />
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <p className='labelInp'>Nom</p>
                                <input
                                    type='text'
                                    placeholder='Votre Nom'
                                    className='inpStu'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Col>
                            <Col sm={6}>
                                <p className='labelInp'>Prenom</p>
                                <input
                                    type='text'
                                    placeholder='Votre Prénom'
                                    className='inpStu'
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <p className='labelInp'>Numero de telephone</p>
                                <Row>
                                    <Col sm={3}>
                                        <CustomCountrySelectAdmin codeCountry={handleCodeCountry} />
                                    </Col>
                                    <Col>
                                        <input
                                            type='text'
                                            placeholder='XXX XXX XXXX'
                                            className='inpTelEtu'
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <p className='labelInp'>Date De Naissance</p>
                                <input
                                    type='date'
                                    className='inpStu'
                                    value={birthdate}
                                    onChange={(e) => setBirthdate(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <p className='labelInp'>Pays</p>
                                <select
                                    className='inpStu'
                                    value={country}
                                    onChange={(e) => handleCountryChange(e.target.value)}
                                >
                                    <option value="">Selectionner</option>
                                    {countries.map(country => (
                                        <option key={country.cca3} value={country.name.common}>
                                            {country.name.common}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm={6}>
                                <p className='labelInp'>Adresse Email</p>
                                <input
                                    type='email'
                                    placeholder='Votre Adresse Email'
                                    className='inpStu'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>

                            <Col sm={6}>
                                <p className='labelInp'>Mots de passe</p>
                                <input
                                    type='password'
                                    placeholder='Votre Mots De Passe'
                                    className='inpStu'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Col>
                            <Col sm={6}>
                                <p className='labelInp'>Confirmez le mots de passe</p>
                                <input
                                    type='password'
                                    placeholder='Confirmez Votre Mots De Passe'
                                    className='inpStu'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className='rowBtnAdd'>
                            <Col>
                                <button className='creer' type="submit">
                                    Créer
                                    <img src={next} className='imgbtnadd' />
                                </button>
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminUpdate;
