import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import '../Dash.css'
import base from "../../../images/Base.png"
import side2 from "../../../images/side2.png"
import { error ,success} from "../../utils/alerts";
import api from "../../api/axiosInstance";
function Chart2() {
    const [NBparents, setNBparents] = useState([]); // État pour les paiements
  
    const [loading, setLoading] = useState(true);
    useEffect(() => {
  
      
  
      fetchParents();
    }, []);
  const fetchParents = async () => {
    try {
      const response = await api.get("/admin/parents/");
  
      // Si la réponse est un tableau, calculez la taille
      if (Array.isArray(response.data)) {
        setNBparents(response.data.length);
      } else {
        console.error("La réponse de l'API n'est pas un tableau.");
        setNBparents(0);
      }
  
      setLoading(false); // Met à jour l'état de chargement
    } catch (err) {
      error("Erreur lors de la récupération des données.");
      setLoading(false); // Met à jour l'état de chargement en cas d'erreur
    }
  };
    const options = {
        chart: {
          height: "89px",
          width: "89px",
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "50%",
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: 0,
                show: true,
                color: "#7E3FB6", // Custom color
                fontSize: "12px", // Custom font size
                style: {
                  fontFamily: 'Poppins', // Custom font family
                  fontStyle: 'normal',
                  fontWeight: 700, // Bold text
                  lineHeight: '50px',
                },
              },
              value: {
                show: false,
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: 'dark',
            type: "horizontal", // You can adjust this to "vertical" if needed
            shadeIntensity: 1,
            gradientToColors: ["#FA9131"], // End color
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 61.47, 95.76], // Corresponding to the percentage positions
            colorStops: [
              {
                offset: 0,
                color: "#E827EC", // Start color
                opacity: 1
              },
              {
                offset: 61.47,
                color: "#F99685", // Middle color
                opacity: 1
              },
              {
                offset: 95.76,
                color: "#FA9131", // End color
                opacity: 1
              }
            ]
          }
        },
        stroke: {
          lineCap: "round",
        },
        labels: [NBparents],
      };
      const series = [50];
      const [sizeImg,SetSizeImg]=useState(180)

  // useEffect(() => {
  //   const updateSize = () => {
  //     if (window.innerWidth < 500) {
  //       SetSizeImg(150);
  //     } else {
  //       SetSizeImg(200);
  //     }
  //   };

  //   // Appelle la fonction une première fois pour régler la taille de l'image
  //   updateSize();

  //   // Ajoute un écouteur d'événements pour les redimensionnements de la fenêtre
  //   window.addEventListener('resize', updateSize);

  //   // Nettoyage de l'écouteur lors du démontage du composant
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);
  return (
    <div className='chartdiv'>
      
        <div className='CHARTT'>
            <img src={side2} className='sideChart'/>
            <Chart
              options={options}
              series={series}
              type="radialBar"
              height={sizeImg}
              width={sizeImg}
              // Set height to 200px
            />
          </div>
          <div className='chartT2'>
            <p className='titleCh'>Nombre total de Parents</p>
            <div className='sousChart'>
                <p className='pour'>{NBparents} </p>
                <img src={base} className='porImg'/>
            </div>
          </div>
     
    </div>
  )
}

export default Chart2
