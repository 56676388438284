const sanitizeImageUrl = (url) => {
  // Vérifiez si l'URL est valide et contient l'ancien domaine
  if (typeof url === 'string' && url.includes('192.162.68.92:8000')) {
    return url.replace('http://192.162.68.92:8000', process.env.REACT_APP_API_URL_IMAGE);
  }

  // Si l'URL n'est pas définie, renvoyez null
  if (!url) return null;

  // Sinon, concaténez le domaine de base avec l'URL donnée
  return `${process.env.REACT_APP_API_URL_IMAGE}${url}`;
};

export default sanitizeImageUrl;
