import React, { useEffect, useRef, useState } from 'react'
import AdminSettingsLayout from '../AdminSettingsLayout'

import ProfilPicture from '../../../images/AdminLayout/Ellipse 806.png'
import Camera from '../../../images/AdminLayout/mdi_camera.png'
import './GeneralDetails.css'
import InputLabel from '../../../Components/InputLabel'
import SelectLabel from '../../../Components/SelectLabel'
import Button from '../../../Components/Button'
import { Col, Row, Container } from 'react-bootstrap'
import CustomCountrySelect from '../../../StudentModule/Layouts/CountrySelect'
import api from '../../api/axiosInstance'
import sanitizeImageUrl from '../../../StudentModule/utils/anitizeImageUrl'
import { error, success } from "../../utils/alerts";

import { useNavigate } from 'react-router'



export default function GeneralDetails() {
    const fileInputRef = useRef(null);
  
    const [profile, setProfile] = useState({});
    const [profileImage, setProfileImage] = useState('');
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const [data, setData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        codeCountry :'',
        phoneIndicator: '',
        role: 'admin',
    })


    const updateFieldValue = e => {
        setData(data => (
            {
                ...data,
                [e.target.name]: e.target.value
            }
        ))
    }
    const updateCodeCountry = (code) => {
        setData(data => (
            {
                ...data,
                ['codeCountry']: code
            }
        ))
    }



    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await api.get(`/admin/profile/`);
            const admin = response.data;
            setData({
                nom: admin.last_name || "",
                prenom: admin.first_name || "",
                email: admin.email || "",
                phone: admin.phone_number || "",
                pays: admin.country || "",
                codeCountry : admin.country_code || "",
                role: admin.role || "admin",
                picture: admin.picture,
            })
            console.log("admin",admin)

            if (admin.picture) {
                setProfile(sanitizeImageUrl(admin.picture))
            }
        } catch (err) {
            console.log(err)
        }
    }


    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('picture', file);

        try {
            const response = await api.patch('/admin/profile/', formData);
            setProfile(sanitizeImageUrl(response.data.picture));
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'image :", error);
        }
    };

    async function handleModifier() {

        let currentErrors = {};
    
    
    
        if (!data.picture || data.picture < 1) {
          currentErrors.picture = "Vous devez fournir un photo de profile.";
        } else {
          delete currentErrors.picture;
        }
    
    
    
        const validNamePattern = /^[A-Za-z\- ]+$/;
        const validPrenomPattern = /^[A-Za-z\- ]+$/;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        const phonePattern = /^\d{9,15}$/;

    
        if (!String(data.nom).trim()) {
          currentErrors.nom = "Veuillez entrer votre nom (pas d'espaces vides)";
        } else if (!validNamePattern.test(data.nom)) {
          currentErrors.nom = "Le nom ne doit contenir que des lettres et des apostrophes.";
        } else {
          delete currentErrors.nom;
        }
    
        if (!String(data.prenom).trim()) {
          currentErrors.prenom = "Veuillez entrer votre nom (pas d'espaces vides)";
        } else if (!validPrenomPattern.test(data.prenom)) {
          currentErrors.prenom = "Le prénom ne doit contenir que des lettres et des apostrophes.";
        } else {
          delete currentErrors.prenom;
        }
    
        

    
        if (!String(data.phone).trim()) {
          currentErrors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
          
        } else if (!phonePattern.test(data.phone)) {
          currentErrors.phoneNumber = "Le numéro de téléphone doit contenir uniquement des chiffres";
        } else {
          delete currentErrors.phoneNumber;
        }

    
       
        if(currentErrors.phoneNumber){
          console.log(currentErrors.phoneNumber)
          error(currentErrors.phoneNumber)
          return
        }
        else if(currentErrors.nom){
          console.log(currentErrors.nom)

          error(currentErrors.nom)
          return
        }
        else if(currentErrors.prenom){
          console.log(currentErrors.prenom)

          error(currentErrors.prenom)
          return
        }
        else if(currentErrors.prenom){
          console.log(currentErrors.prenom)

          error(currentErrors.picture)
          return
        }


    


 

        try {

    
    
          setLoading(true);
          const formData = new FormData()
    
          formData.append("first_name", data.prenom)
          formData.append("last_name", data.nom)
          formData.append("email", data.email)
          formData.append("phone_number", data.phone)
          formData.append("country", data.pays)
          formData.append("country_code", data.codeCountry)
          formData.append("town", data.ville)
          formData.append("role", 'admin')
          // if (data.picture ) {
          //   formData.append("picture", data.picture)
          // }
    
    
          const response = await api.put(`/admin/profile/`, formData);
          if (response.status >= 200 && response.status < 300) {
            let msg = response.data?.data?.message || "Les informations de l'admin ont bien été mises à jour.";
            success(msg);
          }
          else{
            error(errors)


          }
    
        } catch (err) {
          console.log(err)
          setLoading(false)
        }
    
      }

    return (


        <AdminSettingsLayout mainTitle={'Détails Générals'}>

            <div className="generalDetails"  >
                <h5 className='PhotoTillePar'>Photo de profil</h5>
                <div className="generalDetailsPicture">
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className='imgInpModif'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}

                />
                <img
                  src={sanitizeImageUrl(profile)}
                  alt="Cliquez pour changer l'image"
                  className="spanInpImg img-fluid img-rounded"
                  onClick={() => fileInputRef.current.click()}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
                <img src={Camera} className='addImg' onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }} />

                </div>
                <form >
                <div className="generalDetailsInfos">

                  
                    <InputLabel label={'Prénom'} name={'prenom'} value={data.prenom} onChange={(e) => { setData({ ...data, prenom: e.target.value })}} placeholder={'Entrez votre prénom'} />
                    <InputLabel label={'Nom'} name={'nom'} value={data.nom} onChange={(e) => { setData({ ...data, nom: e.target.value })}}  placeholder={'Entrez votre nom'} />
                    <InputLabel label={'Email'} name={'email'} value={data.email}  placeholder={'Entrez votre adresse email'} />
                    <div className="composedInput">
                        <div className='inputLabel pb-5'>
                            <label htmlFor="codeCountry">Numero de Telephone</label>
                            <CustomCountrySelect className="" name='codeCountry' value={data.codeCountry} dialCode={data.codeCountry} codeCountry={updateCodeCountry} onChange={(e) => { setData({ ...data, codeCountry: e.target.value }) }} />
                        </div>
                        <InputLabel label={'Numéro de téléphone'} name={'phone'} placeholder={'XX-XXX-XXX'} value={data.phone} onChange={(e) => updateFieldValue(e)} />
                        {/* <SelectLabel label={'Numéro de téléphone'} options={['+221','+212','+234']} onChange ={e=>updateField(e)}/> */}
                    </div>
                    {/* <SelectLabel label={'Role'} name={'role'} value={'admin'}/> */}
                </div>
                <Button  onClick={handleModifier} value={'Modifier'}  />
                </form>
            </div>
        </AdminSettingsLayout>



    )

}





