import React, { useEffect, useState } from "react";
// import './RechercheLogement.css'
import FileToDownload from "../FileToDownload";
import SelectLabel from "../../../Components/SelectLabel";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";
import api from "../../../StudentModule/api/axiosInstance";
import countries from "../../../Layout/COUNTRY.json";
import findCitiesOfCountry from "../../../Layout/findCitiesOfCountry";
import InputLabel from "../../../Components/InputLabel";
import { PersonnalInfos } from "../RechercheLogement/RechercheLogement";
import sanitizeImageUrl from "../../../StudentModule/utils/anitizeImageUrl";

export default function ServiceBilleterie() {
  const [data, setData] = useState({
    fistname: "",
    lastname: "",
    codeCountry: '+33',

    phone: "",
    birthPlace: "",
    birthDate: "",
    email: "",
    nationnality: "",
    departureCountry: "",
    departureCity: "",
    arrivalCountry: "",
    arrivalCity: "",
    departureDate: "",
    arrivalDate: "",
    passagersCount: "",
    travelClass: "",
    preferedAirline: "",
    specialNeeds: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [villesDepart, setVillesDepart] = useState([]);
  const [villesArrivee, setVillesArrivee] = useState([]);

  const updateField = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  const navigate = useNavigate();


  const {
    departureCountry,
    departureCity,
    arrivalCountry,
    arrivalCity,
    departureDate,
    arrivalDate,
    passagersCount,
    preferedAirline,
    specialNeeds,
    travelClass,
  } = data;
  // Load cities when a country is selected
  const handleCitiesOfSelectedCountry = async (e, state) => {
    const countryName = e.target.value;
    if (state === 'depart')
      setVillesDepart(findCitiesOfCountry(countryName))
    else if (state === 'arrivee')
      setVillesArrivee(findCitiesOfCountry(countryName))

  };

 
  useEffect(() => {
    // Fetch existing ticketing data
    const fetchTicketingData = async () => {
      try {
        const response = await api.get('parent/ticketing-status/');
        if (!response) {
          throw new Error('Une erreur est survenue lors de la récupération des données.');
        }

        const result = response.data;

        // Vérifiez si des données ont été renvoyées
        if (result && result.length > 0) {
          const fetchedData = result[0]; // On prend le premier élément du tableau
          const student = fetchedData.student || {};
          const ticketing = fetchedData.ticketing || {};

          setData({
            firstname: student.first_name,
            lastname: student.last_name,
            codeCountry: student.country_code || '+33',
            phone: student.phone_number,
            birthPlace: student.town,
            birthDate: student.date_of_birth,
            email: student.email,
            nationality: student.nationality,
            departureCountry: ticketing.departure_country,
            departureCity: ticketing.departure_city,
            arrivalCountry: ticketing.destination_country,
            arrivalCity: ticketing.destination_city,
            departureDate: ticketing.departure_date,
            arrivalDate: ticketing.return_date,
            passagersCount: ticketing.number_of_passengers,
            travelClass: ticketing.travel_class,
            preferedAirline: ticketing.preferred_airlines,
            specialNeeds: ticketing.special_requests,

            passport:sanitizeImageUrl( ticketing.files.passport_copy) || null,
            documentAdditionel: sanitizeImageUrl(ticketing.files.additional_pdf) || null,
            payementPreuve: sanitizeImageUrl(ticketing.files.payment_proof) || null,
            preuveAcceptance:sanitizeImageUrl( ticketing.files.preliminary_acceptance) || null,
          });

        } else {
          setError('Aucune donnée trouvée.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
// : ticketing.destination_country
    fetchTicketingData();
  }, []);



  return (
    <form
      className="serviceBilleterie animParent rechercheLogement"
    >
      <div className="backSectionButton">
        <Button
          value={
            <>
              <IoArrowBackCircle />
              <span>Retour</span>
            </>
          }
          onClick={() => navigate("/parent/prestations-de-service")}
        />
      </div>
      <PersonnalInfos
        title={"Service de billeterie"}
        data={data}
        updateField={updateField}
        setData={setData}
      />
      <section className="detailsVoyageSection">
        <h1 className="student-attestation-title">Détails du Voyage</h1>
        <div className="detailsVoyageForm">
          <SelectLabel
            readOnly
            label={"Pays de départ"}
            name={"departure_country"}
            value={departureCountry}
          >

            <option>
              {departureCountry}
            </option>

          </SelectLabel>
          {
            villesDepart.length > 0 ?
              <SelectLabel
                readOnly
                label={"Ville de départ"}
                name="departure_city"
                onChange={(e) => updateField(e)}
              >
                <option value="" selected disabled>
                  Sélectionnez la ville de départ
                </option>
                {
                  villesDepart.map((ville) => (
                    <option key={ville} value={ville}>
                      {ville}
                    </option>
                  ))}
              </SelectLabel> :
              <InputLabel
                readOnly

                label={"Ville de départ"}
                name="departure_city"
                placeholder={'Entrez la ville de depart'}
                value={departureCity}
                onChange={(e) => updateField(e)}
              />
          }
          <SelectLabel
            readOnly
            label={"Pays de destination"}
            name={"destination_country"}
            value={arrivalCountry}
          >

            <option >
              {arrivalCountry}
            </option>
          </SelectLabel>
          {
            villesArrivee.length > 0 ?
              <SelectLabel
                readOnly
                label={"Ville de destination"}
                name="destination_city"
                onChange={(e) => updateField(e)}
              >

                {
                  villesArrivee.map((ville) => (
                    <option key={ville} value={ville}>
                      {ville}
                    </option>
                  ))}
              </SelectLabel> :

              <InputLabel
                readOnly

                label={"Ville de destination"}
                name="destination_city"
                placeholder={'Entrez la ville de destination'}
                value={arrivalCity}
                onChange={(e) => updateField(e)}
              />
          }
        
          <InputLabel
            readOnly

            label={"Date de départ"}
            name="departure_date"
            value={departureDate}
            type="date"
            placeholder={""}
            onChange={(e) => updateField(e)}
          />
          <InputLabel
            readOnly

            label={"Date de retour"}
            name="return_date"
            value={arrivalDate}
            type="date"
            placeholder={""}
            onChange={(e) => updateField(e)}
          />
          <SelectLabel
            readOnly
            label="Nombre de passagers"
            name="number_of_passengers"
            value={passagersCount}
            onChange={(e) => updateField(e)}
          >
            <option selected value={passagersCount} disabled>
              {passagersCount}
            </option>

          </SelectLabel>
          <SelectLabel
            readOnly
            label={"Classe de voyage"}
            name="travel_class"
            onChange={(e) => updateField(e)}
            value={travelClass}
          >
            <option selected value={travelClass} disabled>
              {travelClass}
            </option>
          </SelectLabel>
          <InputLabel
            readOnly

            label={"Compagnies aériennes préférées(facultative)"}
            name={"preferedAirline"}
            value={preferedAirline}
            type="text"
            placeholder={"Entrez"}
            onChange={(e) => updateField(e)}
          />

          <div className="inputLabel">
            <label htmlFor="specialNeeds" className="inputLabelLabel">
              Demandes Spéciales
            </label>
            <textarea
              name={"special_requests"}
              id="specialNeeds"
              value={specialNeeds}
              className="inputLabelInput"
            ></textarea>
          </div>
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Copie du passeport"}
            acceptanceText={"Passeport, ID Carte"}
            fieldName="files[passport_copy]"
            fichier={data.passport}
            value={data.passport}
          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Document additionnel"}
            acceptanceText={"Document Pdf"}
            fieldName="files[additional_pdf]"
            fichier={data.documentAdditionel}
            value={data.documentAdditionel}
            name="documentAdditionel"
          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Preuve de paiement"}
            acceptanceText={"Document Pdf"}
            fieldName="files[payment_proof]"
            fichier={data.payementPreuve}
            value={data.payementPreuve}
            name="payementPreuve"
          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={" Accord préliminaire"}
            acceptanceText={"Document Pdf"}
            fieldName="files[additional_pdf]"
            fichier={data.preuveAcceptance}
            value={data.preuveAcceptance}
            name="preuveAcceptance"
          />
        </div>
      </section>
      {/* <div className="submitSectionButton">
        <button type="submit" className="buttonComponent">
          <span>Soumettre</span>
          <svg
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="profil-fleche"
          >
            <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
            <path
              d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
              stroke="#015192"
              strokeWidth="1.375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div> */}
    </form>
  );
}
