import React from "react";
import "./register.css";
import CountryDropdown from "./infos_pays";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "../../Components/InputLabel";
import buttonImg from "../../images/nextAdd.png";
import TwoManImg from "../assets/twoMan.png";
import logo from "../assets/image/logo-colorful.png";
import { Col, Container, Row, Form, Spinner } from "react-bootstrap";
import CustomCountrySelectAdmin from "../../Layout/CountrySelect";
import { useEffect, useState } from "react";
import countries from '../../Layout/COUNTRY.json'
import SelectLabel from "../../Components/SelectLabel";
import { success, error } from '../utils/alerts';
import findCitiesOfCountry from '../../Layout/findCitiesOfCountry';
import api from '../api/axiosInstance';
import CustomCountrySelect from "../../Layout/CountrySelect";

export default function RegisterParent() {

  const navigate = useNavigate();

  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");

  const [pays, setPays] = useState("");
  const [ville, setVille] = useState("");
  const [dateN, setDateN] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("mamn");
  const [referenceCode, setReferenceCode] = useState("123e4567-e19b-12d3-a456-426614174000");
  const [agree, setAgree] = useState("off")
  const [phoneNumber, setPhoneNumber] = useState('');
  const [codeCountry, setCodeCountry] = useState("+33");
  const [confPass, setConfPass] = useState("")
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});


  const [cities, setCities] = useState([]);

  const openEye = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
  const closedEye = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>

  // Load cities when a country is selected
  const handlePays = async (e) => {
    const selectedCountry = e.target.value;
    setPays(selectedCountry);  // Add this line to update the pays state
    setCities(findCitiesOfCountry(selectedCountry));
  };

  function handleCodeCountry(c) {
    setCodeCountry(c);
  }

  const [full,setFull]=useState(true)

  useEffect(()=>{
    if(referenceCode && nom && prenom && email && pays && ville && dateN && password && agree=="on" && phoneNumber && codeCountry && confPass ){
          setFull(false)
    }
}
,[referenceCode,nom,prenom,email,pays,ville,dateN,password,agree,phoneNumber,codeCountry,confPass])

  async function handleSubmit(e) {
    e.preventDefault();
    let currentErrors = {};



    const validNamePattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const phonePattern = /^\d{4,15}$/;
    const datePattern = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;

    if (!nom.trim()) {
      currentErrors.nom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validNamePattern.test(nom)) {
      currentErrors.nom = "Le nom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.nom;
    }
    if (!username.trim()) {
      currentErrors.username = "Veuillez entrer votre username (pas d'espaces vides)";
    } else if (!validNamePattern.test(username)) {
      currentErrors.username = "Le username ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.username;
    }


    if (!prenom.trim()) {
      currentErrors.prenom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validPrenomPattern.test(prenom)) {
      currentErrors.prenom = "Le prénom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.prenom;
    }

    if (!ville.trim()) {
      currentErrors.ville = "Veuillez sélectionner une ville";
    } else {
      delete currentErrors.ville;
    }

    if (!referenceCode.trim()) {
      currentErrors.reference_code = "Veuillez entrer votre code reference";
    } else {
      delete currentErrors.reference_code;
    }

    if (!email.trim()) {
      currentErrors.email = "Veuillez entrer une adresse e-mail valide";
    } else if (!emailPattern.test(email)) {
      currentErrors.email = "Adresse e-mail invalide";
    } else {
      delete currentErrors.email;
    }

    if (!phoneNumber.trim()) {
      currentErrors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
    } else if (!phonePattern.test(phoneNumber)) {
      currentErrors.phoneNumber = "Le numéro de téléphone doit contenir entre 4 et 15 chiffres uniquement";
    } else {
      delete currentErrors.phoneNumber;
    }

    if (!pays || pays === "Sélectionnez le pays") {
      currentErrors.pays = "Veuillez sélectionner un pays";
    } else {
      delete currentErrors.pays;
    }

 
    if (!dateN.trim()) {
      currentErrors.dateN = "Veuillez entrer une date valide";
    } else if (!datePattern.test(dateN)) {
      currentErrors.dateN = "La date est invalide";
    } else {
      delete currentErrors.dateN;
    }

    if (!password) {
      currentErrors.password = "Veuillez entrer un mot de passe";
    } else if (!passwordPattern.test(password)) {
      currentErrors.password = "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule";
    } else {
      delete currentErrors.password;
    }
    if (!confPass) {
      currentErrors.confPass = "Veuillez confirmer votre mot de passe";
    } else if (confPass !== password) {
      currentErrors.confPass = "Les mots de passe ne correspondent pas.";
    } else {
      delete currentErrors.confPass;
    }



    if (agree != "on") {
      currentErrors.agree = "Veuillez accepter les termes et conditions pour continuer.";
    } else {
      delete currentErrors.agree;
    }

    setErrors(currentErrors);


    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);
      const endPoint = "parent/register/";

      const userInput = {
        // username: "wqwwqwjj13aou",

        email,
        password,
        first_name: prenom,
        last_name: nom,
        country_code: codeCountry,
        phone_number: phoneNumber,
        country: pays,
        date_of_birth: dateN,
        town: ville,
        reference_code: referenceCode
      };

      const response = await api.post(endPoint, userInput);

      if (response.status === 201) {
        // Assuming the response structure as: 
        // { student: { ...parentData }, message: "Student created successfully." }
        const parentData = response.data.parent; // The student data
        const message = response.data.message; // The success message

        success(message); // Show the success message (e.g., in a toast or modal)

        // Optionally, you can log or display the student data
        console.log("Student created:", parentData);

        // Navigate to the login page with the user data
        navigate("/parent/login", { state: userInput });
      }
    }catch (err) {
      if (err.response && err.response.status === 400) {
        const errorData = err.response.data;
  
       
        // Affichez toutes les erreurs reçues
        Object.entries(errorData).forEach(([field, message]) => {
          if (Array.isArray(message)) {
            message.forEach(msg => error(`${field}: ${msg}`));
          } else {
            error(`${field}: ${message}`);
          }
        });
        } else {
          error("Une erreur s'est produite lors de l'inscription");
        }
      
    } finally {
      setLoading(false);
    }



  }

  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const [showConfPassword, setShowConfPassword] = useState(false);


  const toggleConfPasswordVisibility = () => {
    setShowConfPassword(!showConfPassword);
  };

  return (
    <div className="parentLogin parentRegister">
      <Container className="loginParentContainer">
        <Col className="ColLoginParent " lg={6} md={6}>
          <div className="parentLoginLeftSection parentRegiterLeftSection parentLoginLeftSectionheight">
            <div className="logoImgCard">
              <img src={logo} />
            </div>
            <h1>S'inscrire</h1>
            <div className="parentLoginHeader">
              <p>Veuillez remplir le formulaire pour vous inscrire</p>
              <p>
                Vous avez deja un compte ?
                <span>
                  <Link to={"/parent/login"}>Se connecter</Link>
                </span>
              </p>
            </div>

            <Form className="parentLoginForm">
            <Row className=''>
                  <Col >
                    <Form.Group controlId="Nom">
                      <Form.Label>Nom <span className='star'>*</span></Form.Label>
                      <Form.Control type="text" placeholder="Entrez votre nom" name="nom" onChange={(e) => { setNom(e.target.value) }} isInvalid={errors?.nom ? true : false} />
                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.nom && errors.nom
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Form.Group controlId="prénom">
                      <Form.Label>Prénom <span className='star'>*</span></Form.Label>
                      <Form.Control type="text" placeholder="Entrez votre prénom" name="prénom" onChange={(e) => { setPrenom(e.target.value) }} isInvalid={errors?.prenom ? true : false} />
                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.prenom && errors.prenom
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Form.Group controlId="reference_code">
                      <Form.Label>Code reference <span className='star'>*</span></Form.Label>
                      <Form.Control type="text" placeholder="Entrez votre Code reference" name="reference_code" onChange={(e) => { setReferenceCode(e.target.value) }} isInvalid={errors?.referenceCode ? true : false} />
                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.referenceCode && errors.referenceCode
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Form.Group controlId="Id">
                      <Form.Label>Email <span className='star'>*</span></Form.Label>
                      <Form.Control type="email" placeholder="abc@gmail.com" name="email" onChange={(e) => { setEmail(e.target.value) }} isInvalid={errors?.email ? true : false} />
                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.email && errors.email
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-2'>
                  <Form.Label>Numéro de téléphone <span className='star'>*</span></Form.Label>
                  <Col xl={3} lg={4} md={3} sm={4} xs={4} xxs={4} >
                    <CustomCountrySelect className="flagSelect" codeCountry={handleCodeCountry} />
                  </Col>
                  <Col xl={9} lg={8} md={9} sm={8} xs={8} xxs={8} className='tel-container'>
                    <Form.Control type="text" placeholder="XXX  XXX  XXXX" name="tel" onChange={(e) => { setPhoneNumber(e.target.value) }} isInvalid={errors?.phoneNumber ? true : false} />

                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {
                      errors?.phoneNumber && errors.phoneNumber
                    }
                  </Form.Control.Feedback>
                </Row>

                <Row className='mt-2'>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12} className="student-login-input-pays" >
                    <Form.Group controlId="Pays" className="pays">
                      <Form.Label>Pays <span className='star'>*</span></Form.Label>
                      <Form.Select name="pays" defaultValue="" onChange={handlePays} isInvalid={errors?.pays ? true : false}>
                        <option value="" disabled>
                          Sélectionnez le pays
                        </option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}

                      </Form.Select>


                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.pays && errors.pays
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2 mt-xl-0 mt-lg-0">
                  <Form.Group controlId="Ville">
                            <Form.Label>Ville <span className='star'>*</span></Form.Label>
                            

                              <Form.Control type="text" placeholder="Entrez votre ville" name="tel" onChange={(e)=>{setVille(e.target.value)}} isInvalid={errors?.ville ? true : false}/>
                              
                              
                              
                           
                            <Form.Control.Feedback type="invalid">
                              {
                                errors?.ville && errors.ville
                              }
                            </Form.Control.Feedback>
                        </Form.Group>

                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} className="student-login-input-date ">
                    <Form.Group controlId="date" className="date"  >
                      <Form.Label>Date de naissance <span className='star'>*</span></Form.Label>
                      <Form.Control
                        type="date"


                        name="dateN"
                        placeholder="AAAA-MM-JJ"
                        onChange={(e) => { setDateN(e.target.value) }}
                        isInvalid={errors?.dateN ? true : false}

                      />
                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.dateN && errors.dateN
                        }
                      </Form.Control.Feedback>

                    </Form.Group>


                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Form.Group controlId="password">
                      <Form.Label>Créer un mot de passe <span className='star'>*</span></Form.Label>
                      <div className="d-flex align-items-center justify-content-between " style={{ position: "relative" }}>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="**************"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          isInvalid={errors?.password ? true : false}

                        />
                        <p onClick={togglePasswordVisibility} className="eye-password-student" style={{
                          position: "absolute",
                          right: "0.75rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#888888"
                        }} >
                          {showPassword ? closedEye : openEye}
                        </p>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors?.password && errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-2'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Form.Group controlId="password">
                      <Form.Label >Confirmer le mot de passe <span className='star'>*</span></Form.Label>
                      <div className="d-flex align-items-center justify-content-between " style={{ position: "relative" }}>
                        <Form.Control
                          type={showConfPassword ? "text" : "password"}
                          placeholder="**************"
                          value={confPass}
                          onChange={(e) => setConfPass(e.target.value)}
                          isInvalid={errors?.confPass ? true : false}

                        />
                        <p onClick={toggleConfPasswordVisibility} className="eye-password-student" style={{
                          position: "absolute",
                          right: "0.75rem",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          color: "#888888"
                        }} >
                          {showConfPassword ? closedEye : openEye}
                        </p>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {
                          errors?.confPass && errors.confPass
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="student-login-checkbox-global-container mt-2">
                  <div className='student-login-checkbox-container mx-2'>
                    <Form.Check
                      type="checkbox"
                      name="agree"
                      id='agree'

                      onChange={(e) => { agree === "off" ? setAgree("on") : setAgree("off") }}
                      isInvalid={errors?.agree ? true : false}
                    />

                    <Form.Label htmlFor='agree' className="label-checkbox-login" >J'accepte les termes et conditions</Form.Label>
                  </div>
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {
                      errors?.agree && errors.agree
                    }
                  </Form.Control.Feedback>
                </Row>
                <Row className='mt-2'>
                           <div className='suivant-login-container' >
                           
                           <button 
                            type="submit" 
                            className='button-Register'
                            onClick={handleSubmit}
                            disabled={full}
                            aria-label="Submit"
                        > 
                          {
                            !loading ? (
                              <>
                             S'inscrire
                                <svg className="Register-fleche" width="29" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                      <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                                      <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </>
                            ) : (
                              <Spinner animation="border" size="md"/>
                            )
                          }

                        </button>
                           </div>
                          
                       </Row>
            </Form>
          </div>
        </Col>

        <Col lg={6} md={6} className="rightImgLoginCol">
          <div className="rightSectionImgCard2">
            <img src={TwoManImg} />
          </div>
        </Col>
      </Container>
    </div>
  );
}
