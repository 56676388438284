import React, { useState, useEffect, useReducer, useMemo } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import api from "../../StudentModule/api/axiosInstance";
import TableHeading from "../TableHeading";
import Pagination from "../Pagination";
import TableData from "../TableData";
import Button from "../Button";

import DateIcon from "../../images/Parent/uil_calender.png";
import FilterIcon from "../../images/Parent/carbon_filter.png";
import SuccesIcon from "../../images/Parent/gg_check-o.png";
import EchecIcon from "../../images/Parent/gg_check-o (1).png";
import ProgressIcon from "../../images/Parent/gg_check-o (2).png";

import "./PaymentHistoric.css";

const formatDate = (date) => {
    return date ? format(date, "yyyy-MM-dd") : "";
};

// Reducer function to manage state changes
const initialState = {
    currentPage: 1,
    itemsPerPage: 10,
    loading: true,
    statusFilter: "",
    data: [],
    isOpen: false,
    startDate: null,
    endDate: null,
    itemsPages: {},
    error: null,
};

function reducer(state, action) {
    switch (action.type) {
        case "SET_DATA":
            return { ...state, data: action.payload };
        case "SET_LOADING":
            return { ...state, loading: action.payload };
        case "SET_STATUS_FILTER":
            return { ...state, statusFilter: action.payload };
        case "SET_DATE_RANGE":
            return { ...state, startDate: action.startDate, endDate: action.endDate };
        case "SET_ITEMS_PAGES":
            return { ...state, itemsPages: action.payload };
        case "SET_CURRENT_PAGE":
            return { ...state, currentPage: action.payload };
        case "SET_ITEMS_PER_PAGE":
            return { ...state, itemsPerPage: action.payload };
        case "SET_IS_OPEN":
            return { ...state, isOpen: action.payload };
        case "SET_ERROR":
            return { ...state, error: action.payload };
        default:
            return state;
    }
}

export default function PaymentHistoric() {
    const [state, dispatch] = useReducer(reducer, initialState);

    // Fetch data function
    const fetchData = async () => {
        try {
            dispatch({ type: "SET_LOADING", payload: true });
            const response = await api.get("/student/payments/");
            const fetchedData = response.data;

            const filteredData = fetchedData.filter((item) => {
                const itemDate = new Date(item.date);
                return (
                    (!state.startDate || itemDate >= state.startDate) &&
                    (!state.endDate || itemDate <= state.endDate) &&
                    (!state.statusFilter || item.status === state.statusFilter)
                );
            });

            dispatch({ type: "SET_DATA", payload: filteredData });
            dispatch({ type: "SET_LOADING", payload: false });
        } catch (error) {
            dispatch({ type: "SET_ERROR", payload: "Failed to load data. Please try again later." });
            console.error("Erreur lors de la récupération des données :", error);
            dispatch({ type: "SET_LOADING", payload: false });
        }
    };

    // Fetch data when statusFilter, startDate, or endDate changes
    useEffect(() => {
        fetchData();
    }, [state.statusFilter, state.startDate, state.endDate]);

    const itemsPages = useMemo(() => {
        const totalPages = Math.ceil(state.data.length / state.itemsPerPage);
        const newItemsPages = {};
        let current = 0;

        for (let index = 1; index <= totalPages; index++) {
            newItemsPages[index] = state.data.slice(current, current + state.itemsPerPage);
            current += state.itemsPerPage;
        }

        return newItemsPages;
    }, [state.data, state.itemsPerPage]);

    const handleStatusFilterChange = (event) => {
        dispatch({ type: "SET_STATUS_FILTER", payload: event.target.value });
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        dispatch({ type: "SET_DATE_RANGE", startDate: start, endDate: end });
    };

    return (
        <main className="animParent parentPaymentHistoric">
            <div className="parentPaymentHistoricFilters">
                <div className="parentPaymentHistoricDateFilter">
                    <div>
                        <img
                            src={DateIcon}
                            alt="date"
                            onClick={() => dispatch({ type: "SET_IS_OPEN", payload: true })}
                        />
                        {state.isOpen && (
                            <div>
                                <DatePicker
                                    selected={state.startDate}
                                    onChange={handleDateChange}
                                    startDate={state.startDate}
                                    endDate={state.endDate}
                                    selectsRange
                                    inline
                                    minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                                    maxDate={new Date()}
                                />
                            </div>
                        )}
                    </div>
                    <span>
                        {state.startDate
                            ? `${formatDate(state.startDate)} - ${formatDate(state.endDate)}`
                            : "Sélectionner une plage"}
                    </span>
                </div>
                <div className="parentPaymentHistoricFilter">
                    <img src={FilterIcon} alt="filter" />
                    <select
                        onChange={handleStatusFilterChange}
                        value={state.statusFilter}
                        className="paymentStatusFilter"
                    >
                        <option value="">Tous</option>
                        <option value="success">Succès</option>
                        <option value="pending">En attente</option>
                        <option value="failed">Échec</option>
                    </select>
                </div>
                <Button value={"Exporter"} />
            </div>

            {state.error && <div className="errorMessage">{state.error}</div>}

            <PaymentHistoricTable
                data={itemsPages[state.currentPage] || []}
                loading={state.loading}
            />

            <Pagination
                itemsPages={itemsPages}
                setItemsPerPage={(perPage) => dispatch({ type: "SET_ITEMS_PER_PAGE", payload: perPage })}
                currentPage={state.currentPage}
                setCurrentPage={(page) => dispatch({ type: "SET_CURRENT_PAGE", payload: page })}
            />
        </main>
    );
}

function PaymentHistoricTable({ data, loading }) {
    return (
        <div className="parentPaymentHistoricTableContainer">
            <table className="parentPaymentHistoricTable">
                <thead>
                    <tr>
                        <TableHeading value={"Numero"} />
                        <TableHeading value={"Nom de service"} />
                        <TableHeading value={"Date du paiement"} />
                        <TableHeading value={"Statut de paiement"} />
                        <TableHeading value={"Montant"} />
                        <TableHeading value={"Details"} />
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td style={{ textAlign: "center", width: "100%" }}>
                                Chargement des données...
                            </td>
                        </tr>
                    ) : (
                        data.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "OddTableRow" : ""}>
                                <TableData value={item.id} />
                                <TableData value={item.service_name} />
                                <TableData value={item.date} />
                                <TableData
                                    value={
                                        <div
                                            className={`${
                                                item.status === "success"
                                                    ? "sucessStatus"
                                                    : item.status === "failed"
                                                    ? "echecStatus"
                                                    : "progressStatus"
                                            } paymentHistoricStatus`}
                                        >
                                            <img
                                                src={
                                                    item.status === "success"
                                                        ? SuccesIcon
                                                        : item.status === "failed"
                                                        ? EchecIcon
                                                        : ProgressIcon
                                                }
                                                alt=""
                                            />
                                            <span>{item.status}</span>
                                        </div>
                                    }
                                />
                                <TableData value={`€ ${item.amount}`} />
                                <TableData value={<div className="moreDetailsChamp">...</div>} />
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
}
