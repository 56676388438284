import React, { useEffect, useState } from 'react'
import './RechercheLogement.css'
import InputLabel from '../../../Components/InputLabel'
import Button from '../../../Components/Button'
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from 'react-router'
import api from '../../../StudentModule/api/axiosInstance'
import FileToDownload from '../FileToDownload'
import CustomCountrySelect from '../../Layouts/CountrySelect'
import sanitizeImageUrl from '../../../StudentModule/utils/anitizeImageUrl';


const ParentRechercheLogement = () => {
    const [data, setData] = useState({
        fistname: '',
        lastname: '',
        codeCountry: '+33',
        phone: '',
        birthPlace: '',
        birthDate: '',
        email: '',
        nationnality: '',
        hebermentType: '',
        preferedPlace: '',
        movingDate: '',

        sejourDuration: '',
        funds: '',
        specialNeeds: '',
        sejourObject: '',
        workPlace: '',
        occupantCount: '',
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('parent/housing-status/');
                if (!response) {
                    throw new Error('Une erreur est survenue lors de la récupération des données.');
                }

                const result = response.data;

                // Vérifiez si des données ont été renvoyées
                if (result && result.length > 0) {
                    const fetchedData = result[0]; // On prend le premier élément du tableau
                    const student = fetchedData.student || {};
                    const housingSearch = fetchedData.housing_search || {};

                    // Mettre à jour l'état local avec les données récupérées
                    setData({
                        firstname: student.first_name || '',
                        lastname: student.last_name || '',
                        codeCountry: student.country_code || '+33',
                        phone: student.phone_number || '',
                        birthPlace: student.town || '',
                        birthDate: student.date_of_birth || '',
                        email: student.email || '',
                        nationality: student.nationality || '',
                        hebergementType: housingSearch.accommodation_type || '',
                        preferedPlace: housingSearch.preferred_location || '',
                        movingDate: housingSearch.move_in_date || '',
                        sejourDuration: housingSearch.stay_duration || '',
                        funds: housingSearch.budget || '',
                        specialNeeds: housingSearch.special_needs || '',
                        sejourObject: housingSearch.purpose_of_stay || '',
                        workPlace: housingSearch.university_or_workplace || '',
                        occupantCount: housingSearch.number_of_occupants || '',
                        identityProof: sanitizeImageUrl( housingSearch.files.passport_copy) || null,
                        admissionProof:sanitizeImageUrl(  housingSearch.files.preliminary_acceptance) || null,
                    });
                } else {
                    setError('Aucune donnée trouvée.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const updateField = e => {
        setData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };


    return (
        <main className="rechercheLogement animParent">
            <div className="backSectionButton">
                <Button
                    value={
                        <>
                            <IoArrowBackCircle />
                            <span>Retour</span>
                        </>
                    }
                    onClick={() => navigate("/parent/prestations-de-service")}
                />
            </div>

            <PersonnalInfos
                title={"Recherche de logement"}
                data={data}
                setData={setData}
                updateField={updateField}
            />

            <section className="preferenceHebergementSection">
                <h1 className="preferenceHebergementTitreSection">
                    Préférences d'hébergement
                </h1>
                <div className="preferenceHebergementForm">


                    <InputLabel
                        readOnly
                        label={"Type d'hébergement"}
                        name="hebergementType"
                        value={data.hebergementType}
                        onChange={(e) => updateField(e)}

                    />
                    <InputLabel
                        readOnly
                        label={"Lieu préféré"}
                        name="preferedPlace"
                        placeholder={'Entrez votre lieu préféré'}
                        value={data.preferedPlace}
                        onChange={(e) => updateField(e)}


                    />
                    <InputLabel
                        readOnly
                        label={"Date de déménagement"}
                        name="movingDate"
                        placeholder={''}
                        value={data.movingDate}
                        type="date"
                        onChange={(e) => updateField(e)}

                    />

                    <InputLabel
                        readOnly
                        label={"Durée du séjour (mois)"}
                        name="sejourDuration"
                        placeholder={'1'}
                        value={data.sejourDuration}
                        onChange={(e) => updateField(e)}

                    />

                    <InputLabel
                        readOnly
                        label={"Budget (€)"}
                        name="funds"
                        placeholder={'20'}
                        value={data.funds}
                        onChange={(e) => updateField(e)}

                    />

                    <InputLabel
                        readOnly
                        label={"Besoins speciaux"}
                        name="specialNeeds"
                        value={data.specialNeeds}
                        onChange={(e) => updateField(e)}


                    />

                </div>
            </section>

            <section className="informationComplementaireSection">
                <h1 className="student-attestation-title">
                    Informations Complémentaires
                </h1>
                <div className="informationComplementaireForm">

                    <InputLabel
                        readOnly
                        label={"Objet du séjour"}
                        name="sejourObject"
                        placeholder={"Entrez l'objet du séjour"}
                        value={data.sejourObject}
                        onChange={(e) => updateField(e)}


                    />

                    <InputLabel
                        readOnly
                        label={"Université ou Lieu de travail"}
                        name="workPlace"
                        placeholder={"Hardward"}
                        value={data.workPlace}
                        onChange={(e) => updateField(e)}


                    />

                    <InputLabel
                        readOnly
                        label={"Nombre d'occupants"}
                        name="occupantCount"
                        placeholder={"Entrez le nommbre d'occupant"}
                        value={data.occupantCount}
                        onChange={(e) => updateField(e)}


                    />

                </div>
            </section>

            <section className="documentsATelecharger">
                <h1 className="student-attestation-title">Documents à télécharger</h1>
                <div className="documentsATelechargerContenu">
                    {/* Preuve d'identité */}
                    {data.identityProof && (
                        <FileToDownload
                            titre={"Preuve d'identité"}
                            acceptanceText={"Passeport, ID Carte"}
                            fichier={data.identityProof}
                            value={data.identityProof}
                            name="identityProof"
                        />
                    )}

                    {/* Preuve d'admission / d'emploi */}
                    {data.admissionProof && (
                        <FileToDownload
                            titre={"Preuve d'admission / d'emploi"}
                            acceptanceText={"Lettre d'acceptance, Contrat de travail"}
                            fichier={data.admissionProof}
                            value={data.admissionProof}
                            name="admissionProof"
                            onChange={(e) => updateField(e)} // Vous pouvez utiliser cette méthode pour gérer des mises à jour si nécessaire
                        />
                    )}
                </div>
            </section>


            {/* <div className="submitSectionButton">
                <Button
                    disabled={loading}
                    value={
                        <>
                            <span>{data?.id ? "Modifier" : "Soumettre"}</span>
                            <svg
                                viewBox="0 0 29 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="profil-fleche"
                            >
                                <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
                                <path
                                    d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
                                    stroke="#015192"
                                    strokeWidth="1.375"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </>
                    }
                />
            </div> */}
        </main>
    );
};

export default ParentRechercheLogement;
export function PersonnalInfos({ data, updateField, title, setData }) {

    return (
        <section className="rechercheLogementSection ">
            <h1 className="student-attestation-title">{title}</h1>
            <div className="rechercheLogementForm">


                <InputLabel
                    readOnly
                    label={"Prénom"}
                    name="firstname"
                    placeholder={"Entrez votre prénom"}
                    value={data.firstname}
                    onChange={(e) => updateField(e)}


                />

                <InputLabel
                    readOnly
                    label={"Nom de famille"}
                    name="lastname"
                    placeholder={"Entrez votre nom de famille"}
                    value={data.lastname}
                    onChange={(e) => updateField(e)}


                />


                <div className="composedInput">
                    <div className="inputLabel">
                        <label htmlFor="codeCountry">Numéro de Téléphone</label>
                        <div style={{ height: "45px" }}>
                            <CustomCountrySelect
                                readOnly={true} // Empêche les modifications dans certains cas
                                disabled={true} // Rend le composant visuellement non interactif
                                dialCode={data.codeCountry} // Affiche le code du pays actuel
                                value={data.codeCountry} // Valeur affichée
                            />
                        </div>
                    </div>

                    <InputLabel
                        readOnly
                        label={"Nom de famille"}
                        name="phone"
                        placeholder={"XX-XXX-XXX"}
                        value={data.phone}
                        onChange={(e) => updateField(e)}

                    />

                </div>

                <InputLabel
                    readOnly
                    label={"Lieu de naissance"}
                    name="birthPlace"
                    placeholder={"Entrez le lieu de naissance"}
                    value={data.birthPlace}
                    onChange={(e) => updateField(e)}


                />
                <InputLabel
                    readOnly
                    label={"Date de naissance"}
                    name="birthDate"
                    type="date"
                    placeholder={"Entrez le lieu de naissance"}
                    value={data.birthDate}
                    onChange={(e) => updateField(e)}


                />
                <InputLabel
                    readOnly
                    label={"Adresse email"}
                    name="email"
                    placeholder={"Entrez votre adresse email"}
                    value={data.email}
                    onChange={(e) => updateField(e)}

                />

                <InputLabel
                    readOnly
                    label={"Nationalité"}
                    name="nationality"
                    placeholder={"Nationalité"}
                    value={data.nationality}
                    onChange={(e) => updateField(e)}

                />

            </div>
        </section>
    );
}
