import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Container/SideBar2';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import bg from "../../images/dash.png";
import Navbar from '../Layouts/Navbar';
import "./style.css";
import { error } from '../../StudentModule/utils/alerts';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Moved this line up

   useEffect(() => {
  
          const accessToken = localStorage.getItem("accessToken");
          const role = localStorage.getItem("role");
          if (!accessToken) {
              navigate("/parent/login");
          }
          if (role !== 'parent') {
            error("vous avez pas la permission pour cette page");
            navigate("/parent/login");

          }
    

      }, [navigate]);

  // const handleContact = () => {
  //   navigate('/parent/contact');
  // };

  // const handlePasswordChange = () => {
  //   navigate('/parent/parameter/motdepasse'); // Navigate to the Password Change section
  // };

  const [navHeading, setNavHeading] = useState('');

  const updateHeading = (newHeading) => {
    setNavHeading(newHeading);
  };

  // Determine whether to show FAQ or Messages/Help based on the current route
  const showFAQ = location.pathname === '/etudiant/faq';

  const showMessagesAndHelp = [
    '/etudiant/notification',
    '/etudiant/messages',
    '/etudiant/help'
  ].includes(location.pathname);

  return (
    <Container fluid
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        position: "fixed",
        overflowY: "scroll",
        overflowX: 'hidden'
      }}>
      <Row>
        <Col  lg={3} md={0} className="mt-5 mb-5 sidebarIndex p-0">
          <Sidebar updateHeading={updateHeading} />
        </Col>

        <Col  lg={9} md={11} className="mt-5 main-content-part">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='header' >
              <Navbar title={navHeading} />
            </Col>
          </Row>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
