import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import React, { useState, useRef,useEffect } from 'react'; 
import api from '../../api/axiosInstance';
import { useParams } from 'react-router-dom';


import { useNavigate } from 'react-router-dom';
import AdminSettingsLayout from "../AdminSettingsLayout"
import './ResetPassword.css'

export default function ResetPassword(){
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const navigate=useNavigate()
      const [profileImage, setProfileImage] = useState(null);
      const [adminInfo,setAdminInfo]=useState({
        nom:"",
        prenom:"",
        email:"",
        phones:"",
      })
      // const [activeSection, setActiveSection] = useState('Documents');
      const fileInputRef = useRef(null);
      const [cardNumber, setCardNumber] = useState('');
      const [cvv, setCvv] = useState('');
      const [expiration, setExpiration] = useState('');
      const [showErrorModal, setShowErrorModal] = useState(false);
      const [showSuccessModal, setShowSuccessModal] = useState(false);
      const [navbarTitle, setNavbarTitle] = useState('Parameter');
      const { section } = useParams(); // Get the section from the URL
      const [activeSection, setActiveSection] = useState(section || 'Documents'); // Default to 'Documents' if no section is provided
    const [profile, setProfile] = useState({});
    const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    });
    const [data, setData] = useState({
        newPassword: '',
        confirmPassword: ''
    })

    const updateFieldValue = e => setData(data => ({...data, [e.target.name]: e.target.value}))
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    const handleSubmit2 = async (event) => {
        event.preventDefault();
        const activeSection ='motdepasse'
    
        // Check if we are submitting card details or password change
        if (activeSection === 'Payment') {
          // Validate card details
          if (cardNumber.length < 16 || cvv.length < 3 || !expiration.match(/^\d{2}\/\d{2}$/)) {
            setShowErrorModal(true);
          } else {
            setShowSuccessModal(true);
          }
        } else if (activeSection === 'motdepasse') {
          const { currentPassword, newPassword, confirmPassword } = formData;
    
          // Check if new passwords match
          if (newPassword !== confirmPassword) {
            setAlertType("error");
            setAlert("Les nouveaux mots de passe ne correspondent pas.");
            return;
          }
    
          try {
            const token = localStorage.getItem("accessToken");
            const response = await api.put(
              "change-password/",
              {
                old_password: currentPassword, // Correction du nom du paramètre
                new_password: newPassword,       // Correction du nom du paramètre
              },
            );
            console.log(currentPassword,newPassword)
    
            if (response.status === 200) {
              setAlertType("success");
              setAlert("Mot de passe changé avec succès.");
            }
          } catch (error) {
            if (error.response) {
              if (error.response.status === 401) {
                setAlertType("error");
                setAlert("Le mot de passe actuel est incorrect.");
              } else {
                setAlertType("error");
                setAlert("Le mot de passe actuel est incorrect.");
              }
            } else {
              setAlertType("error");
              setAlert("Une erreur inconnue s'est produite.");
            }
            console.error("Error changing password:", error);
          }
        }
      };


    return(
        <Container>
            <Row>
            <Col>
            
                <AdminSettingsLayout mainTitle={'Réinitialisation de Mot de passe'}>
                    <div className="resetPassword">
                    <Form onSubmit={handleSubmit2}>
            <Form.Group controlId="newPassword" className="row mb-3">
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className='label-form'>Mot de passe actuel</Form.Label>
                <Form.Control
                  type="password"
                  name="currentPassword"
                  className="input"
                  placeholder='Entrez votre mot de passe actuel'
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className='label-form'>Nouveau mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  className="input"
                  placeholder='Entrez le nouveau mot de passe'
                  value={formData.newPassword}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className='label-form'>Confirmez le mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  className="input"
                  placeholder='Confirmez le mot de passe'
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Button className='btn-sauveguard' type="submit">Sauvegarder</Button>
            {alert && <div className={`alert alert-${alertType}`}>{alert}</div>}
          </Form>
                    </div>
                </AdminSettingsLayout>
            
                </Col>
            </Row>
        </Container>
    )
  
  }
  
