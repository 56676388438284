import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import "./Profil.css"
import nationalities from "../../StudentModule/Layouts/nationalities.json"
// import CustomCountrySelect from '../../../StudentModule/Layouts/CountrySelect'
import next from "../../images/nextAdd.png"
import CustomCountrySelect from '../../StudentModule/Layouts/CountrySelect'
import countries from '../../Layout/COUNTRY.json'
import { error, success } from '../../StudentModule/utils/alerts'
import api from '../../StudentModule/api/axiosInstance'
import findCitiesOfCountry from "../../Layout/findCitiesOfCountry";
import SelectLabel from '../../Components/SelectLabel'

function ProfileParentModule() {

    const formRef = useRef(null);
    const [villes, setVilles] = useState("");
    const [errors, setErrors] = useState({});


    // Profile States
    const [username, setUsername] = useState("");
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [tel, setTel] = useState("");
    const [codeCountry, setCodeCountry] = useState("");
    const [pays, setPays] = useState("");
    const [dateN, setDateN] = useState("");
    const [ville, setVille] = useState("");
    const [paysOrigin, setPaysOrigin] = useState("");
    const [email, setEmail] = useState("");
    const updateCodeCountry = (code) => {
        setCodeCountry(code)
    }

    const handlePays = async (e) => {
        const countryName = e.target.value;
        setPays(countryName);


        setVilles(findCitiesOfCountry(countryName));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(
            "student: ",
            prenom,
            nom,
            codeCountry,
            tel,
            pays,
            dateN,
            ville,
            paysOrigin,
            email
        );

        try {
            await updateProfileFully();
            console.log("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };
    const validateForm = () => {
        const newErrors = {};
        if (!prenom) newErrors.prenom = 'Le prénom est requis.';
        if (!nom) newErrors.nom = 'Le nom est requis.';
        if (!tel) newErrors.tel = 'Le numéro de téléphone est requis.';
        if (!email || !/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email invalide.';
        if (!pays) newErrors.pays = 'Le pays est requis.';
        if (!dateN) newErrors.dateN = 'La date de naissance est requise.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Fetch profile on component mount
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await api.get("/parent/profile/");
                const profileData = response.data;

                // Set state based on API response
                setUsername(profileData.username || "");
                setPrenom(profileData.first_name || "");
                setNom(profileData.last_name || "");
                setTel(profileData.phone_number || "");
                setCodeCountry(profileData.country_code || "");
                setPays(profileData.country || "");
                setDateN(profileData.date_of_birth || "");
                setVille(profileData.town || "");
                setPaysOrigin(profileData.nationality || "");
                setEmail(profileData.email || "");

            } catch (err) {
                // Show error toast
                error("Erreur lors de la récupération du profil.");
            }
        };

        fetchProfile();
    }, []);

    const updateProfileFully = async () => {

        if (!validateForm()) return;
        try {
            const profileData = {
                first_name: prenom,
                last_name: nom,
                phone_number: tel,
                country_code: codeCountry,
                country: pays,
                date_of_birth: dateN,
                town: ville,
                nationality: paysOrigin,
                email: email,
                username: username,

            };
            const response = await api.put("/parent/profile/", profileData);

            success("Profil mis à jour avec succès.");
            console.log("Full profile update successful:", response.data);
            return response.data;
        } catch (err) {
            console.error("Erreur lors de la mise à jour complète du profil:", err);
            error("Erreur lors de la mise à jour du profil.");
            throw err;
        }
    };


    return (

        <div className='animParent'>
            <Col className="addStu2">
                <Row>
                    <Col sm={6}>
                        <p className='titleEtuP'>Mes coordonnées</p>
                    </Col>


                </Row>
                <Form className="mt-4" ref={formRef}>
                    <Row className=" mt-3">
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="prenom"
                                    value={prenom}
                                    placeholder="Entrez votre prénom"
                                    onChange={(e) => {
                                        setPrenom(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.prenom}</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Form.Label className="label-student-attestation">
                                    Nom de famille
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nom"
                                    value={nom}
                                    placeholder="Entrez votre nom de famille"
                                    onChange={(e) => {
                                        setNom(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.nom}</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className=" mt-3">
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Form.Label>Numéro de téléphone</Form.Label>
                                <Row>
                                    <Col xl={4} lg={4} md={3} sm={12} xs={12} style={{ marginTop: "1px" }}>
                                        <CustomCountrySelect name="codeCountry"
                                            codeCountry={updateCodeCountry}
                                            dialCode={codeCountry}
                                        //  codeCountry={handleCodeCountry} 
                                        />
                                    </Col>
                                    <Col xl={8} lg={8} md={9} sm={12} xs={12}>
                                        <Form.Control
                                            type="text"
                                            name="tel"
                                            value={tel}
                                            placeholder="XXX XXX XXXX"
                                            onChange={(e) => {
                                                setTel(e.target.value);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.tel}</Form.Control.Feedback>

                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>

                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>

                            <Form.Group>
                                <Form.Label className="label-student-attestation">
                                    Pays
                                </Form.Label>
                                <Form.Select
                                    name="pays"
                                    defaultValue=""
                                    onChange={handlePays}
                                >
                                    {countries.map((country) => (
                                        <option key={country} value={country} selected={
                                            country.toLowerCase() ===
                                            pays.toLowerCase()
                                        }>
                                            {country}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.pays}</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className=" mt-3">
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Form.Label>Date de naissance</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="dateN"
                                    value={dateN}
                                    onChange={(e) => {
                                        setDateN(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.dateN}</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group>
                                <Form.Label className="label-student-attestation">
                                    Ville
                                </Form.Label>
                                {
                                    villes.length > 0 ?
                                        <Form.Select
                                            name="ville"
                                            defaultValue=""
                                            onChange={(e) => {
                                                setVille(e.target.value);
                                            }}
                                        >
                                            <option value="">Sélectionnez la ville</option>
                                            {villes.length > 0 &&
                                                villes.map((ville) => (
                                                    <option key={ville} value={ville}>
                                                        {ville}
                                                    </option>
                                                ))}
                                        </Form.Select> :
                                        <Form.Control
                                            type="email"
                                            name="ville"
                                            value={ville}
                                            placeholder="Entrez la ville"
                                            onChange={(e) => {
                                                setVille(e.target.value);
                                            }}
                                        />
                                }
                                <Form.Control.Feedback type="invalid">{errors.ville}</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Pays d'origine */}
                    <Row >
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <SelectLabel
                                label={"Pays d'origine"}
                                name={"nationnality"}
                                // onBlur={handleBlur}
                                isInvalid={paysOrigin}

                                onChange={(e) => {
                                    setPaysOrigin(e.target.value);
                                }}        >
                                <option value="">Sélectionnez le Pays d'origine
                                </option>

                                {nationalities.map((country, index) => (
                                    <option
                                        key={`${country.nationality}-${index}`}
                                        value={country.nationality}
                                        selected={
                                            country.nationality.toLowerCase() ===
                                            paysOrigin.toLowerCase()
                                        }
                                    >
                                        {country.nationality}
                                    </option>
                                ))}

                            </SelectLabel>
                            <Form.Control.Feedback type="invalid">{errors.nationality}</Form.Control.Feedback>

                        </Col>
                        <Col className=" mt-2" xl={6} mt={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group >
                                <Form.Label className="label-student-attestation">
                                    Adresse Email
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={email}
                                    placeholder="abc@gmail.com"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Row className='rowBtnAdd1'>
                <Col  >

                    <button className='creer1' type="submit" onClick={handleSubmit} >
                        Sauvegarder
                        <img src={next} className='imgbtnadd' />
                    </button>

                </Col>
            </Row>
        </div>
    )
}

export default ProfileParentModule
