import { Col,Row, Form } from "react-bootstrap";
import folder from "../../../images/folders.png"
import eye from "../../../images/eye.png"
import delet from "../../../images/deleteEtu.png"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FileUpload from "../../../StudentModule/Layouts/FileUpload";
import { useState } from "react";
import RenderView from "./RenderView";

export default function ServiceAbonne(props){
    
    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    return <><Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={show} onHide={handleClose}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" className="student-attestation-title">
       {props.titre}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
             <RenderView titre={props.titre}/>
    </Modal.Body>
    <Modal.Footer >
    <Button className="btnServiceAbonne1">Annuler</Button>
      <Button onClick={props.onHide} className="btnServiceAbonne2">Modifier</Button>
    </Modal.Footer>
  </Modal>
    
    <Col sm={4} className='ColDoc mt-3'>
    <div className='DocInp'>
        <span className='foldInp'>
            <img src={folder} />
        </span>
        <span className='PDown'>
            <p> {props.titre}</p>
        </span>
        <span className='downl'>
           
            <div  className='DOWNIMG'>
            <img src={eye} onClick={handleShow} style={{cursor:"pointer"}}/>
            </div>
            <div>
                <img src={delet}   style={{cursor:"pointer"}}/>
            </div>
        </span>
    </div>
</Col></> 
}