import { Col, Row, Form } from "react-bootstrap";
import FileUpload from "../../../StudentModule/Layouts/FileUpload";
import { useState } from "react";
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import api from "../../api/axiosInstance";

export default function RenderView(props) {
  const icon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.5"
        width="39"
        height="39"
        rx="10"
        fill="url(#paint0_linear_991_5415)"
        fill-opacity="0.1"
      />
      <path
        d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5"
        stroke="url(#paint1_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75"
        stroke="url(#paint2_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z"
        stroke="url(#paint3_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_991_5415"
          x1="-0.37396"
          y1="10.8019"
          x2="37.6153"
          y2="11.1255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_991_5415"
          x1="14.685"
          y1="20.0849"
          x2="18.5815"
          y2="20.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_991_5415"
          x1="9.43002"
          y1="14.783"
          x2="28.9117"
          y2="14.949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_991_5415"
          x1="21.622"
          y1="11.6132"
          x2="29.4147"
          y2="11.6796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
      </defs>
    </svg>
  );
  const [modePaiement, setModePaiement] = useState("");

  const [insuranceData, setInsuranceData] = useState({});
  const [housingData, setHousingData] = useState({});
  const [insurancesData, setInsurancesData] = useState({});
  const [ticketingData, setTicketingData] = useState({});

    const id = useParams().id


  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await api.get(`/admin/students/${id}/`);
        const student = response.data.student;
        const services = response.data.services;

        console.log('student', student);
        if (response) {
          setInsuranceData(services.insurances && services.insurances[0] ? services.insurances[0] : {}); // Charger la première entrée (exemple)
          setHousingData(services.housing && services.housing[0] ? services.housing[0] : {}); // Charger la première entrée (exemple)
          setInsurancesData(services.irrevocable_payments && services.irrevocable_payments[0] ? services.irrevocable_payments[0] : {}); // Charger la première entrée (exemple)
          setTicketingData(services.ticketing && services.ticketing[0] ? services.ticketing[0] : {}); // Charger la première entrée (exemple)
      }
      

      } catch (err) {
        console.log(err);
      }
    };
    fetchStudent();
  }, [id]); // Ensure `id` is included in the dependency array


  if (props.titre == "Attestation de virement") {
    return <>
      <Row >

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Université acceptée
            </Form.Label>
            <Form.Control
              type="text"
              name="accepted_university"
              value={insurancesData.accepted_university || ''}
              placeholder="Université acceptée"

              readOnly
            />
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Niveau d'études accepté</Form.Label>
            <Form.Control
              type="text"
              name="accepted_level"
              value={insurancesData.accepted_level || ''}
              placeholder="Université acceptée"

              readOnly
            />

          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Programme accepté
            </Form.Label>
            <Form.Control
              type="text"
              name="accepted_program"
              value={insurancesData.accepted_program}

              placeholder="Entrez programme accepté"
            />
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Pays de destination</Form.Label>
            <Form.Control
              type="text"
              name="destination_city"
              value={insurancesData.destination_city}

              placeholder="Entrez programme accepté"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-2">
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Ville de destination
            </Form.Label>

            <Form.Control
              type="text"
              name="destination_country"
              value={insurancesData.destination_country}
              placeholder="Entrez la ville de destination"

            />
          </Form.Group>
        </Col>

      </Row>
    </>
  }
  else if (props.titre == "Assurance complète") {
    return <>
      <Row className=" mt-3">
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Date début</Form.Label>
            <Form.Control type="date" value={insuranceData.start_date || ''} readOnly />

          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Durée d'assurance</Form.Label>
            <Form.Control type="text" value={`${insuranceData.insurance_duration || ''} an(s)`} readOnly />


          </Form.Group>
        </Col>
      </Row >

      <Row className=" mt-3">
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Type d'assurance</Form.Label>
            <Form.Control type="text" value={insuranceData.insurance_type || ''} readOnly />

          </Form.Group>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Les bénéficiaires
            </Form.Label>
            <Form.Control type="text" value={insuranceData.beneficiaries || ''} readOnly />

          </Form.Group>
        </Col>
      </Row>
      <Row className=" mt-3">
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Montant assuré</Form.Label>
            <Form.Control
              type="text"
              name="insured_amount"
              value={insuranceData.insured_amount || ''} 
              readOnly 

              placeholder="Entrez le montant assuré"

            />
          </Form.Group>
        </Col>
      </Row >
      <Row
        className="mt-4"
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Mode de paiement</h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Sélectionnez le mode de paiement</Form.Label>
                  {/* <Row className=" mt-3">
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="Carte de crédit"
                        name="mode"
                        value={insuranceData.payment_method || ''} 
                                            checked={type === option.value} // Coche si la valeur correspond

                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="PayPal"
                        name="mode"
                        value="paypal"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="Virement"
                        name="mode"
                        value="virement"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                      <Form.Check
                        type="radio"
                        label="Preuve du paiement"
                        name="mode"
                        value="preuve"
                        className="label-student-attestation"
                        onChange={(e) => setModePaiement(e.target.value)}
                      />
                    </Col>
                  </Row> */}
                </Form.Group>
              </Col>
            </Row>
            {modePaiement === "carte" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                      Carte de crédit
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Numéro de carte</Form.Label>
                      <Form.Control
                        type="text"
                        name="numCarte"
                        placeholder="1234 5678 5677 5678"
                      // onChange={(e) => setNumCarte(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="label-student-attestation">
                        Date d'expiration
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dateExp"
                        placeholder="MM/YY"
                      // onChange={(e) => setDateExp(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>CVV</Form.Label>
                      <Form.Control
                        type="text"
                        name="cvv"
                        placeholder="123"
                      // onChange={(e) => setCvv(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {modePaiement === "virement" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                      Virement
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Nom de la banque</Form.Label>
                      <Form.Control
                        type="text"
                        name="nomBanc"
                        placeholder="Nom de la banque"
                      // onChange={(e) => setInforVirement({...infoVirement,nomBanc:e.target.value})}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="label-student-attestation">
                        IBAN
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="IBAN"
                        placeholder="IBAN"
                      // onChange={(e) => setInforVirement({...infoVirement,IBAN:e.target.value})}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>BIC/SWIFT</Form.Label>
                      <Form.Control
                        type="text"
                        name="BIC"
                        placeholder="BIC/SWIFT"
                      // onChange={(e) => setInforVirement({...infoVirement,BIC:e.target.value})}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Code bancaire</Form.Label>
                      <Form.Control
                        type="text"
                        name="codeBancaire"
                        placeholder="Code bancaire"
                      // onChange={(e) => setInforVirement({...infoVirement,codeBanc:e.target.value})}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {modePaiement === "preuve" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                      Preuve de paiement
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="attestation-save-container ">
                      <FileUpload
                        svgIcon={icon}
                        label="Télécharger"
                        acceptedTypes=".png, .jpeg, .pdf"

                      />

                      <p className="attestation-save-p2">
                        {" "}
                        Fichiers autorisés Fichiers PDF, JPEG, PNG
                      </p>
                    </div>
                  </Col>


                </Row>

              </>
            )}
          </Form>
        </Col>
      </Row>
    </>

  }
  else if (props.titre == "Recherche de logement") {
    return <> <Row className=" mt-3">
      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
        <Form.Label>Type d'hébergement</Form.Label>

        <Form.Control
          name="hebergementType"
          type="text"
          value={housingData.accommodation_type}

          placeholder={"Entrez votre lieu préféré"}

        />
      </Col>
      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
        <Form.Label>Lieu préféré</Form.Label>
        <Form.Control
          name="preferedPlace"
          type="text"
          value={housingData.preferred_location}

          placeholder={"Entrez votre lieu préféré"}

        />

      </Col>
    </Row>

      <Row className=" mt-3">
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Label>Date de déménagement</Form.Label>
          <Form.Control
            name="movingDate"
            type="date"
            placeholder={""}
            value={housingData.move_in_date}

          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Label>Durée du séjour (mois)</Form.Label>
          <Form.Control
            name="sejourDuration"
            type="number"
            value={housingData.stay_duration}

            placeholder={"Entrez la durée du séjour"}

          />

        </Col>
      </Row>

      <Row className=" mt-3">
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Label>Budget (€)</Form.Label>
          <Form.Control
            name="funds"
            type="number"
            value={housingData.budget}

            placeholder={"Entrez votre budget"}

          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="inputLabel">
          <label htmlFor="specialNeeds" className="inputLabelLabel">
            Besoins speciaux
          </label>
          <textarea
            name="specialNeeds"
            id="specialNeeds"
            className="inputLabelInput"
            value={housingData.special_needs}


          ></textarea>

        </Col>
      </Row>

      <Row className=" mt-3">
        <h1 className="student-attestation-title">
          Informations Complémentaires
        </h1>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Label>Objet de séjour</Form.Label>
          <Form.Control
            name="sejourObject"
            value={housingData.purpose_of_stay}

            type="text"
            placeholder={"Entrez l'objet de séjour"}

          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Label>Université ou lieu de travail</Form.Label>
          <Form.Control
            name="workPlace"
            type="text"
            placeholder={"Entrez la Université ou lieu de travail"}
            value={housingData.university_or_workplace}

          />

        </Col>
      </Row>

      <Row className=" mt-3">
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Label>Nombre d'occupants</Form.Label>
          <Form.Control
            name="occupantCount"
            type="number"
            placeholder={"Entrez nombre d'occupants"}
            value={housingData.number_of_occupants}

          />
        </Col>
      </Row>



    </>
  }
  else if (props.titre == "Service de billetrie") {
    return <>
      <Row className="mt-2">

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Pays de départ
            </Form.Label>
            <Form.Control
            name="paysDepart"
            type="text"
            value={ticketingData.departure_country}

          />
            
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Ville de départ</Form.Label>
            <Form.Control
            name="departureCity"
            type="text"
            value={ticketingData.departure_city}

          />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Pays de destination
            </Form.Label>

            <Form.Control
            name="arrivalCountry"
            type="text"
            value={ticketingData.destination_country}

          />
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label>Ville de destination</Form.Label>
            <Form.Control
            name="arrivalCity"
            type="text"
            value={ticketingData.destination_city}

          />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Date de départ
            </Form.Label>
            <Form.Control
              name="departureDate"
              type="date"
              placeholder={""}
              value={ticketingData.departure_date}


            />
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Date de retour
            </Form.Label>
            <Form.Control
              name="arrivalDate"
              type="date"
              placeholder={""}
              value={ticketingData.return_date}

            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Nombre de passagers
            </Form.Label>
            <Form.Control
              name="passagersCount"
              type="number"
              placeholder={""}
              value={ticketingData.number_of_passengers}


            />
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Classe de voyage
            </Form.Label>
            <Form.Control
              name="travelClass"
              type="text"
              value={ticketingData.travel_class}


            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">

        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
          <Form.Group>
            <Form.Label className="label-student-attestation">
              Compagnies aériennes préférées(facultative)
            </Form.Label>
            <Form.Control
              name="preferedAirline"
              type="text"
              value={ticketingData.preferred_airlines}

              placeholder={"Entreze compagnies aériennes préférées"}

            />
          </Form.Group>
        </Col>
        <Col xl={6} lg={6} md={12} sm={12} xs={12} className="inputLabel">
          <label htmlFor="specialNeeds" className="inputLabelLabel">
            Demandes spéciales
          </label>
          <textarea
            name="specialNeeds"
            id="specialNeeds"
            value={ticketingData.special_requests}

            className="inputLabelInput"

          ></textarea>

        </Col>
      </Row>
    </>
  }
}