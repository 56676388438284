import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { MdDownload } from 'react-icons/md';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';
import './FileUpload.css';

import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
const FileUpload = ({
  svgIcon,
  acceptedTypes,
  existingFile,
}) => {
  const pdfWorker = 'https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js';
  pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(''); // Nouvel fichier sélectionné
  const [fileType, setFileType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uniqueId, setUniqueId] = useState('');
  const [label, setLabel] = useState("click");


  // Générer un ID unique au montage du composant
  useEffect(() => {
    const id = uuidv4();
    setUniqueId(id);
  }, []);



  // Gérer la sélection d'un fichier
  const handleFileChange = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFileUrl(URL.createObjectURL(newFile));
      setFileType(newFile.type);
      setSelectedFile(newFile);

      if (typeof setLabel === "function") {
        setLabel(newFile.name);
      } else {
        console.error("setLabel is not a function");
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setFileUrl('');
    setFileType('');
    setSelectedFile(null);

    setLabel('Télécharger');

  };
 

  function handleLabelClick2() {
    document.getElementById(`file-upload-${uniqueId}`).click();
  }

 
 
  const renderPreview = () => {
    if (fileUrl) {
      const detectedType = fileType.startsWith('image/') ? 'image' : fileType === 'application/pdf' ? 'pdf' : 'unsupported';

      if (detectedType === 'image') {
        return <div className='file-preview-container'>
          
          <div className="image-preview-container">

            <img src={fileUrl} alt="preview" className="image-preview" />
          </div>
        </div>;
      }
      if (detectedType === 'pdf') {
        return (
          <div className='file-preview-container'>
            
            <div className="pdf-preview-container">

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer fileUrl={fileUrl} />
              </Worker>
            </div>
          </div>
        );
      }
      return <p>Format non supporté</p>;
    }

    if (existingFile) {
      const detectedType = existingFile.endsWith('.pdf') ? 'pdf' : 'image';

      if (detectedType === 'pdf') {
        return (
          <div className='file-preview-container'>
            

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
              <Viewer fileUrl={existingFile} />
            </Worker>

          </div>
        );

      }
      return <div className='file-preview-container'>
       
        <div className="image-preview-container"><img src={existingFile} alt="existing preview" className="image-preview" />
        </div>
      </div>;
    }

    return <div className='d-flex justify-content-center align-items-center flex-column' style={{ cursor: 'pointer' }}
      onClick={handleLabelClick2}>
      <div > {svgIcon} <span>{label}</span></div>
      <p className='attestation-save-p2'> Fichiers autorisés Fichiers JPEG, PNG, PDF </p>
    </div>
  };

  const handleLabelClick = () => {
    setShowModal(true);

  };

  return (
    <div>
      <label
        style={{ cursor: 'pointer' }}
        onClick={handleLabelClick}
      >
        {svgIcon} <span>{label}</span>
      </label>

      <Modal show={showModal} onHide={handleClose} centered >
        <Modal.Header closeButton>
          <Modal.Title>Aperçu du fichier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderPreview()}
        </Modal.Body>

        
      </Modal>

      <Form.Control
        id={`file-upload-${uniqueId}`}
        type="file"
        hidden
        accept={acceptedTypes}
        onChange={handleFileChange}
      />

      
    </div>
  );
};

export default FileUpload;