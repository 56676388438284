import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CustomCountrySelect from '../../../StudentModule/Layouts/CountrySelect';
import { useState, useEffect } from 'react';
import axios from 'axios';
import nationalities from "../../../StudentModule/Layouts/nationalities.json";
import FileUpload from "../../../StudentModule/Layouts/FileUpload";
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router';
import api from '../../api/axiosInstance';
import FileToDownload from "../FileToDownload";
import sanitizeImageUrl from '../../../StudentModule/utils/anitizeImageUrl';

export default function ParentAssurancesCompletes() {
    const navigate = useNavigate();

    // Déclarations des states
    const [insuranceData, setInsuranceData] = useState({});
    const [lieux, setLieux] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/parent/insurance-status/');
                if (response.data.length > 0) {
                    setInsuranceData(response.data[0]); // Charger la première entrée (exemple)
                }
                console.log('test', insuranceData.files.passport_copy)
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Fetch countries
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryData = response.data
                    .map(country => ({
                        name: country.translations.fra.common,
                        code: country.name.common,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                setLieux(countryData);
            } catch (error) {
                console.error('Erreur lors de la récupération des pays:', error);
            }
        };

        fetchCountries();
    }, []);

    if (loading) {
        return <div>Chargement des données...</div>;
    }

    return (
        <div className="animParent">
            <Row className="mt-4 mb-3 d-flex justify-content-end button-retour-container">
                <button onClick={() => navigate('/parent/prestations-de-service')} type="submit" className="button-retour-prestation">
                    <IoArrowBackCircle className="retour-assurance-icon" />
                    Retour
                </button>
            </Row>

            {/* Formulaire rempli avec les données */}
            <Row className="student-assurance-container mt-3" style={{ marginLeft: "1%" }}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-title">Assurances Complètes</h3>
                    <Form className="mt-4">
                        <Row className="mt-3">
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control type="text" value={insuranceData.first_name || ''} readOnly />
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Nom de famille</Form.Label>
                                    <Form.Control type="text" value={insuranceData.last_name || ''} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Numéro de téléphone</Form.Label>
                                    <Form.Control type="text" value={`${insuranceData.country_code || ''} ${insuranceData.phone_number || ''}`} readOnly />
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Lieu de naissance</Form.Label>
                                    <Form.Control type="text" value={insuranceData.place_of_birth || ''} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Date de naissance</Form.Label>
                                    <Form.Control type="date" value={insuranceData.date_of_birth || ''} readOnly />
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Nationalité</Form.Label>
                                    <Form.Control type="text" value={insuranceData.nationality || ''} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <Row className="student-assurance-container2 mt-3" style={{ marginLeft: "1%" }}>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-title">Informations sur l'assurance</h3>
                    <Form className="mt-4">
                        <Row className="mt-3">
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Date de début</Form.Label>
                                    <Form.Control type="date" value={insuranceData.start_date || ''} readOnly />
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Durée d'assurance</Form.Label>
                                    <Form.Control type="text" value={`${insuranceData.insurance_duration || ''} an(s)`} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Type d'assurance</Form.Label>
                                    <Form.Control type="text" value={insuranceData.insurance_type || ''} readOnly />
                                </Form.Group>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                <Form.Group>
                                    <Form.Label>Bénéficiaires</Form.Label>
                                    <Form.Control type="text" value={insuranceData.beneficiaries || ''} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="student-assurance-container3 mt-4 ">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Documents à télécharger</h3>
          <Row className="mt-4">
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">Passeport</h3>
              <div className="attestation-save-container ">
              <FileToDownload
    fichier={sanitizeImageUrl( insuranceData.files?.passport_copy) || null}
    value={sanitizeImageUrl(insuranceData.files?.passport_copy )|| null}
/>
                
                  </div>
            </Col>

            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                Justificatif de domicile
              </h3>

              <div className="attestation-save-container ">
              <FileToDownload
                          fieldName="files[passport_copy]"

    f fichier={sanitizeImageUrl(insuranceData.files?.preliminary_acceptance) || null}
    value={sanitizeImageUrl(insuranceData.files?.preliminary_acceptance) || null}
/>
                    
                    </div>

            </Col>
          </Row>
        </Col>
      </Row>
        </div>
    );
}
