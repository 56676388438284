import { Container, Row, Col, Form, Button } from "react-bootstrap";

import CustomCountrySelect from "../../../StudentModule/Layouts/CountrySelect";
import { useState, useEffect } from "react";
import "./AttestationVirement.css";
import api from "../../../StudentModule/api/axiosInstance";
// import FileToDownload from "../Layouts/FileToDownload";
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";
import { success, error } from "../../../StudentModule/utils/alerts";
import countries from '../../../Layout/COUNTRY.json'
import FileToDownload from "../FileToDownload";
import findCitiesOfCountry from "../../../Layout/findCitiesOfCountry";
import sanitizeImageUrl from "../../../StudentModule/utils/anitizeImageUrl";

export default function AttestationVirement() {
  const [cities, setCities] = useState([]);
  const [villes, setVilles] = useState([]);
  const [Lieux, setLieux] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(true);


  //useState pour Backend
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [tel, setTel] = useState("");
  const [lieu, setLieu] = useState("");
  const [dateN, setDateN] = useState("");
  const [email, setEmail] = useState("");
  const [nationalite, setNationality] = useState("");
  const [codeCountry, setCodeCountry] = useState("");
  const [pays, setPays] = useState("");
  const [university, setUniversity] = useState("");
  const [programme, setProgramme] = useState("");
  const [niveauEtude, setNiveauEtude] = useState("");
  const [villeDestination, setVilleDestination] = useState("");
  const [paysDestination, setPaysDestination] = useState("");
  const [AutreNiveau, setAutreNiveau] = useState("");
  const [preliminary_acceptance, setpreliminary_acceptance] = useState("");
  const [payment_proof, setpayment_proof] = useState("");
  const [passport_copy, setpassport_copy] = useState("");


  const niveaux = [
    "Diplôme de fin d'études secondaires",
    "Baccalauréat",
    "Licence",
    "Master",
    "Doctorat",
    "Certificat professionnel",
    "Diplôme technique",
    "Formation continue",
    "Études à l'étranger",
    "Autre",
  ];


  const handleChangeNiveau = (e) => {
    const selectedNiveau = e.target.value;
    setNiveauEtude(selectedNiveau);
    setShowInput(selectedNiveau === "Autre");
  };


  const handleInputChangeAutreNiveau = (e) => {
    setAutreNiveau(e.target.value);
  };


  // Fetch certificat Data
  useEffect(() => {
    const fetchCertificatData = async () => {
      
      try {
        const response = await api.get("/parent/certficate-status/");
        const certificatData = response.data;
        console.log("Received certificate data:", certificatData);
  
        // Check if the response is an array and has data
        if (Array.isArray(certificatData) && certificatData.length > 0) {
          // Access the first element in the list (assuming there's only one item)
          const data = certificatData[0]; 
  
          // Set state based on the first item in the list
          setPrenom(data.first_name || "");
          setNom(data.last_name || "");
          setTel(data.phone_number || "");
          setLieu(data.place_of_birth || "");
          setDateN(data.date_of_birth || "");
          setEmail(data.email || "");
          setNationality(data.nationality || "");
          setCodeCountry(data.country_code || "");
          setPays(data.country || "");
          setUniversity(data.accepted_university || "");
          setProgramme(data.accepted_program || "");
          setNiveauEtude(data.accepted_level || "");
          setVilleDestination(data.destination_city || "");
          setPaysDestination(data.destination_country || "");
          setAutreNiveau(data.autreNiveau || "");
          setpreliminary_acceptance(sanitizeImageUrl( data.files?.preliminary_acceptance) || null);
          setpayment_proof(sanitizeImageUrl( data.files?.payment_proof) || null);
          setpassport_copy(sanitizeImageUrl( data.files?.passport_copy )|| null);
        }
      } catch (err) {
        // Handle error properly
        console.error("Error fetching certificate data", err);
      }
    };
  
    fetchCertificatData();
  }, []);

  // Load cities when a country is selected
  const handleCountryDestination = async (e) => {
    const countryName = e.target.value;
    setPaysDestination(countryName);


    setVilles(findCitiesOfCountry(countryName));
  };


  const handlePays = async (e) => {
    const countryName = e.target.value;
    setPays(countryName);


    setLieux(findCitiesOfCountry(countryName));
  };


  function handleCodeCountry(c) {
    setCodeCountry(c);
  }



  const navigate = useNavigate();


  return (
    <div className="animParent">
      <Row className="mt-4 mb-3 d-flex button-retour-container justify-content-end">
        <button
          onClick={() => navigate("/etudiant/prestations-de-service")}
          type="submit"
          className="button-retour-prestation"
        >
          <IoArrowBackCircle className="retour-assurance-icon" />
          Retour
        </button>
      </Row>


      <Row
        className="student-attestation-container mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">
            Attestation de virement irrévocable (AVI)
          </h3>
          <Form className="">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={prenom}
                    placeholder="Entrez votre prénom"
                    onChange={(e) => {
                      setPrenom(e.target.value);
                    }}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Nom de famille
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={nom}
                    placeholder="Entrez votre nom de famille"
                    onChange={(e) => {
                      setNom(e.target.value);
                    }}
                    readOnly 
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Numéro de téléphone</Form.Label>
                  <Row>
                    <Col xl={4} lg={4} md={3} sm={3} xs={12}>
                    <CustomCountrySelect dialCode={codeCountry}
                    codeCountry={setCodeCountry} />
                    </Col>
                    <Col xl={8} lg={8} md={9} sm={9} xs={12}>
                      <Form.Control
                        type="text"
                        name="tel"
                        value={tel}
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                        readOnly
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Pays
                  </Form.Label>
                  <Form.Select
                    name="pays"
                    value={pays}
                    onChange={handlePays}
                    disabled
                  >
                    <option value="" disabled>
                      Sélectionnez le pays
                    </option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                      
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateN"
                    value={dateN}
                    onChange={(e) => {
                      setDateN(e.target.value);
                    }}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Lieu de naissance
                  </Form.Label>
                  {
                    Lieux.length > 0 ?
                      <Form.Select
                        name="lieu"
                        value={lieu}
                        defaultValue=""
                        onChange={(e) => {
                          setLieu(e.target.value);
                        }}
                        disabled
                      >
                        <option value="" disabled>
                          Sélectionnez lieu de naissance
                        </option>
                        {Lieux.length > 0 &&
                          Lieux.map((ville) => (
                            <option key={ville} value={ville}>
                              {ville}
                            </option>
                          ))}
                      </Form.Select> :
                      <Form.Control
                        type="text"
                        name="lieu"
                        value={lieu}
                        placeholder="Entrez le lieu de naissance"
                        onChange={(e) => {
                          setLieu(e.target.value);
                        }}
                        readOnly
                      />
                  }
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Adresse Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Entrez l'adresse e-mail "
                    onChange={(e) => {
                      setEmail(e.target.value);
                      
                    }}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Université acceptée
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="university"
                    value={university}
                    placeholder="Université acceptée"
                    onChange={(e) => {
                      setUniversity(e.target.value);
                    }}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Niveau d'études accepté</Form.Label>
                  <Form.Select
                    name="niveauEtude"
                    value={niveauEtude}
                    defaultValue=""
                    onChange={handleChangeNiveau}
                    disabled
                  >
                    <option value="" disabled>
                      Sélectionnez
                    </option>
                    {niveaux.map((niveau, index) => (
                      <option key={index} value={niveau}>
                        {niveau}
                      </option>
                    ))}
                  </Form.Select>
                  {showInput && (
                    <Form.Control
                      type="text"
                      name="AutreNiveau"


                      placeholder="Entrez niveau d'études accepté"
                      className="mt-3"
                      onChange={(e) => {
                        setAutreNiveau(e.target.value);
                      }}
                      readOnly
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Programme accepté
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="programme"
                    value={programme}
                    placeholder="Entrez programme accepté"
                    onChange={(e) => {
                      setProgramme(e.target.value);
                    }}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Pays de destination</Form.Label>
                  <Form.Select
                    name="pays"
                    value={pays}
                    defaultValue=""
                    onChange={handleCountryDestination}
                    disabled 
                  >
                    <option value="" disabled>
                      Sélectionnez le pays de destination
                    </option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                        
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Ville de destination
                  </Form.Label>
                  {
                    villes.length > 0 ?
                      <Form.Select
                        name="villeDestination"
                        value={villeDestination}
                        defaultValue=""
                        onChange={(e) => {
                          setVilleDestination(e.target.value);
                        }}
                        disabled
                      >
                        <option value="" disabled>
                          Sélectionnez la ville de destination
                        </option>
                        {villes.length > 0 &&
                          villes.map((ville) => (
                            <option key={ville} value={ville}>
                              {ville}
                            </option>
                          ))}
                      </Form.Select> :
                      <Form.Control
                        type="text"
                        name="villeDestination"
                        value={villeDestination}
                        placeholder="Entrez la ville de destination"
                        onChange={(e) => {
                          setVilleDestination(e.target.value);
                        }}
                        readOnly
                      />
                  }
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>


      <Row
        className="student-attestation-container2 mt-4"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Documents à télécharger</h3>
          <Row className="">
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                preliminary_acceptance d'inscription préliminaire
              </h3>
              <div className="attestation-save-container ">
                <FileToDownload
                  titre={"Copie du passeport"}
                  acceptanceText={"Passeport, ID Carte"}
                  fichier={preliminary_acceptance}
                  fieldName="files[preliminary_acceptance]"
                  value={preliminary_acceptance}
                />


                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
            </Col>


            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                payment_proof de paiement
              </h3>


              <div className="attestation-save-container">
                <FileToDownload
                  titre={"Copie du payment_proof"}
                  acceptanceText={"payment_proof"}
                  fichier={payment_proof}
                  fieldName="files[additional_pdf]"
                  value={payment_proof}

                />


                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>


      <Row className="justify-content mb-4">
      <Col
  xl={6}
  lg={6}
  md={12}
  sm={12}
  xs={12}
  className="student-Attestation-container-pdf mt-4"
  id="pdfContainer1"
  style={{ overflow: 'hidden' }} // Prevent overflow
>
  <h3 className="student-attestation-title mx-2">
    Documents à télécharger
  </h3>
  <h3 className="student-attestation-save-title mx-2">
    Télécharger le passport_copy
  </h3>
  <div className="attestation-save-container"  style={{ 
      width: '100%', 
      overflowX: 'auto', 
      marginBottom: '20px', // Adjust the margin as needed
      paddingBottom: '20px'  // Adjust the padding as needed
    }}>
    <FileToDownload
    style={{ 
      width: '100%', 
      overflowX: 'auto', 
      marginBottom: '20px', // Adjust the margin as needed
      paddingBottom: '20px'  // Adjust the padding as needed
    }}
      titre={"Copie du passeport"}
      acceptanceText={"Passeport, ID Carte"}
      fieldName="files[passport_copy]"
      fichier={passport_copy}
      value={passport_copy}
    />
    <p className="attestation-save-p2" style={{ marginTop: "10px" }}>
      Fichiers autorisés Fichiers PDF, JPEG, PNG
    </p>
  </div>
</Col>


        
      </Row>


    </div>
  );
}
 const icon = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.25"
        y="0.5"
        width="39"
        height="39"
        rx="10"
        fill="url(#paint0_linear_991_5415)"
        fill-opacity="0.1"
      />
      <path
        d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5"
        stroke="url(#paint1_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75"
        stroke="url(#paint2_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z"
        stroke="url(#paint3_linear_991_5415)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_991_5415"
          x1="-0.37396"
          y1="10.8019"
          x2="37.6153"
          y2="11.1255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_991_5415"
          x1="14.685"
          y1="20.0849"
          x2="18.5815"
          y2="20.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_991_5415"
          x1="9.43002"
          y1="14.783"
          x2="28.9117"
          y2="14.949"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_991_5415"
          x1="21.622"
          y1="11.6132"
          x2="29.4147"
          y2="11.6796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#015192" />
          <stop offset="1" stop-color="#003D6E" />
        </linearGradient>
      </defs>
    </svg>
  );
