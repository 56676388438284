import React, { useState, useEffect, useRef } from 'react';
import flag from "../../../images/Flag.png";
import { Col, Container, Row, Form } from 'react-bootstrap';
import "../../Student_Admin/Student.css";
import next from "../../../images/nextAdd.png";
import profile from "../../../images/inpProfil.png";
import add from "../../../images/add.png";
import { useNavigate, useParams } from 'react-router';
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect';
import api from '../../api/axiosInstance';
import { success } from '../../utils/alerts';
import { Spinner } from 'react-bootstrap';
import countries from "../../../Layout/COUNTRY.json"
import findCitiesOfCountry from '../../../Layout/findCitiesOfCountry';
import sanitizeImageUrl from '../../../StudentModule/utils/anitizeImageUrl';


function StudentEdit() {
  const fileInputRef = useRef(null);
  const [codeCountry, setCodeCountry] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [universities, setUniversities] = useState([]);
  const [dialCode, setDialCode] = useState("");
  const [profil, setProfil] = useState(profile)
  const id = useParams().id
  const [loading, setLoading] = useState(false)
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});


  const [data, setData] = useState({
    nom: "",
    prenom: "",
    email: "",
    phone: "",
    gender: "",
    universite: "",
    school_year: "",
    picture: "",
    pays: "",
    ville: "",
    dateN: "",
    statut: ""

  })
  const navigate = useNavigate();


  const handleBackClick = () => navigate(-1);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, picture: file })
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfil(reader.result);

      };
      reader.readAsDataURL(file);
    }
  };

  const handlePays = async (e) => {
    const selectedCountry = e.target.value;
    setData({ ...data, pays: selectedCountry });
    setCities(findCitiesOfCountry(selectedCountry));
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await api.get(`/admin/students/${id}/`);
        const student = response.data.student;
        setCities(findCitiesOfCountry(student.country))
        setData({
          nom: student.last_name || "",
          prenom: student.first_name || "",
          email: student.email || "",
          phone: student.phone_number || "",
          pays: student.country || "",
          ville: student.town || "",
          gender: student.gender || "",
          universite: student.university || "",
          school_year: student.school_year || "",
          dateN: student.date_of_birth || "",
          picture: student.picture,
          statut: student.is_active
        })
        setDialCode(student.country_code)
        setCodeCountry(student.country_code)
        if (student.picture) {
          setProfil(student.picture)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchStudentData()
  }, [])

  //methode put 
  async function handleModifier() {

    let currentErrors = {};



    if (!data.picture || data.picture < 1) {
      currentErrors.picture = "Vous devez fournir un photo de profile.";
    } else {
      delete currentErrors.picture;
    }



    const validNamePattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const phonePattern = /^\d{9,15}$/;
    const anneePattern = /^\d{4}$/;
    const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
    const anneeActuelle = new Date().getFullYear();
    const universitePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9'’\s\-]+$/;


    if (!String(data.nom).trim()) {
      currentErrors.nom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validNamePattern.test(data.nom)) {
      currentErrors.nom = "Le nom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.nom;
    }

    if (!String(data.prenom).trim()) {
      currentErrors.prenom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validPrenomPattern.test(data.prenom)) {
      currentErrors.prenom = "Le prénom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.prenom;
    }

    if (!String(data.town).trim()) {
      currentErrors.ville = "Veuillez Entrer une ville";
    } else {
      delete currentErrors.ville;
    }

    if (!String(data.email).trim()) {
      currentErrors.email = "Veuillez entrer une adresse e-mail valide";
    } else if (!emailPattern.test(data.email)) {
      currentErrors.email = "Adresse e-mail invalide";
    } else {
      delete currentErrors.email;
    }

    if (!String(data.phone).trim()) {
      currentErrors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
    } else if (!phonePattern.test(data.phone)) {
      currentErrors.phoneNumber = "Le numéro de téléphone doit contenir uniquement des chiffres";
    } else {
      delete currentErrors.phoneNumber;
    }

    if (!data.pays || data.pays === "Sélectionnez le pays") {
      currentErrors.pays = "Veuillez sélectionner un pays";
    } else {
      delete currentErrors.pays;
    }
    const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD




    if (!(data.dateN).trim()) {
      currentErrors.dateN = "Veuillez entrer une date  valide.";
    } else if (data.dateN >= today) {
      currentErrors.dateN =
        "La date de déplacement doit être dans le passe.";
    }
    else {
      delete currentErrors.dateN;
    }

    // if (!String(data.universite).trim()) {
    //   currentErrors.universite = "Veuillez saisir le nom d'une université.";
    // } else if (!universitePattern.test(data.universite)) {
    //   currentErrors.universite = "Veuillez entrer un nom d'université valide";
    // }
    // else {
    //   delete currentErrors.universite;
    // }

    // if (!String(data.gender).trim()) {
    //   currentErrors.gender = "Veuillez sélectionner un gender."
    //     ;
    // } else {
    //   delete currentErrors.gender;
    // }

    // if (!String(data.annee).trim()) {
    //   currentErrors.annee = "Veuillez entrer une année scolaire";
    // } else if (!anneePattern.test(data.annee)) {
    //   currentErrors.annee = "année scolaire invalide";
    // } else if (data.annee < anneeActuelle) {
    //   currentErrors.annee = "L'année scolaire saisie est antérieure à l'année en cours.";
    // // }
    // else {
    //   delete currentErrors.annee;
    // }
    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }


    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {


      setLoading(true);
      const formData = new FormData()

      formData.append("first_name", data.prenom)
      formData.append("last_name", data.nom)
      formData.append("email", data.email)
      formData.append("country_code", codeCountry)
      formData.append("phone_number", data.phone)
      formData.append("country", data.pays)
      formData.append("town", data.ville)
      formData.append("gender", data.gender)
      formData.append("is_active", data.statut)
      formData.append("university", data.universite)
      formData.append("school_year", data.school_year)
      formData.append("date_of_birth", data.dateN)
      if (data.picture !== profil) {
        formData.append("picture", data.picture)
      }


      const response = await api.put(`/admin/students/${id}/`, formData)
      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Les informations de l'étudiant ont bien été mises à jour.";
        success(msg);
        navigate("/administrateur/etudiant");
      }

    } catch (err) {
      console.log(err)
      setLoading(false)
    }

  }

  return (
    <Container>
      <Row>
        <Col>
          <span className='RouteStu'>
            <p className='routeStu'>Etudiant</p>
            <p className='RouteStuRoute'>/ Modifier Etudiant </p>
          </span>
        </Col>
        <Col>
          <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col>
      </Row>

      <Row>
        <Col className="addStu1">
          <Row>
            <Col sm={6}>
              <p className='titleEtu'>Compte Etudiant</p>
            </Col>
            <Col sm={6}>
              <span className='spanInpImg'>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className='imgInpModif'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}

                />
                <img
                  src={sanitizeImageUrl(profil)}
                  alt="Cliquez pour changer l'image"
                  className="spanInpImg img-fluid img-rounded"
                  onClick={() => fileInputRef.current.click()}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
                <img src={add} className='addImg' onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }} />

              </span>
              <Form.Control.Feedback type="invalid">
                {
                  errors?.picture && errors.picture
                }
              </Form.Control.Feedback>

            </Col>
          </Row>

          {/* Formulaire */}
          <Row>
            <Col sm={6}>
              <p className='labelInp'>Nom</p>
              <input type='text' placeholder='Votre Nom' className='inpStu' value={data.nom} onChange={(e) => { setData({ ...data, nom: e.target.value }) }} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.nom && errors.nom
                }
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Prénom</p>
              <input type='text' placeholder='Votre Prénom' className='inpStu' value={data.prenom} onChange={(e) => { setData({ ...data, prenom: e.target.value }) }} />

              <Form.Control.Feedback type="invalid">
                {
                  errors?.prenom && errors.prenom
                }
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Numéro de téléphone</p>
              <Row>
                <Col sm={3}>
                  <CustomCountrySelectAdmin codeCountry={setCodeCountry} dialCode={dialCode} />
                </Col>
                <Col>
                  <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtu' value={data.phone} onChange={(e) => { setData({ ...data, phone: e.target.value }) }} />
                  <Form.Control.Feedback type="invalid">
                    {
                      errors?.phoneNumber && errors.phoneNumber
                    }
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Date De Naissance</p>
              <input type='date' className='inpStu' value={data.dateN} onChange={(e) => { setData({ ...data, dateN: e.target.value }) }} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.dateN && errors.dateN
                }
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Pays</p>
              <Form.Select name="pays" defaultValue="" onChange={handlePays} >
                <option value="" disabled>
                  Sélectionnez le pays
                </option>
                {countries.map((country) => (
                  <option key={country} value={country} selected={country == data.pays}>
                    {country}
                  </option>
                ))}

              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {
                  errors?.pays && errors.pays
                }
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Ville</p>
              <input type='text' placeholder='Votre ville' className='inpStu' value={data.ville} onChange={(e) => { setData({ ...data, ville: e.target.value }) }} />

              {/* {
                cities.length > 0 ?

                  <Form.Select name="villeDestination" defaultValue="" value={data.ville} onChange={(e) => { setData({ ...data, ville: e.target.value }) }} >
                    <option value="" disabled>
                      Sélectionnez la ville
                    </option>
                    {cities.length > 0 && cities.map((ville) => (
                      <option key={ville} value={ville} >
                        {ville}
                      </option>
                    ))}

                    

                  </Form.Select> :

                  <Form.Control type="text" placeholder="Entrez votre ville" name="town" onChange={(e) => { setData({ ...data, ville: e.target.value }) }} value={data.ville} />



              }     */}

              <Form.Control.Feedback type="invalid">
                {
                  errors?.ville && errors.ville
                }
              </Form.Control.Feedback>
            </Col>

          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Adresse Email</p>
              <input type='email' placeholder='Votre Adresse Email' className='inpStu' value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.email && errors.email
                }
              </Form.Control.Feedback>

            </Col>
            <Col sm={6}>
              <p className='labelInp'>Genre</p>
              <select className='inpStu' onChange={(e) => { setData({ ...data, gender: e.target.value }) }}>
                <option value="">Selectionner</option>
                <option value="male" selected={data.gender.toLowerCase() === "male"}>Homme</option>
                <option value="female" selected={data.gender.toLowerCase() === "female"}>Femme</option>
              </select>
              <Form.Control.Feedback type="invalid">
                {
                  errors?.gender && errors.gender
                }
              </Form.Control.Feedback>
            </Col>

          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Université</p>
              <input type='text' placeholder="Entrez le nom d'université" className='inpStu' value={data.universite} onChange={(e) => { setData({ ...data, universite: e.target.value }) }} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.universite && errors.universite
                }
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Année Scolaire</p>
              <input type='text' placeholder='Votre Année Scolaire' className='inpStu' value={data.school_year} onChange={(e) => { setData({ ...data, school_year: e.target.value }) }} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.school_year && errors.school_year
                }
              </Form.Control.Feedback>
            </Col>
          </Row>


          <Row>
            <Col sm={6}>
              <p className='labelInp'>Statut du compte</p>
              <select className='inpStu' onChange={(e) => { setData({ ...data, statut: e.target.value }) }}>
                <option value="">Sélectionnez une université</option>
                <option value={true} selected={data.statut == true}>Activé</option>
                <option value={false} selected={data.statut == false}>Désactivé</option>
              </select>
            </Col>

          </Row>

          <Row className='rowBtnAdd'>
            <Col>
              <button className='creer' onClick={handleModifier}>
                {
                  !loading ? (
                    <>
                      Modifier
                      <img src={next} className='imgbtnadd' />
                    </>
                  ) : (
                    <Spinner animation="border" size="md" />
                  )
                }
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default StudentEdit;