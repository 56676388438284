import React, { useState, useEffect, useRef } from 'react';
import flag from "../../../images/Flag.png";
import { Col, Container, Row, Form } from 'react-bootstrap';
import "../../Student_Admin/Student.css";
import next from "../../../images/nextAdd.png";
import profile from "../../../images/inpProfil.png";
import add from "../../../images/add.png";
import { useNavigate, useParams } from 'react-router';
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect';
import api from '../../api/axiosInstance';
import { success } from '../../utils/alerts';
import { Spinner } from 'react-bootstrap';
import countries from "../../../Layout/COUNTRY.json";
import findCitiesOfCountry from '../../../Layout/findCitiesOfCountry';
import sanitizeImageUrl from '../../../StudentModule/utils/anitizeImageUrl';

function ParentUpdate() {
  const fileInputRef = useRef(null);
  const [codeCountry, setCodeCountry] = useState("");
  const [profil, setProfil] = useState(profile); // Default profile picture
  const id = useParams().id;
  const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
  
  const [cities, setCities] = useState([]);
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    genre: "",
    relation: "",
    country: "",
    town: "",
    date_of_birth: "",
    is_active: true,
    picture:""
  });
  const navigate = useNavigate();

  const handleBackClick = () => navigate(-1);

  // Handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, picture: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfil(reader.result); // Set the image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePays = async (e) => {
    const selectedCountry = e.target.value;
    setData({ ...data, country: selectedCountry });
    setCities(findCitiesOfCountry(selectedCountry));
  };

  useEffect(() => {
    const fetchParentData = async () => {
      try {
        const response = await api.get(`/admin/parents/${id}/`);
        const parent = response.data;
        console.log(parent);
        setCities(findCitiesOfCountry(parent.country));
        setData({
          first_name: parent.first_name || "",
          last_name: parent.last_name || "",
          email: parent.email || "",
          phone_number: parent.phone_number || "",
          genre: parent.genre || "",
          relation: parent.relation || "",
          country: parent.country || "",
          town: parent.town || "",
          date_of_birth: parent.date_of_birth || "",
          is_active: parent.is_active,
          picture: parent.picture,
        });
        setCodeCountry(parent.country_code);
        if (parent.picture) {
          setProfil(parent.picture); // Set the profile picture if available
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchParentData();
  }, [id]);

  const handleModifier = async () => {


    let currentErrors = {};



    if (!data.picture || data.picture < 1) {
      currentErrors.picture = "Vous devez fournir un photo de profile.";
    } else {
      delete currentErrors.picture;
    }



    const validNamePattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const phonePattern = /^\d{9,15}$/;

    if (!String(data.nom).trim()) {
      currentErrors.nom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validNamePattern.test(data.nom)) {
      currentErrors.nom = "Le nom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.nom;
    }

    // if (!String(data.prenom).trim()) {
    //   currentErrors.prenom = "Veuillez entrer votre nom (pas d'espaces vides)";
    // } else if (!validPrenomPattern.test(data.prenom)) {
    //   currentErrors.prenom = "Le prénom ne doit contenir que des lettres et des apostrophes.";
    // } else {
    //   delete currentErrors.prenom;
    // }

    // if (!String(data.town).trim()) {
    //   currentErrors.ville = "Veuillez Entrer une ville";
    // } else {
    //   delete currentErrors.ville;
    // }

    // if (!String(data.email).trim()) {
    //   currentErrors.email = "Veuillez entrer une adresse e-mail valide";
    // } else if (!emailPattern.test(data.email)) {
    //   currentErrors.email = "Adresse e-mail invalide";
    // } else {
    //   delete currentErrors.email;
    // }

    // if (!String(data.phone).trim()) {
    //   currentErrors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
    // } else if (!phonePattern.test(data.phone)) {
    //   currentErrors.phoneNumber = "Le numéro de téléphone doit contenir uniquement des chiffres";
    // } else {
    //   delete currentErrors.phoneNumber;
    // }

    // if (!data.pays || data.pays === "Sélectionnez le pays") {
    //   currentErrors.pays = "Veuillez sélectionner un pays";
    // } else {
    //   delete currentErrors.pays;
    // }
    // const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD




    // if (!(data.dateN).trim()) {
    //   currentErrors.dateN = "Veuillez entrer une date  valide.";
    // } else if (data.dateN >= today) {
    //   currentErrors.dateN =
    //     "La date de déplacement doit être dans le passe.";
    // }
    // else {
    //   delete currentErrors.dateN;
    // }

    // if (!String(data.universite).trim()) {
    //   currentErrors.universite = "Veuillez saisir le nom d'une université.";
    // } else if (!universitePattern.test(data.universite)) {
    //   currentErrors.universite = "Veuillez entrer un nom d'université valide";
    // }
    // else {
    //   delete currentErrors.universite;
    // }

    // if (!String(data.gender).trim()) {
    //   currentErrors.gender = "Veuillez sélectionner un gender."
    //     ;
    // } else {
    //   delete currentErrors.gender;
    // }

    // if (!String(data.annee).trim()) {
    //   currentErrors.annee = "Veuillez entrer une année scolaire";
    // } else if (!anneePattern.test(data.annee)) {
    //   currentErrors.annee = "année scolaire invalide";
    // } else if (data.annee < anneeActuelle) {
    //   currentErrors.annee = "L'année scolaire saisie est antérieure à l'année en cours.";
    // // }
    // else {
    //   delete currentErrors.annee;
    // }
    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }


    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("password", data.password);
      formData.append("country_code", codeCountry);
      formData.append("phone_number", data.phone_number);
      formData.append("country", data.country);
      formData.append("town", data.town);
      formData.append("genre", data.genre);
      formData.append("is_active", data.is_active);
      formData.append("relation", data.relation);
      formData.append("date_of_birth", data.date_of_birth);
      if(data.picture!==profil){
        formData.append("picture",data.picture)
      }
      console.log(formData);
      const response = await api.put(`/admin/parents/${id}/`, formData);
      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Les informations du parent ont bien été mises à jour.";
        success(msg);
        navigate("/administrateur/parents");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <span className='RouteStu'>
            <p className='routeStu'>Parent</p>
            <p className='RouteStuRoute'>/ Modifier Parent </p>
          </span>
        </Col>
        <Col>
          <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col>
      </Row>

      <Row>
        <Col className="addStu1">
          <Row>
            <Col sm={6}>
              <p className='titleEtu'>Compte Parent</p>
            </Col>
            <Col sm={6}>
              <span className='spanInpImg'>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className='imgInpModif'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <img
                  src={sanitizeImageUrl(data.picture)}
                  alt="Cliquez pour changer l'image"
                  className="spanInpImg img-fluid img-rounded"
                  onClick={() => fileInputRef.current.click()}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
                <img src={add} className='addImg' onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }} />
              </span>
               <Form.Control.Feedback type="invalid">
                              {
                                errors?.picture && errors.picture
                              }
                            </Form.Control.Feedback>
            </Col>
          </Row>

          {/* Formulaire */}
          <Row>
            <Col sm={6}>
              <p className='labelInp'>Nom</p>
              <input type='text' placeholder='Votre Nom' className='inpStu' value={data.last_name} onChange={(e) => { setData({ ...data, last_name: e.target.value }) }} />
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Prénom</p>
              <input type='text' placeholder='Votre Prénom' className='inpStu' value={data.first_name} onChange={(e) => { setData({ ...data, first_name: e.target.value }) }} />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Numéro de téléphone</p>
              <Row>
                <Col sm={3}>
                  <CustomCountrySelectAdmin dialCode={codeCountry} codeCountry={setCodeCountry} />
                </Col>
                <Col>
                  <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtu' value={data.phone_number} onChange={(e) => { setData({ ...data, phone_number: e.target.value }) }} />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Date De Naissance</p>
              <input type='date' className='inpStu' value={data.date_of_birth} onChange={(e) => { setData({ ...data, date_of_birth: e.target.value }) }} />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Pays</p>
              <Form.Select name="pays" defaultValue="" onChange={handlePays}>
                <option value="" disabled>
                  Sélectionnez le pays
                </option>
                {countries.map((country) => (
                  <option key={country} value={country} selected={country === data.country}>
                    {country}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Ville</p>
              {cities.length > 0 ?
                <Form.Select name="villeDestination" value={data.town} onChange={(e) => { setData({ ...data, town: e.target.value }) }}>
                  <option value="" disabled>Sélectionnez la ville</option>
                  {cities.map((ville) => (
                    <option key={ville} value={ville}>
                      {ville}
                    </option>
                  ))}
                </Form.Select> :
                <Form.Control type="text" placeholder="Entrez votre ville" name="town" value={data.town} onChange={(e) => { setData({ ...data, town: e.target.value }) }} />
              }
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Adresse Email</p>
              <input type='email' placeholder='Votre Adresse Email' className='inpStu' value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Genre</p>
              <select className='inpStu' onChange={(e) => { setData({ ...data, genre: e.target.value }) }}>
                <option value="">Selectionner</option>
                <option value="male" selected={data.genre.toLowerCase() === "male"}>Homme</option>
                <option value="female" selected={data.genre.toLowerCase() === "female"}>Femme</option>
              </select>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Relation</p>
              <select 
                className='inpStu' 
                value={data.relation} 
                onChange={(e) => setData({ ...data, relation: e.target.value })}>
                <option value="">Sélectionnez une relation</option>
                <option value="father" selected={data.relation === "father"}>Père</option>
                <option value="mother" selected={data.relation === "mother"}>Mère</option>
              </select>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Statut du compte</p>
              <select className='inpStu' onChange={(e) => { setData({ ...data, is_active: e.target.value }) }}>
                <option value="">Sélectionnez un statut</option>
                <option value={true} selected={data.is_active === true}>Activé</option>
                <option value={false} selected={data.is_active === false}>Désactivé</option>
              </select>
            </Col>
          </Row>

          <Row className='rowBtnAdd'>
            <Col>
              <button className='creer' onClick={handleModifier}>
                {!loading ? (
                  <>
                    Modifier
                    <img src={next} className='imgbtnadd' />
                  </>
                ) : (
                  <Spinner animation="border" size="md" />
                )}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ParentUpdate;
