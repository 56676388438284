import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import '../Dash.css'
import base from "../../../images/Base.png"
import side1 from "../../../images/side1.png"
import { error ,success} from "../../utils/alerts";
import api from "../../api/axiosInstance";

function Chart1() {
  const [NbStudents, setNbStudents] = useState([]); // État pour les paiements

  const [loading, setLoading] = useState(true);

  const options = {
    chart: {
      height: "85px",
      width:"85px",
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "50%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: 0,
            show: true,
            color: "#7E3FB6", // Custom color
            fontSize: "12px", // Custom font size
            style: {
              fontFamily: 'Poppins', // Custom font family
              fontStyle: 'normal',
              fontWeight: 700, // Bold text
              lineHeight: '50px',
            },
          },
          value: {
            
            show: false,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'dark',
        type: "horizontal",
        shadeIntensity: 1,
        gradientToColors: ["#281AC8"], // End color
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#FD749B", // Start color
            opacity: 1
          },
          {
            offset: 100,
            color: "#281AC8", // End color
            opacity: 1
          }
        ]
      }
    },
    stroke: {
      lineCap: "round",
    },
    labels: [NbStudents],
  };
  

  const series = [67];
  const [sizeImg,SetSizeImg]=useState(180)
  const fetchStudents = async () => {
    try {
      const response = await api.get("/admin/students/");
  
      // Si la réponse est un tableau, calculez la taille
      if (Array.isArray(response.data.students)) {
        setNbStudents(response.data.students.length);
      } else {
        console.error("La réponse de l'API n'est pas un tableau.");
        setNbStudents(0);
      }
  
      setLoading(false); // Met à jour l'état de chargement
    } catch (err) {
      error("Erreur lors de la récupération des données.");
      setLoading(false); // Met à jour l'état de chargement en cas d'erreur
    }
  };
  useEffect(() => {

    

    fetchStudents();
  }, []);
  // useEffect(() => {
  //   const updateSize = () => {
  //     if (window.innerWidth < 500) {
  //       SetSizeImg(150);
  //     } else {
  //       SetSizeImg(200);
  //     }
  //   };

  //   // Appelle la fonction une première fois pour régler la taille de l'image
  //   updateSize();

  //   // Ajoute un écouteur d'événements pour les redimensionnements de la fenêtre
  //   window.addEventListener('resize', updateSize);

  //   // Nettoyage de l'écouteur lors du démontage du composant
  //   return () => window.removeEventListener('resize', updateSize);
  // }, []);
  return (
    <div className='chartdiv'>
       
        <div className='CHARTT'>
            <img src={side1} className='sideChart'/>
            <Chart
              options={options}
              series={series}
              type="radialBar"
              height={sizeImg}
              width={sizeImg}
              // Set height to 200px
            />
          </div>
          <div className='chartT2'>
            <p className='titleCh'>Nombre Total d'étudiants</p>
            <div className='sousChart'>
                <p className='pour'>{NbStudents} </p>
                <img src={base} className='porImg'/>
            </div>
          </div>
     
    </div>
  )
}

export default Chart1
