import { Container, Row ,Col, Form} from "react-bootstrap";
import CustomCountrySelect from "../../Layout/CountrySelect";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";

export default function PagePaiement(){
    const [codeCountry,setCodeCountry]=useState("+33")
    const [modePaiement,setModePaiement]=useState("carte")
    const navigate=useNavigate()

    const plan= JSON.parse(decodeURIComponent(useParams().plan))
    return  <Container fluid>
   <Row  className="student-assurance-container mt-3">
  
        <Col xl={7} lg={7} md={7} sm={7}>
        
        <Row className="mt-3">
        <Form.Group>
        <h3 className='student-attestation-title'>Les informations de votre carte</h3>
                
              <>
              
                <Row >
                <div className="d-flex align-items-center" style={{marginTop:"-20px",marginBottom:"10px"}}>
                <svg
    height="50px"
    width="50px"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new 0 0 512 512' }}
   
  >
    <g style={{ enableBackground: 'new' }}>
      <g>
        <g>
          <path
            d="M211.328,184.445l-23.465,144.208h37.542l23.468-144.208 H211.328z M156.276,184.445l-35.794,99.185l-4.234-21.358l0.003,0.007l-0.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223 c-5.601-4.476-11.247-8.296-16.705-11.559l32.531,124.943h39.116l59.733-144.208H156.276z M302.797,224.48 c0-16.304,36.563-14.209,52.629-5.356l5.357-30.972c0,0-16.534-6.288-33.768-6.288c-18.632,0-62.875,8.148-62.875,47.739 c0,37.26,51.928,37.723,51.928,57.285c0,19.562-46.574,16.066-61.944,3.726l-5.586,32.373c0,0,16.763,8.148,42.382,8.148 c25.616,0,64.272-13.271,64.272-49.37C355.192,244.272,302.797,240.78,302.797,224.48z M455.997,184.445h-30.185 c-13.938,0-17.332,10.747-17.332,10.747l-55.988,133.461h39.131l7.828-21.419h47.728l4.403,21.419h34.472L455.997,184.445z M410.27,277.641l19.728-53.966l11.098,53.966H410.27z"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#005BAC' }}
          />
        </g>
      </g>
    </g>
    <g style={{ enableBackground: 'new' }}>
      <g>
        <g>
          <path
            d="M104.132,198.022c0,0-1.554-13.015-18.144-13.015H25.715 l-0.706,2.446c0,0,28.972,5.906,56.767,28.033c26.562,21.148,35.227,47.51,35.227,47.51L104.132,198.022z"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#F6AC1D' }}
          />
        </g>
      </g>
    </g>
  </svg>        
  <svg
    height="45px"
    width="45px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 291.791 291.791"
    xmlSpace="preserve"
     className="ms-3"
    
  >
    <g>
      <path
        style={{ fill: '#E2574C' }}
        d="M182.298,145.895c0,50.366-40.801,91.176-91.149,91.176S0,196.252,0,145.895 s40.811-91.176,91.149-91.176S182.298,95.538,182.298,145.895z"
      />
      <path
        style={{ fill: '#F4B459' }}
        d="M200.616,54.719c-20.442,0-39.261,6.811-54.469,18.181l0.073,0.009 c2.991,2.89,6.291,4.924,8.835,8.251l-18.965,0.301c-2.972,3-5.68,6.264-8.233,9.656H161.3c2.544,3.054,4.896,5.708,7.03,9.081 h-46.536c-1.705,2.936-3.282,5.954-4.659,9.09h56.493c1.477,3.127,2.799,5.489,3.921,8.799h-63.76 c-1.012,3.146-1.878,6.364-2.535,9.646h68.966c0.675,3.155,1.194,6.072,1.55,9.045h-71.884c-0.301,3-0.456,6.045-0.456,9.118 h72.859c0,3.228-0.228,6.218-0.556,9.118h-71.847c0.31,3.091,0.766,6.127,1.368,9.118h68.856c-0.711,2.954-1.532,5.926-2.562,9.008 h-63.969c0.966,3.118,2.143,6.145,3.428,9.099h56.621c-1.568,3.319-3.346,5.972-5.306,9.081h-46.691 c1.842,3.191,3.875,6.236,6.081,9.154l33.589,0.501c-2.863,3.437-6.537,5.507-9.884,8.516c0.182,0.146-5.352-0.018-16.248-0.191 c16.576,17.105,39.744,27.772,65.446,27.772c50.357,0,91.176-40.82,91.176-91.176S250.981,54.719,200.616,54.719z"
      />
    </g>
  </svg>
  
  </div>
  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Numéro de carte</Form.Label>
                      <Form.Control
                        type="text"
                        name="numCarte"
                        placeholder="1234 5678 5677 5678"
                       
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="label-student-attestation">
                        Date d'expiration
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dateExp"
                        placeholder="MM/YY"
                       
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>CVV</Form.Label>
                      <Form.Control
                        type="text"
                        name="cvv"
                        placeholder="123"
                      
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
        
            </Form.Group>
</Row>
<div >
<button
         
          type="submit"
         className="soumettreCarte"
        >
        Soumettre
          <svg
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="profil-fleche"
          >
            <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
            <path
              d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
              stroke="#015192"
              strokeWidth="1.375"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        </div>
        </Col>
        <Col sm={12} md={5} lg={6} xl={5} xxl={4} key={plan.id} className="ColPlanPrix" >

  <div className="plan-first" style={{background:"linear-gradient(113.18deg, #015192 -9.35%, #00A5BC 87.94%)"}} >
    
    

    <div className="d-flex justify-content-between align-items-center">
      <span>
        <span className="titPricePlan">
          <p className="titPlan" style={{color:"white"}}>{plan.price}</p>
          <p className="LasttitPlan" style={{color:"white"}}>{plan.lastPrice}</p>
        </span>
        <p className="sousTitPlan" style={{color:"white"}}>{plan.title}</p>
      </span>
    </div>

    <p className="subTitlPlan" style={{color:"white"}}>{plan.sousTitle}</p>

    <ul className="ulPlanPrixHeigt">
      {plan.features.map((feature, index) => (
        <li key={index} className="liPlanPrix" style={{color:"white"}}>
          <span className="imgspanlist">
            {/* <img
              src={plan.popular ? listoption : checktick}
              alt="tick"
              className="imglistplan"
            /> */}
            <p className="featPlan" style={{color:"white"}}>{feature}</p>
          </span>
        </li>
      ))}
    </ul>
    <button className="buttonForfait" onClick={()=>{navigate(-1)}}>Choisir un autre forfait</button>
  </div>
</Col>

  </Row>

    </Container>
}