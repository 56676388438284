import bottomArrow from '../images/AdminLayout/Sidebar/Vector.png';
import { useState } from 'react';
import './style.css';
import eye from "../images/eye.png";

export default function Faq({ data }) {
  const [activeIndex, setActiveIndex] = useState(undefined);
  const [showAllResponses, setShowAllResponses] = useState(false);

  const showMaskResponse = (index) =>
    activeIndex === index ? setActiveIndex(null) : setActiveIndex(index);

  const toggleAllResponses = () => {
    // Inverse l'état des réponses
    setShowAllResponses(!showAllResponses);
    // Si on montre toutes les réponses, on désactive l'état d'élément individuel actif
    setActiveIndex(null);
  };

  return (
    <div className="FAQContainer">
      {/* Bouton pour afficher toutes les réponses */}
      <div onClick={toggleAllResponses} className="downloadButton">
      <img src={eye}  style={{cursor:"pointer"}}/>
      </div>

      {data.map((item, index) => (
        <div className="FAQ" key={index}>
          <div
            className="FAQQuestion"
            onClick={() => showMaskResponse(index)}
          >
            {item.question}
            <img src={bottomArrow} alt="" />
          </div>
          {(showAllResponses || activeIndex === index) && (
            <div className="FAQResponse">{item.response}</div>
          )}
        </div>
      ))}
    </div>
  );
}