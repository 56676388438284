export default function InputLabel({
    name,
    label,
    type = "text",
    placeholder,
    errorMessage = "",
    ...props
  }) {
    return (
      <div className="inputLabel">
        {label && (
          <label htmlFor={name} className="inputLabelLabel">
            {label}
          </label>
        )}
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          className={`inputLabelInput ${errorMessage ? "is-invalid" : ""}`}
          {...props}
        />
        {errorMessage && (
          <div className="invalid-feedback">{errorMessage}</div>
        )}
      </div>
    );
  }
  