import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Row, Col, Table } from "react-bootstrap";
import avatar from "../../images/Avatar.png";
import show from "../../images/ShowEtu.png";
import delet from "../../images/deleteEtu.png";
import edit from "../../images/editEtu.png";
import Pagination from "../../Components/Pagination";
import api from "../api/axiosInstance";
import { success } from "../../StudentModule/utils/alerts";
import sanitizeImageUrl from "../../StudentModule/utils/anitizeImageUrl";

// Unified filter component (all in one)
import FilterComponent from "../../Components/Filters/FilterComponent";

function ParentTable() {
  const navigate = useNavigate();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPages, setItemsPages] = useState({});
  const [loading, setLoading] = useState(true);

  // Bulk Delete / checkboxes
  const [showDelete, setShowDelete] = useState(false);
  const [selectedElement, setSelectedElement] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // Original + Filtered data
  const [parents, setParents] = useState([]);
  const [elementsAfterDelete, setElementsAfterDelete] = useState([]);

  // Filter states, all in FilterComponent
  const [emailQuery, setEmailQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [countryFilter, setCountryFilter] = useState("");

  // Fetch data
  useEffect(() => {
    const fetchParents = async () => {
      try {
        const response = await api.get("/admin/parents/");
        setParents(response.data);
        setElementsAfterDelete(response.data);
        setLoading(false);
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des données des parents.",
          err
        );
      }
    };
    fetchParents();
  }, []);

  // Combined filtering: email + name/phone + country
  useEffect(() => {
    let filtered = [...parents];

    // 1) Email
    if (emailQuery) {
      filtered = filtered.filter((p) =>
        p.email.toLowerCase().includes(emailQuery.toLowerCase())
      );
    }

    // 2) Name/phone
    if (searchQuery) {
      const lower = searchQuery.toLowerCase();
      filtered = filtered.filter((p) => {
        const fullName = (p.first_name + " " + p.last_name).toLowerCase();
        const phone = p.phone_number || "";
        return fullName.includes(lower) || phone.includes(searchQuery);
      });
    }

    // 3) Country
    if (countryFilter) {
      filtered = filtered.filter((p) =>
        p.country.toLowerCase().includes(countryFilter.toLowerCase())
      );
    }

    setElementsAfterDelete(filtered);
  }, [parents, emailQuery, searchQuery, countryFilter]);

  // Recompute pagination
  useEffect(() => {
    const totalPages = Math.ceil(elementsAfterDelete.length / itemsPerPage);
    let current = 0;
    const newPages = {};

    for (let i = 1; i <= totalPages; i++) {
      newPages[i] = elementsAfterDelete.slice(current, current + itemsPerPage);
      current += itemsPerPage;
    }

    setItemsPages(newPages);
    setCurrentPage(1);
  }, [elementsAfterDelete, itemsPerPage]);

  // Detail/Edit/Add
  const handleDetail = (id) => navigate(`details/${id}`);
  const handleEdit = (id) => navigate(`modifier/${id}`);
  const handleAjouter = () => navigate("ajouter");

  // Single delete
  async function deleteOne(id) {
    const confirmation = window.confirm("Voulez-vous supprimer ce parent?");
    if (!confirmation) return;

    try {
      await api.delete(`/admin/parents/${id}/`);
      const afterDelete = parents.filter((p) => p.id !== id);
      setParents(afterDelete);
      setElementsAfterDelete(afterDelete);
      success("Le parent a été supprimé avec succès.");
    } catch (err) {
      console.log(`Erreur lors de la suppression du parent ${id}: `, err);
    }
  }

  // Checkboxes
  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleChangeCheckbox2 = (id) => {
    if (selectedElement.some((ele) => ele.id === id)) {
      const filtered = selectedElement.filter((p) => p.id !== id);
      setSelectedElement(filtered);
    } else {
      const newEle = elementsAfterDelete.find((p) => p.id === id);
      setSelectedElement([...selectedElement, newEle]);
    }
  };

  // "Select All"
  useEffect(() => {
    if (isChecked) {
      setSelectedElement(elementsAfterDelete);
    } else {
      setSelectedElement([]);
    }
  }, [isChecked, elementsAfterDelete]);

  useEffect(() => {
    setShowDelete(selectedElement.length > 0);
  }, [selectedElement]);

  // Bulk Delete
  async function handleDeleteMany() {
    const confirmation = window.confirm(
      "Voulez-vous supprimer les parents sélectionnés?"
    );
    if (!confirmation) return;

    try {
      for (const parent of selectedElement) {
        await api.delete(`/admin/parents/${parent.id}/`);
      }
      const afterDelete = parents.filter(
        (pr) => !selectedElement.find((p) => p.id === pr.id)
      );
      setParents(afterDelete);
      setElementsAfterDelete(afterDelete);
      setSelectedElement([]);
      setIsChecked(false);

      success("Les parents sélectionnés ont été supprimés avec succès.");
    } catch (err) {
      console.log("Erreur lors de la suppression en masse:", err);
    }
  }

  // Reset all
  const handleReset = () => {
    setEmailQuery("");
    setSearchQuery("");
    setCountryFilter("");
  };

  const uniqueCountries = [...new Set(parents.map((p) => p.country))];

  return (
    <div>
      <Row>
        <Col sm={12} className="btnflex1">
          <button className="addStu" onClick={handleAjouter}>
            Ajouter Un Nouveau
          </button>
        </Col>
      </Row>

      {/* FilterComponent (including email, but no status) */}
      <FilterComponent
        emailQuery={emailQuery}
        setEmailQuery={setEmailQuery}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        countryFilter={countryFilter}
        setCountryFilter={setCountryFilter}
        showStatusFilter={false}
        handleReset={handleReset}
        countries={uniqueCountries}
      />

      {/* Bulk Delete / Stats */}
      <Row className="mt-4" style={{ marginBottom: "-15px" }}>
        <div className="d-flex align-items-center justify-content-between">
          {showDelete && (
            <div
              className="d-flex align-items-center"
              onClick={handleDeleteMany}
            >
              <img
                src={delet}
                className="actionEtu"
                style={{ marginTop: "-15px" }}
                alt="delete"
              />
              <p className="para-etu-admin"> Supprimer </p>
            </div>
          )}
          <div className="d-flex align-items-center">
            <p
              style={{ marginRight: "15px", marginLeft: "15px" }}
              className="para-etu-admin"
            >
              Total: {elementsAfterDelete.length}
            </p>
            <p style={{ marginRight: "20px" }} className="para-etu-admin">
              Comptes sélectionnés: {selectedElement.length}
            </p>
          </div>
        </div>
      </Row>

      {/* Table */}
      <Row>
        <Col className="TableParent">
          <Table responsive>
            <thead>
              <tr>
                <th className="tdEtuAdm">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChangeCheckbox}
                  />
                </th>
                <th className="ThParent">S.NO</th>
                <th className="ThParent">NOM</th>
                <th className="ThParent">NUMERO DE TELEPHONE</th>
                <th className="ThParent">EMAIL ID</th>
                <th className="ThParent">COUNTRY</th>
                <th className="ThParent">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    Chargement des données...
                  </td>
                </tr>
              ) : itemsPages[currentPage]?.length > 0 ? (
                itemsPages[currentPage].map((val, index) => (
                  <tr key={val.id}>
                    <td className="tdEtuAdm">
                      <input
                        checked={selectedElement.some(
                          (ele) => ele.id === val.id
                        )}
                        type="checkbox"
                        onChange={() => handleChangeCheckbox2(val.id)}
                      />
                    </td>
                    <td className="tdEtuAdm">
                      {(currentPage - 1) * itemsPerPage + (index + 1)}
                    </td>
                    <td className="TdParent NAME" onClick={() => handleDetail(val.id)}>
                      <img
                        src={
                          val.picture ? sanitizeImageUrl(val.picture) : avatar
                        }
                        className="etuimg img-fluid img-rounded"
                        alt="avatar"
                      />
                      <span className="spantdname">
                        {val.first_name} {val.last_name}
                      </span>
                    </td>
                    <td className="TdParent PH">
                      {val.country_code}
                      {val.phone_number}
                    </td>
                    <td className="TdParent EM">{val.email}</td>
                    <td className="TdParent CT">{val.country}</td>
                    <td className="TdParent ACT">
                      <img
                        src={show}
                        onClick={() => handleDetail(val.id)}
                        className="actionEtu"
                        alt="View"
                      />
                      <img
                        src={edit}
                        onClick={() => handleEdit(val.id)}
                        className="actionEtu"
                        alt="Edit"
                      />
                      <img
                        src={delet}
                        onClick={() => deleteOne(val.id)}
                        className="actionEtu"
                        alt="Delete"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    Aucun parent trouvé.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination
            itemsPages={itemsPages}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ParentTable;
