import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row, Form, Spinner } from 'react-bootstrap';
import "../../Student_Admin/Student.css";
import next from "../../../images/nextAdd.png";
import profile from "../../../images/inpProfil.png";
import add from "../../../images/add.png";
import { useNavigate } from 'react-router';
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect';
import countries from "../../../Layout/COUNTRY.json"
import findCitiesOfCountry from '../../../Layout/findCitiesOfCountry';
import api from '../../api/axiosInstance';
import { error, success } from '../../utils/alerts';

function ParentAdd() {
  const fileInputRef = useRef(null);
  const [codeCountry, setCodeCountry] = useState("+33");
  const [profil, setProfil] = useState(profile);
  const navigate = useNavigate();

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    password: "",
    dateN: "",
    pays: "",
    genre: "",
    email: "",
    town: "",
    picture: "",
    relation: ""
  });

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  const handleBackClick = () => navigate(-1);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, picture: file });
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfil(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePays = async (e) => {
    const selectedCountry = e.target.value;
    setData({ ...data, pays: selectedCountry });
    setCities(findCitiesOfCountry(selectedCountry));
  };

  const addParent = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("password", "parent12345");
      formData.append("country_code", codeCountry);
      formData.append("student_reference_code", data.student_reference_code);
      formData.append("phone_number", data.phone);
      formData.append("country", data.pays);
      formData.append("date_of_birth", data.dateN);
      formData.append("genre", data.genre);
      formData.append("relation", data.relation);
      formData.append("picture", data.picture);
      formData.append("town", data.town);

      // Get the token from localStorage or context (you can adjust this based on your authentication mechanism)
      const token = localStorage.getItem('auth_token');
      if (token) {
        api.defaults.headers['Authorization'] = `Bearer ${token}`;
      }

      const response = await api.post("/admin/parents/", formData);

      if (response.status >= 200 && response.status < 300) {
        const msg = response.data?.data?.message || "Le parent a été ajouté avec succès.";
        success(msg);
        navigate("/administrateur/parents");
      }
    } catch (err) {
      error("Une erreur s'est produite lors de l'ajout du parent.");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <span className='RouteStu'>
            <p className='routeStu'>Parent</p>
            <p className='RouteStuRoute'>/ Ajouter Parent </p>
          </span>
        </Col>
        <Col>
          <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col>
      </Row>

      <Row>
        <Col className="addStu1">
          <Row>
            <Col sm={6}>
              <p className='titleEtu'>Compte Parent</p>
            </Col>
            <Col sm={6}>
              <span className='spanInpImg'>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className='imgInpModif'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <img
                  src={profil}
                  alt="Cliquez pour changer l'image"
                  className="spanInpImg"
                  onClick={() => fileInputRef.current.click()}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
                <img src={add} className='addImg' onClick={() => fileInputRef.current.click()} />
              </span>
            </Col>
          </Row>

          {/* Formulaire */}
          <Row>
            <Col sm={6}>
              <p className='labelInp'>Nom</p>
              <input type='text' placeholder='Votre Nom' className='inpStu' onChange={(e) => setData({ ...data, last_name: e.target.value })} />
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Prénom</p>
              <input type='text' placeholder='Votre Prénom' className='inpStu' onChange={(e) => setData({ ...data, first_name: e.target.value })} />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Numéro de téléphone</p>
              <Row>
                <Col sm={3}>
                  <CustomCountrySelectAdmin codeCountry={setCodeCountry} />
                </Col>
                <Col>
                  <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtu' onChange={(e) => setData({ ...data, phone: e.target.value })} />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Date De Naissance</p>
              <input type='date' className='inpStu' onChange={(e) => setData({ ...data, dateN: e.target.value })} />
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Pays</p>
              <Form.Select name="pays" defaultValue="" onChange={handlePays}>
                <option value="" disabled>Sélectionnez le pays</option>
                {countries.map((country) => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Ville</p>
              {
                cities.length > 0 ?
                  <Form.Select name="villeDestination" defaultValue="" onChange={(e) => setData({ ...data, town: e.target.value })}>
                    <option value="" disabled>Sélectionnez la ville</option>
                    {cities.map((ville) => (
                      <option key={ville} value={ville}>{ville}</option>
                    ))}
                  </Form.Select> :
                  <Form.Control type="text" placeholder="Entrez votre ville" onChange={(e) => setData({ ...data, town: e.target.value })} />
              }
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Adresse Email</p>
              <input type='email' placeholder='Votre Adresse Email' className='inpStu' onChange={(e) => setData({ ...data, email: e.target.value })} />
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Genre</p>
              <select className='inpStu' onChange={(e) => setData({ ...data, genre: e.target.value })}>
                <option value="">Selectionner</option>
                <option value="male">Homme</option>
                <option value="female">Femme</option>
              </select>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Relation avec étudiant</p>
              <select className='inpStu' onChange={(e) => setData({ ...data, relation: e.target.value })}>
                <option value="">Selectionner</option>
                <option value="father">Père</option>
                <option value="mother">Mère</option>
              </select>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Reference Code</p>
              <input type='text' placeholder='Reference Code' className='inpStu' onChange={(e) => setData({ ...data, student_reference_code: e.target.value })} />
            </Col>
          </Row>

          <Row className='rowBtnAdd'>
            <Col>
              <button className='creer' onClick={addParent}>
                {!loading ? (
                  <>
                    Créer
                    <img src={next} className='imgbtnadd' />
                  </>
                ) : (
                  <Spinner animation="border" size="md" />
                )}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ParentAdd;
