import React, { useState } from 'react';
import { Container, Row, Col,Form,Spinner } from 'react-bootstrap';
import bg from '../../images/loginAdmin.png'; // Importer l'image
import logo from "../../images/logoLoginAdmin.png";
import loginImgLeft from "../../images/loginImgLeft.png"
import "./Login.css";
import next from "../../images/loginNext.png";
import { useNavigate } from 'react-router';
import api from "../api/axiosInstance";
import { error, success } from "../utils/alerts";
import extractError from "../utils/extractError";

function LoginAdmin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);


  const openEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
  const closedEye=<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z"/></svg>


  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();


    const currentErrors = {};

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      currentErrors.identifiant = "Veuillez entrer une adresse e-mail valide";
    }

    if (!password) {
      currentErrors.password = "Le mot de passe est un champ obligatoire.";
    }


    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);

      const userInput = {
        email:email,
        password,
      };

      const endPoint = "login/";
      const response = await api.post(endPoint, JSON.stringify(userInput));
      if (response.status === 200) {
        const { email, first_name, last_name, picture, tokens,role,phone_number } =
          response.data.data;
          console.log(response)
        localStorage.setItem("accessToken", tokens.access);
        localStorage.setItem("refreshToken", tokens.refresh);
        localStorage.setItem("firstname", first_name);
        localStorage.setItem("lastname", last_name);
        localStorage.setItem("picture", picture);
        localStorage.setItem("phone", phone_number);
        localStorage.setItem("email", email);
        localStorage.setItem("role", role);
        console.log(role)
        if(role !== "admin"){
          error("vous avez pas la permission pour se connecter");
        }
       
        else {
          console.log(response.data.data)
          navigate("/administrateur/");
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errorObject = err.response?.data?.data;
        const errorMessages = extractError(errorObject);
        errorMessages.forEach((message) => {
          error(message);
        });
      } else if (err.response && err.response.status === 401) {
        error(err.response.data.errors.detail);
      } else {
        error("Something Went Wrong");
      }
    } finally {
      setLoading(false);
    }
  }



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
const navigate=useNavigate()
  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container>
        <Row>
          <Col sm={7} >
           {/* <img src={loginImgLeft}/> */}
          </Col>
          <Col sm={5} className='LoginCardAdm'>
          
          <Row>
              <Col className='logoAdm'>
                <img src={logo} alt='logo UFS' className='logoLogin' />
              </Col>
            </Row>
            <Row>
              <Col>
                <p className='loginAdm'>SE CONNECTER</p>
                <p className='sousLogin'>Veuillez remplir le formulaire pour vous connecter</p>
              </Col>
            </Row>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <label className='labelLoginAdm'>Identifiant de courrier électronique*</label><br />
                  <input
                    className='inpLogAdm'
                    value={email}
                    type='email' // Changed to 'email' for better validation
                    name='email'
                    placeholder='abc@gmail.com'
                    onChange={handleEmail} // Attach the onChange handler
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label  className='labelLoginAdm'>Mot de passe*</label><br />
                  <div className="d-flex align-items-center justify-content-between "   style={{position:"relative"}}>
<Form.Control
 type={showPassword ? "text" : "password"} 
 value={password}
 placeholder="****************" 
 name="password" 
  onChange={(e)=>{setPassword(e.target.value)}}
   isInvalid={errors?.password ? true : false}
/>
<p onClick={togglePasswordVisibility} className="eye-password-student"  style={{
   position: "absolute",
   right: "0.75rem", 
   top: "50%",
   transform: "translateY(-50%)",
   cursor: "pointer",
   color: "#888888"
 }} >
 {showPassword ? closedEye : openEye} 
</p>
</div>
                </Col>
              </Row>
              <Row>
                <p onClick={()=>{navigate('/renitialisation-mot-de-passe')}} className='forgetpwd'>Mot de passe oublié?</p>
              </Row>
              <Row>
                <button type='submit'   className='button-Register' onClick={(e)=>{handleSubmit(e)}}>
                {
                            !loading ? (
                              <>
                              Se connecter
                                <svg className="Register-fleche" width="29" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                      <rect y="0.5" width="29" height="29" rx="14.5" fill="white"/>
                                      <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </>
                            ) : (
                              <Spinner animation="border" size="md"/>
                            )
                          }
                </button>
              </Row>
            </form>
          
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginAdmin;
