import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import "./Login.css"
import { useState } from "react";
import logo from "../../images/logo-colorful.png";
import { Link, useNavigate } from "react-router-dom";
import api from "../api/axiosInstance";
import bg from "../../images/dash.png"
import TwoManImg from "../assets/MERE.png";
import { error, success } from "../utils/alerts";
import extractError from "../utils/extractError";

import InputLabel from "../../Components/InputLabel";

export default function Login() {
  const navigate = useNavigate();
  const [identifiant, setIdentifiant] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});


  const openEye = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" /></svg>
  const closedEye = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m644-428-58-58q9-47-27-88t-93-32l-58-58q17-8 34.5-12t37.5-4q75 0 127.5 52.5T660-500q0 20-4 37.5T644-428Zm128 126-58-56q38-29 67.5-63.5T832-500q-50-101-143.5-160.5T480-720q-29 0-57 4t-55 12l-62-62q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302Zm20 246L624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM222-624q-29 26-53 57t-41 67q50 101 143.5 160.5T480-280q20 0 39-2.5t39-5.5l-36-38q-11 3-21 4.5t-21 1.5q-75 0-127.5-52.5T300-500q0-11 1.5-21t4.5-21l-84-82Zm319 93Zm-151 75Z" /></svg>




  async function handleSubmit(e) {
    e.preventDefault();


    const currentErrors = {};

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(identifiant)) {
      currentErrors.identifiant = "Veuillez entrer une adresse e-mail valide";
    }

    if (!password) {
      currentErrors.password = "Le mot de passe est un champ obligatoire.";
    }


    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);

      const userInput = {
        email: identifiant,
        password,
      };

      const endPoint = "login/";
      const response = await api.post(endPoint, JSON.stringify(userInput));
      if (response.status === 200) {
        const { email, first_name, last_name, picture, tokens, role , country_code, country,phone_number, town, date_of_birth} =
          response.data.data;
        localStorage.setItem("accessToken", tokens.access);
        localStorage.setItem("refreshToken", tokens.refresh);
        localStorage.setItem("firstname", first_name);
        localStorage.setItem("lastname", last_name);
        localStorage.setItem("picture", picture);
        localStorage.setItem("email", email);
        localStorage.setItem("codeCountry", country_code);
        localStorage.setItem("phone", phone_number);
        localStorage.setItem("role", role);
        localStorage.setItem("country", country);
        localStorage.setItem("birthPlace", town);
        localStorage.setItem("birthDate", date_of_birth);

        console.log(role)
        if (role !== "parent") {
          error("vous avez pas la permission pour se connecter");
        }
        // success(`Bienvenue ${response.data.data.username}`);
        else { navigate("/parent"); }

      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errorObject = err.response?.data?.data;
        const errorMessages = extractError(errorObject);
        errorMessages.forEach((message) => {
          error(message);
        });
      } else if (err.response && err.response.status === 401) {
        error(err.response.data.errors.detail);
      } else {
        error("Contactez le support svp");
      }
    } finally {
      setLoading(false);
    }
  }


  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="parentLogin parentLogin2" style={{ backgroundImage: `url(${bg})`, backgroundAttachment: 'fixed', backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
      <div className="parentLogin parentLogin2" style={{ backgroundImage: `url(${bg})`, backgroundAttachment: 'fixed', backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <Container className="loginParentContainer" >
          <Col className="ColLoginParent" lg={6} md={6}>
            <div className="parentLoginLeftSection parentLoginLeftSectionheight">
              <div className="logoImgCard logo-card">
                <img src={logo} />
              </div>
              <h1 className="headeLoginPar">Se connecter</h1>
              <div className="parentLoginHeader">
                <p>Veuillez remplir le formulaire pour vous connecter</p>
                <p>
                  Vous n'avez pas de compte ?
                  <span>
                    <Link to={"/parent/register"}>Inscrivez-vous</Link>
                  </span>
                </p>
              </div>

              <div className="parentLoginForm">
                <InputLabel

                  type="text" placeholder="abc@gmail.com"
                  name="id"
                  onChange={(e) => { setIdentifiant(e.target.value) }}
                  isInvalid={errors?.identifiant ? true : false}
                  errorMessage={errors?.identifiant && errors.identifiant}

                />
                <div className="passwordContainer">

                  <InputLabel
                    type={showPassword ? "text" : "password"}
                    value={password}
                    placeholder="****************"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={errors?.password ? true : false}
                    errorMessage={errors?.password}
                    aria-label="Mot de passe"
                    aria-invalid={errors?.password ? "true" : "false"}
                  />
                  <p
                    onClick={togglePasswordVisibility}
                    className="eye-password-student"
                    style={{
                      position: "absolute",
                      right: "0.75rem",
                      top: "63%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#888888"
                    }}
                    aria-label="Afficher/Masquer le mot de passe"
                  >
                    {showPassword ? closedEye : openEye}
                  </p>
                </div>
                <div className="rememberMeForgotPassword">
                  <div className="rememberMe">
                    <input type="checkbox" id="checkbox"></input>
                    <label htmlFor="checkbox">Se Souvenir de moi</label>
                  </div>
                  <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Link to="/renitialisation-mot-de-passe" style={{ color:'black' ,textDecoration: "none" }}> <p className='label-checkbox-login' style={{ fontWeight: "500", textAlign: "right" }}>Mot de passe oublié?</p> </Link>
                  </Col>                </div>
                <div className="parent-login-switch-to-student">
                  <div>
                    Vous n'êtes pas parent - <span onClick={() => navigate('/etudiant/login')}>Espace Etudiant</span>
                  </div>

                </div>
                <button className="parentLoginFormButton"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                  aria-label="Submit"
                >
                  {
                    !loading ? (
                      <>
                        Se connecter
                        <svg className="Register-fleche" width="29" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
                          <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
                          <path d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136" stroke="#015192" strokeWidth="1.375" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </>
                    ) : (
                      <Spinner animation="border" size="md" />
                    )
                  }
                </button>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} className="rightImgLoginCol">
            <div className="rightSectionImgCard1">
              <img src={TwoManImg} className="bottomImage" />
            </div>
          </Col>
        </Container>
      </div>
    </div>
  );


}
