import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import profile from "../../images/etuAdm.png";
import show from "../../images/ShowEtu.png";
import delet from "../../images/deleteEtu.png";
import edit from "../../images/editEtu.png";
import Pagination from "../../Components/Pagination";
import api from "../api/axiosInstance";
import { success } from "../../StudentModule/utils/alerts";
import sanitizeImageUrl from "../../StudentModule/utils/anitizeImageUrl";

// Unified filter component
import FilterComponent from "../../Components/Filters/FilterComponent";

function Student() {
  const navigate = useNavigate();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPages, setItemsPages] = useState({});
  const [loading, setLoading] = useState(true);

  // Checkboxes / Bulk Delete
  const [showDelete, setShowDelete] = useState(false);
  const [selectedElement, setSelectedElement] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // Original + Filtered data
  const [students, setStudents] = useState([]);
  const [elementsAfterDelete, setElementsAfterDelete] = useState([]);

  // Filter states (all in FilterComponent)
  const [emailQuery, setEmailQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // name/phone
  const [countryFilter, setCountryFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  // Fetch data
//  useEffect(() => {
 //   const fetchStudents = async () => {
   //   try {
     //   const response = await api.get("/admin/students/");
      //  setStudents(response.data);
       /// setElementsAfterDelete(response.data);
        //setLoading(false);
      ///} catch (err) {
        //console.error(
          //"Erreur lors de la récupération des données des students.",
         // err
        //);
      //}
    //};
    //fetchStudents();
  //}, []);
  useEffect(()=>{
      const fetchStudents = async () =>{
        try {
          const response = await api.get("/admin/students/");
          const Listestudents = response.data.students;
          console.log('test',Listestudents);
          // IMAGE




         setStudents(Listestudents)
         setElementsAfterDelete(Listestudents)
         setLoading(false);

        } catch (err) {
          console.error(
            "Erreur lors de la récupération des données des students."
          );
        }
      }
      fetchStudents()
    },[])

  // Combine all filters in one useEffect
  useEffect(() => {
    let filtered = [...students];

    // 1) Email
    if (emailQuery) {
      filtered = filtered.filter((stu) =>
        stu.email.toLowerCase().includes(emailQuery.toLowerCase())
      );
    }

    // 2) Name/Phone
    if (searchQuery) {
      const lower = searchQuery.toLowerCase();
      filtered = filtered.filter((stu) => {
        const fullName = (stu.first_name + " " + stu.last_name).toLowerCase();
        const phone = stu.phone_number || "";
        return fullName.includes(lower) || phone.includes(searchQuery);
      });
    }

    // 3) Country
    if (countryFilter) {
      filtered = filtered.filter((stu) =>
        stu.country.toLowerCase().includes(countryFilter.toLowerCase())
      );
    }

    // 4) Status
    if (statusFilter) {
      filtered = filtered.filter((stu) =>
        stu.is_active ? statusFilter === "active" : statusFilter === "inactive"
      );
    }

    setElementsAfterDelete(filtered);
  }, [students, emailQuery, searchQuery, countryFilter, statusFilter]);

  // Recompute pagination
  useEffect(() => {
    const totalPages = Math.ceil(elementsAfterDelete.length / itemsPerPage);
    let current = 0;
    const newPages = {};

    for (let i = 1; i <= totalPages; i++) {
      newPages[i] = elementsAfterDelete.slice(current, current + itemsPerPage);
      current += itemsPerPage;
    }

    setItemsPages(newPages);
    setCurrentPage(1);
  }, [elementsAfterDelete, itemsPerPage]);

  // Actions
  const handleDetail = (id) => navigate(`details/${id}`);
  const handleEdit = (id) => navigate(`modifier/${id}`);
  const handleAjouter = () => navigate("ajouter");

  // Single delete
  async function deleteOne(id) {
    const confirmation = window.confirm("Voulez-vous supprimer cet étudiant?");
    if (!confirmation) return;

    try {
      await api.delete(`/admin/students/${id}/`);
      const afterDelete = students.filter((stu) => stu.id !== id);
      setStudents(afterDelete);
      setElementsAfterDelete(afterDelete);
      success("L'étudiant a été supprimé avec succès.");
    } catch (err) {
      console.log(`Erreur lors de la suppression de l'étudiant ${id}: `, err);
    }
  }

  // Checkbox logic
  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleChangeCheckbox2 = (id) => {
    if (selectedElement.some((ele) => ele.id === id)) {
      const filtered = selectedElement.filter((stu) => stu.id !== id);
      setSelectedElement(filtered);
    } else {
      const newEle = elementsAfterDelete.find((stu) => stu.id === id);
      setSelectedElement([...selectedElement, newEle]);
    }
  };

  // "Select All"
  useEffect(() => {
    if (isChecked) {
      // Usually we select the "filtered" set
      setSelectedElement(elementsAfterDelete);
    } else {
      setSelectedElement([]);
    }
  }, [isChecked, elementsAfterDelete]);

  useEffect(() => {
    setShowDelete(selectedElement.length > 0);
  }, [selectedElement]);

  // Bulk delete
  async function handleDeleteMany() {
    const confirmation = window.confirm(
      "Voulez-vous supprimer les étudiants sélectionnés?"
    );
    if (!confirmation) return;

    try {
      for (const etu of selectedElement) {
        await api.delete(`/admin/students/${etu.id}/`);
      }
      const afterDelete = students.filter(
        (stu) => !selectedElement.find((etu) => etu.id === stu.id)
      );
      setStudents(afterDelete);
      setElementsAfterDelete(afterDelete);
      setSelectedElement([]);
      setIsChecked(false);
      success("Les étudiants sélectionnés ont été supprimés avec succès.");
    } catch (err) {
      console.log("Erreur lors de la suppression en masse:", err);
    }
  }

  // Reset all filters
  const handleReset = () => {
    setEmailQuery("");
    setSearchQuery("");
    setCountryFilter("");
    setStatusFilter("");
  };

  const uniqueCountries = [...new Set(students.map((stu) => stu.country))];

  return (
    <div>
      <Row>
        <Col sm={12} className="btnflex">
          <button className="addStu" onClick={handleAjouter}>
            Ajouter Un Nouveau
          </button>
        </Col>
      </Row>

      {/* All filters in one component (including email) */}
      <FilterComponent
        emailQuery={emailQuery}
        setEmailQuery={setEmailQuery}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        countryFilter={countryFilter}
        setCountryFilter={setCountryFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        showStatusFilter={true} // Student shows status
        handleReset={handleReset}
        countries={uniqueCountries}
      />

      {/* Bulk Delete / Stats */}
      <Row className="mt-4" style={{ marginBottom: "-15px" }}>
        <div className="d-flex align-items-center justify-content-between">
          {showDelete && (
            <div
              className="d-flex align-items-center"
              onClick={handleDeleteMany}
            >
              <img
                src={delet}
                className="actionEtu"
                style={{ marginTop: "-15px" }}
                alt="delete"
              />
              <p className="para-etu-admin"> Supprimer </p>
            </div>
          )}
          <div className="d-flex align-items-center">
            <p
              style={{ marginRight: "15px", marginLeft: "15px" }}
              className="para-etu-admin"
            >
              Total: {elementsAfterDelete.length}
            </p>
            <p style={{ marginRight: "20px" }} className="para-etu-admin">
              Comptes sélectionnés: {selectedElement.length}
            </p>
          </div>
        </div>
      </Row>

      {/* Table */}
      <Row>
        <Col sm={12} className="TabStuAdm">
          <Table responsive>
            <thead>
              <tr>
                <th className="tdEtuAdm">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChangeCheckbox}
                  />
                </th>
                <th className="thEtu NO">S.NO</th>
                <th className="thEtu Nom">Name</th>
                <th className="thEtu Phone">Telephone</th>
                <th className="thEtu Email">Email</th>
                <th className="thEtu Country">Pays</th>
                <th className="thEtu Country">Statut</th>
                <th className="thEtu">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>
                    Chargement des données...
                  </td>
                </tr>
              ) : itemsPages[currentPage]?.length > 0 ? (
                itemsPages[currentPage].map((val, index) => (
                  <tr key={val.id}>
                    <td className="tdEtuAdm">
                      <input
                        checked={selectedElement.some(
                          (ele) => ele.id === val.id
                        )}
                        type="checkbox"
                        onChange={() => handleChangeCheckbox2(val.id)}
                      />
                    </td>
                    <td className="tdEtuAdm">
                      {(currentPage - 1) * itemsPerPage + (index + 1)}
                    </td>
                    <td
                      className="nameTh pr-5 cur"
                      onClick={() => handleDetail(val.id)}
                    >
                      <img
                        src={
                          val.picture ? sanitizeImageUrl(val.picture) : profile
                        }
                        className="etuimg img-fluid img-rounded"
                        alt="stu-profile"
                      />
                      {val.first_name} {val.last_name}
                    </td>
                    <td className="tdEtuAdm">
                      {val.country_code} {val.phone_number}
                    </td>
                    <td className="tdEtuAdm">{val.email}</td>
                    <td className="tdEtuAdm">{val.country}</td>
                    <td className="tdEtuAdm">
                      {val.is_active ? "Activé" : "Désactivé"}
                    </td>
                    <td className="tdEtuAdm">
                      <img
                        src={show}
                        onClick={() => handleDetail(val.id)}
                        className="actionEtu"
                        alt="details"
                      />
                      <img
                        src={edit}
                        onClick={() => handleEdit(val.id)}
                        className="actionEtu"
                        alt="edit"
                      />
                      <img
                        src={delet}
                        onClick={() => deleteOne(val.id)}
                        className="actionEtu"
                        alt="delete"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>
                    Aucun étudiant trouvé.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <Pagination
            itemsPages={itemsPages}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalItems={elementsAfterDelete.length}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Student;
