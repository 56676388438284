import { Row, Col, Form, Button, Spinner } from "react-bootstrap";

import api from "../api/axiosInstance";
import { IoPencilSharp } from 'react-icons/io5';

import CustomCountrySelect from "../Layouts/CountrySelect";
import { useState, useEffect, useMemo } from "react";
import "./AssuranceCompletes.css";
import axios from "axios";
import nationalities from "../Layouts/nationalities.json";
import FileUpload from "../Layouts/FileUpload";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router";
import countries from '../../Layout/COUNTRY.json';

import { error ,success} from "../utils/alerts";
import sanitizeImageUrl from "../utils/anitizeImageUrl";
const icon = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.5"
      width="39"
      height="39"
      rx="10"
      fill="url(#paint0_linear_991_5415)"
      fill-opacity="0.1"
    />
    <path
      d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5"
      stroke="url(#paint1_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75"
      stroke="url(#paint2_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z"
      stroke="url(#paint3_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_991_5415"
        x1="-0.37396"
        y1="10.8019"
        x2="37.6153"
        y2="11.1255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_991_5415"
        x1="14.685"
        y1="20.0849"
        x2="18.5815"
        y2="20.107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_991_5415"
        x1="9.43002"
        y1="14.783"
        x2="28.9117"
        y2="14.949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_991_5415"
        x1="21.622"
        y1="11.6132"
        x2="29.4147"
        y2="11.6796"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
    </defs>
  </svg>
);

export default function AssurancesCompletes() {
  const [isUpdateForm, setIsUpdateForm] = useState(true);
  const [showInputAssurance, setShowInputAssurance] = useState(false);
  const getLocalStorageItem = (key, defaultValue = "") => localStorage.getItem(key) || defaultValue;


  const [lieux, setLieux] = useState([]);

  const [showInputBeneficiary, setShowInputBeneficiary] = useState(false);
  const navigate = useNavigate();

  //useState pour Backend
  const [prenom, setPrenom] = useState(getLocalStorageItem("lastname"));
  const [nom, setNom] = useState(getLocalStorageItem("firstname"));
  const [tel, setTel] = useState(getLocalStorageItem("phone"));
  const [codeCountry, setCodeCountry] = useState(getLocalStorageItem("codeCountry"));
  const [pays, setPays] = useState(getLocalStorageItem("country"));
  const [dateN, setDateN] = useState(getLocalStorageItem("birthDate"));
  const [lieu, setLieu] = useState(getLocalStorageItem("birthPlace"));
  const [adresse, setAdresse] = useState("");
  const [nationality, setNationality] = useState(getLocalStorageItem("country"));

 


  const [dateDebut, setDateDebut] = useState("");
  const [dure, setDuree] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [otherInsurance, setOtherInsurance] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [otherBeneficiary, setOtherBeneficary] = useState("");
  const [montant, setMontant] = useState("");
  const [modePaiement, setModePaiement] = useState("");
  const [type, setModePaiementType] = useState("");

  const [numcarte, setNumCarte] = useState("");
  const [dateExp, setDateExp] = useState("");
  const [cvv, setCvv] = useState("");

  const [passport, setPassport] = useState("");
  const [justificatif, setJustificatif] = useState("");
  const [text,setText]=useState("Soumettre");
  const[id,setId]=useState(null);

  const [loading,setLoading]=useState(false)
  const [infoVirement,setInforVirement]=useState({
    nomBanc:"",
    IBAN:"",
    BIC:"",
    codeBanc:""
  })
  const [preuvePaiement,setPreuvePaiement]=useState("");
  const insuranceDurations = [1, 2, 3, 6, 12];
  const [submitted, setSubmitted] = useState(false); // Pour savoir si le formulaire a été soumis

  const insuranceTypes = [
    "Assurance santé internationale",
    "Assurance voyage",
    "Assurance responsabilité civile",
    "Assurance accident de la vie",
    "Assurance scolaire",
    "Assurance équipement personnel",
    "Assurance des prêts étudiants",
    "Assurance annulation de scolarité",
    "Assurance habitation temporaire",
    "Assurance protection juridique",
 
  ];

  const beneficiaries = [
    { label: "Conjoint(e)", value: "conjoint" },
    { label: "Parents", value: "parents" },
    { label: "Frères et sœurs", value: "freres_soeurs" },
    { label: "Proches (famille étendue)", value: "proches" },
    { label: "Organisation caritative", value: "organisation_caritative" },
    { label: "Succession", value: "succession" },
  ];

  const [errors,setErrors]=useState({})

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");

        const countryData = response.data.map((country) => ({
          name: country.translations.fra.common,

          code: country.name.common,
        }));

        countryData.sort((a, b) => a.name.localeCompare(b.name));

        setLieux(countryData);
      } catch (error) {
        console.error("Erreur lors de la récupération des pays:", error);
      }
    };

    fetchCountries();
  }, []);

  function handleCodeCountry(c) {
    setCodeCountry(c);
  }

  function handleAssurance(e) {
    const selectedAssurance = e.target.value;
    setSelectedInsurance(selectedAssurance);
    setShowInputAssurance(selectedAssurance === "Autre");
  }

  function handleBeneficiary(e) {
    const selectedBeneficie = e.target.value;
    setSelectedBeneficiary(selectedBeneficie);
    setShowInputBeneficiary(selectedBeneficie === "Autre");
  }
  function updateJustificatif(valeur){
         setJustificatif(valeur)
  }
  function updatePassport(valeur){
    setPassport(valeur)
}

function renameFile(file, newName) {
  return new File([file], newName, { type: file.type });
}
//handel mode payment 
const options = [
  { label: "Carte de crédit", value: "carte" },
  { label: "PayPal", value: "paypal" },
  { label: "Virement", value: "virement" },
  { label: "Preuve", value: "preuve" },
];


useEffect(()=>{
  console.log("pass",passport)
  console.log("justificatif",justificatif)

},
[passport,justificatif])





const handleModeChange = (e) => {
  const selectedMode = e.target.value; // Récupérer la valeur sélectionnée
  setModePaiementType(selectedMode); // Mettre à jour l'état
  console.log("Mode de paiement sélectionné :", selectedMode); // Afficher dans la console
};
//handled mode payment
const handleAssuranceClick = async () => {
  const today = new Date();
  setSubmitted(true); 
  const validNamePattern = /^[A-Za-z\- ]+$/; 
  const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  const phonePattern = /^\d{9,15}$/;
  const universitePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9'’\s\-]+$/;

  const montantPattern = /^\d+$/;
  const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;

  let currentErrors={};

  if(!prenom.trim()){
    currentErrors.prenom="le prénom ne doit pas être vide"
  }else if(!validNamePattern.test(prenom)){
    currentErrors.prenom="Entrez un prénom valide"
  }
  else{
    delete currentErrors.prenom
  }


  if(!nom.trim()){
    currentErrors.nom="le nom de la famille ne doit pas être vide"
  }else if(!validNamePattern.test(nom)){
    currentErrors.nom="Entrez un nom de famille valide"
  }
  else{
    delete currentErrors.nom
  }

  if(!tel.trim()){
    currentErrors.tel="le numéro téléphone ne doit pas être vide"
  }else if(!phonePattern.test(tel)){
    currentErrors.tel="Entrez un numéro de téléphone valide"
  }
  else{
    delete currentErrors.tel
  }

  if(!pays.trim()){
    currentErrors.pays="le pays ne doit pas être vide"
  }else if(!countryPattern.test(pays)){
    currentErrors.pays="Entrez un numéro de téléphone valide"
  }
  else{
    delete currentErrors.pays
  }

  if(!dateN.trim()){
    currentErrors.dateN="la date de naissance ne doit pas être vide"
  }
  else{
    delete currentErrors.dateN
  }

  if(!lieu.trim()){
    currentErrors.lieu="le lieu de naissance ne doit pas être vide"
  }
  else if(!validVillePattern.test(lieu)){
     currentErrors.lieu="Entrez un  lieu de naissance valide"
  }
  else{
    delete currentErrors.lieu
  }

  if(!nationality.trim()){
    currentErrors.nationality="la nationalité ne doit pas être vide"
  }
  else{
    delete currentErrors.nationality
  }

  if(!dateDebut.trim()){
    currentErrors.dateDebut="la date de début ne doit pas être vide"
  }
  else if(dateDebut <today){
    currentErrors.dateDebut="la date de début doit être dans le future"
  }
  else{
    delete currentErrors.dateDebut
  }


  if(!dure){
    currentErrors.dure="la durée d'assurance ne doit pas être vide"
  }
  
  else{
    delete currentErrors.dure
  }

  if(!selectedInsurance.trim()){
    currentErrors.typeAssurance="le type d'assurance ne doit pas être vide"
  }
  
  else{
    delete currentErrors.typeAssurance
  }

  if(!selectedBeneficiary.trim()){
    currentErrors.beneficiary="le bénéficiaire ne doit pas être vide"
  }
  
  else{
    delete currentErrors.beneficiary
  }

  if(!montant.trim()){
    currentErrors.montant="le montant ne doit pas être vide"
  }
  
  else{
    delete currentErrors.montant
  }

  if(!justificatif){
    currentErrors.justificatiif="le fichier de justificatif ne doit pas être vide"
  }
 
  else{
    delete currentErrors.justificatiif
  }

  if(!passport){
    currentErrors.passport="le passport ne doit pas être vide"
  }
 
  else{
    delete currentErrors.passport
  }

  if(!type){
    currentErrors.modePaiement="Choisissez un mode de paiement."
  }
 
  else{
    delete currentErrors.modePaiement
  }

  setErrors(currentErrors);
    if (Object.keys(currentErrors).length > 0) {
      return; 
  } 


  try {
    setLoading(true)
    const formData = new FormData();
    formData.append("first_name", prenom);
    formData.append("last_name", nom);
    formData.append("phone_number", tel);
    formData.append("place_of_birth", lieu);
    formData.append("nationality", nationality || "");
    formData.append("country_code", codeCountry);
    formData.append("country", pays);
    formData.append("start_date", dateDebut);
    formData.append("insurance_duration", dure);
    formData.append("insurance_type", selectedInsurance !== "Autre" ? selectedInsurance : otherInsurance);
    formData.append("beneficiaries", selectedBeneficiary !== "Autre" ? selectedBeneficiary : otherBeneficiary);
    formData.append("insured_amount", montant);
    formData.append("payment_method.type", type);


    if(type=="carte"){
      formData.append("payment_method.virement_banque_nom", null);
      formData.append("payment_method.virement_iban", null);
      formData.append("payment_method.virement_bic_swift", null);
      formData.append("payment_method.virement_code_bancaire", null);

      formData.append("payment_method.carte_card_number", numcarte);
      formData.append("payment_method.carte_expiration_date", dateExp);
      formData.append("payment_method.carte_cvv", cvv);

  }   
  if(type=="virement"){


    formData.append("payment_method.carte_card_number", null);
    formData.append("payment_method.carte_expiration_date", null);
    formData.append("payment_method.carte_cvv", null);
    
    formData.append("payment_method.virement_banque_nom", infoVirement.nomBanc);
    formData.append("payment_method.virement_iban", infoVirement.IBAN);
    formData.append("payment_method.virement_bic_swift", infoVirement.BIC);
    formData.append("payment_method.virement_code_bancaire", infoVirement.codeBanc);

  }
  if (justificatif instanceof File) {
    formData.append("files.preliminary_acceptance", justificatif);
  } else {
    console.log("preliminary_acceptance n'est pas un fichier");
  }

 
  if (passport instanceof File) {
    formData.append("files.passport_copy", passport);
  } else {
    console.log("passport_copy n'est pas un fichier");
  }
   
    if(type=="preuve"){
      if ( preuvePaiement && preuvePaiement.name) {
        const newName = preuvePaiement.name.slice(0, 95) + preuvePaiement.name.slice(-5);
        const renamedFile = renameFile(preuvePaiement, newName);
        formData.append("files.payment_proof", renamedFile);

      } else {
        console.error("payment Proof File est invalide ou vide.");
      }
      

    }    
    if(text!="modifier"){
      const response = await api.post("/student/insurance/", formData);
      console.log(" read response:",response)


      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Le service d'assurances complètes a été mis à jour avec succès.";
        success(msg);
        setLoading(false)
      }
      else {

        console.log("Verifiez les informations")
        setLoading(false)
      }
    }
    
     else{
      const response = await api.put("/student/insurance/", formData);

      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Le service d'assurances complètes a été mis à jour avec succès.";
      
        success(msg);
        setLoading(false)
      }
      else {

        console.log("Verifiez les informations")
        setLoading(false)
      }
     } 
    
    
  } catch (err) {
    console.log("error try")
    console.error("Une erreur est survenue:", err);
    setLoading(false)
  }
  finally{
    setLoading(false)
  }

};



  // Fetch insurance data on component mount
  useEffect(() => {
    const fetchInsuranceData = async () => {
      try {
        const response = await api.get("/student/insurance/");
        const insuranceData = response.data;
        console.log(insuranceData)

        setPrenom(insuranceData.first_name || "");
        setNom(insuranceData.last_name || "");
        setTel(insuranceData.phone_number || "");
        setCodeCountry(insuranceData.country_code || "+33");
        setPays(insuranceData.country || "");
        setDateN(insuranceData.date_of_birth || "");
        setLieu(insuranceData.place_of_birth || "");
        setAdresse(insuranceData.adresse || "");
        setNationality(insuranceData.nationality || "");

        // Données spécifiques à l'assurance
        setDateDebut(insuranceData.start_date || "");
        setDuree(insuranceData.insurance_duration || "");
        setSelectedInsurance(insuranceData.insurance_type || "");
        setOtherInsurance(insuranceData.insurance_type || "");
        setSelectedBeneficiary(insuranceData.beneficiaries|| "");
        setOtherBeneficary(insuranceData.beneficiaries|| "");
        setMontant(insuranceData.insured_amount|| "");
        setModePaiement(insuranceData.payment_method|| "");
        setModePaiementType(insuranceData.payment_method.type|| "");
        console.log("typeeee",insuranceData.payment_method)
        if (insuranceData.payment_method.type === "carte") {
          setNumCarte(insuranceData.payment_method.carte_card_number || "");
          setDateExp(insuranceData.payment_method.carte_expiration_date || "");
          setCvv(insuranceData.payment_method.carte_cvv || "");
        }
  
        if (insuranceData.payment_method.type === "virement") {
          
          setInforVirement({
            nomBanc: insuranceData.payment_method.virement_banque_nom || "",
            IBAN: insuranceData.payment_method.virement_iban || "",
            BIC: insuranceData.payment_method.virement_bic_swift || "",
            codeBanc: insuranceData.payment_method.virement_code_bancaire || "",
          });
        }
        if(insuranceData.id)
          { setText("modifier")}
        // Documents
        setPassport(sanitizeImageUrl(insuranceData.files.passport_copy || ""));
        setJustificatif(sanitizeImageUrl(insuranceData.files.preliminary_acceptance || ""));
        setId(insuranceData.id || null)
        console.log(insuranceData.id)
        
       

      } catch (err) {
        console.error(
          "Erreur lors de la récupération des données d'assurance."
        );
      }
    };

    fetchInsuranceData();
  }, []);
  useEffect(() => {
   
    if (selectedInsurance && !insuranceTypes.includes(selectedInsurance)) {
      
      setSelectedInsurance("Autre");
      setShowInputAssurance(true);
       
    } else {
      setShowInputAssurance(false);
    }
  }, [selectedInsurance, insuranceTypes]);

  useEffect(() => {
    
    if (
      selectedBeneficiary &&
      !beneficiaries.some((b) => b.value === selectedBeneficiary)
    ) {
      
      setSelectedBeneficiary("Autre");
      setShowInputBeneficiary(true);
     
    } else {
      setShowInputBeneficiary(false);
    }
  }, [selectedBeneficiary, beneficiaries]);
  
  
  return (
    <div className="animParent">
      <Row className="mt-4 mb-3 d-flex justify-content-end button-retour-container">
        <button
          onClick={() =>
            navigate("/etudiant/prestations-de-service/")
          }
          type="submit"
          className="button-retour-prestation"
        >
          <IoArrowBackCircle className="retour-assurance-icon" />
          Retour
        </button>
       
      </Row>

      <Row
        className="student-assurance-container mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Assurances Complètes</h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={prenom}
                    placeholder="Entrez votre prénom"
                    onChange={(e) => {
                      setPrenom(e.target.value);
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
    {errors.prenom && errors.prenom}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Nom de famille
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={nom}
                    placeholder="Entrez votre nom de famille"
                    onChange={(e) => {
                      setNom(e.target.value);
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
    {errors.nom && errors.nom}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Numéro de téléphone</Form.Label>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={4} xs={12}>
                      <CustomCountrySelect codeCountry={handleCodeCountry} dialCode={codeCountry} />
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                      <Form.Control
                        type="text"
                        name="tel"
                        value={tel}
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                        required
                      />
                    </Col>
                    <Form.Control.Feedback type="invalid">
    {errors.tel && errors.tel}
  </Form.Control.Feedback>
                  </Row>

                </Form.Group>
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              Lieu de naissance
              <Form.Control
                  name="lieu"
                  value={lieu}
                  onChange={(e) => setLieu(e.target.value)}
                  placeholder="Lieu de naissance"
                />
                <Form.Control.Feedback type="invalid">
    {errors.lieu && errors.lieu}
  </Form.Control.Feedback>

              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateN"
                    value={dateN}
                    onChange={(e) => {
                      setDateN(e.target.value);
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
    {errors.dateN && errors.dateN}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <Form.Group>
                  <Form.Label>Nationalité</Form.Label>
                  <Form.Select
                    name="lieu"
                    defaultValue=""
                    value={nationality}
                    onChange={(e) => {
                      setNationality(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled>
                      Sélectionnez votre nationalité
                    </option>
                    {countries.map((country) => (
                      <option
                        key={country}
                        value={country}
                        selected={country}
                      >
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
    {errors.nationality && errors.nationality}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Col>
      </Row>
      <Row
        className="student-assurance-container2 mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">
            Information sur l'assurance
          </h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de début</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateDebut"
                    value={dateDebut}
                    onChange={(e) => {
                      setDateDebut(e.target.value);
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
    {errors.dateDebut && errors.dateDebut}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Durée d'assurance
                  </Form.Label>
                  <Form.Select
                    value={dure}
                    onChange={(e) => {
                      setDuree(e.target.value);
                    }}
                    required
                  >
                    <option>Sélectionner</option>
                    {insuranceDurations.map((insurance) => {
                      return (
                        <option value={insurance} key={insurance} selected={insurance==dure}>
                          {insurance} Mois
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
    {errors.dure&& errors.dure}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Type d'assurance</Form.Label>
                  <Form.Select onChange={handleAssurance} name="assurance">
                    <option value="">Sélectionnez le type d'assurance</option>
                    {insuranceTypes.map((insurance, index) => {
                      return (
                        <option value={insurance} key={index} selected={insurance==selectedInsurance}>
                          {insurance}
                        </option>
                      );
                    })}
                    <option value="Autre"  selected={selectedInsurance=="Autre"}>
                          Autre
                        </option>
                  </Form.Select>
                  {showInputAssurance && (
                    <Form.Control
                      type="text"
                      name="AutreAssurance"
                      value={otherInsurance}
                      placeholder="Entrez un type d'assurance"
                      className="mt-3"
                      onChange={(e) => {
                        setOtherInsurance(e.target.value);
                      }}
                    />
                  )}
                   <Form.Control.Feedback type="invalid">
    {errors.typeAssurance&& errors.typeAssurance}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Les bénéficiaires
                  </Form.Label>
                  <Form.Select onChange={handleBeneficiary} name="beneficie">
                    <option value="">Sélectionnez les bénéficiaires</option>
                    {beneficiaries.map((b, index) => {
                      return (
                        <option value={b.value} key={index} selected={b.value==selectedBeneficiary}>
                          {b.label}
                        </option>
                      );
                    })}
                      <option value="Autre"  selected={selectedBeneficiary=="Autre"}>
                          Autre
                        </option>
                  </Form.Select>
                  {showInputBeneficiary && (
                    <Form.Control
                      type="text"
                      name="AutreBeneficiary"
                      value={otherBeneficiary}
                      placeholder="Entrez les bénéficiares"
                      className="mt-3"
                      onChange={(e) => {
                        setOtherBeneficary(e.target.value);
                      }}
                    />
                  )}
                   <Form.Control.Feedback type="invalid">
    {errors.beneficiary && errors.beneficiary}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Montant assuré</Form.Label>
                  <Form.Control
                    type="text"
                    name="montant"
                    value={montant}
                    placeholder="Entrez le montant assuré"
                    onChange={(e) => {
                      setMontant(e.target.value);
                    }}
                    required
                  />
                   <Form.Control.Feedback type="invalid">
    {errors.montant && errors.montant}
  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="student-assurance-container3 mt-4 ">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Documents à télécharger</h3>
          <Row className="mt-4">
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">Passeport</h3>
              <div className="attestation-save-container ">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={passport}
                 setExistingFile={setPassport}
                 
                  nameF="passport_copy"
                />

                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
              <Form.Control.Feedback type="invalid">
    {errors.passport && errors.passport}
  </Form.Control.Feedback>
            </Col>

            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                Justificatif de domicile
              </h3>

              <div className="attestation-save-container ">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={justificatif}
                 setExistingFile={setJustificatif}
                 
                  nameF="preliminary_acceptance"
                />

                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
              <Form.Control.Feedback type="invalid">
    {errors.justificatif && errors.justificatif }
  </Form.Control.Feedback>

            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="student-assurance-container4 mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Mode de paiement</h3>
          <Form className="mt-4">
            <Row className=" mt-3">
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Form.Group>
            <Form.Label>Sélectionnez le mode de paiement</Form.Label>
            <Row className="mt-3">
              {options.map((option, index) => (
                <Col key={index} xl={3} lg={3} md={3} sm={12} xs={12}>
                  <Form.Check
                    type="radio"
                    label={option.label}
                    name="modePaiment"
                    value={option.value}
                    className="label-student-attestation"
                    onChange={handleModeChange} // Appeler la fonction lors du changement
                    checked={type === option.value} // Coche si la valeur correspond
                    required
                  />
                </Col>
              ))}
            </Row>
            <Form.Control.Feedback type="invalid">
    {errors.modePaiement && errors.modePaiement}
  </Form.Control.Feedback>
          </Form.Group>
              </Col>
            </Row>
            {type === "carte" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                      Carte de crédit
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Numéro de carte</Form.Label>
                      <Form.Control
                        type="text"
                        name="numCarte"
                        value={numcarte}
                        placeholder="1234 5678 5677 5678"
                        onChange={(e) => setNumCarte(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="label-student-attestation">
                        Date d'expiration
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dateExp"
                        value={dateExp}
                        placeholder="MM/YY"
                        onChange={(e) => setDateExp(e.target.value)}
                      />

                    </Form.Group>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>CVV</Form.Label>
                      <Form.Control
                        type="text"
                        name="cvv"
                        placeholder="123"
                        value={cvv}
                        onChange={(e) => setCvv(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {type === "virement" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                      Virement
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Nom de la banque</Form.Label>
                      <Form.Control
                        type="text"
                        name="nomBanc"
                        value={infoVirement.nomBanc}
                        placeholder="Nom de la banque"
                        onChange={(e) => setInforVirement({...infoVirement,nomBanc:e.target.value})}
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label className="label-student-attestation">
                        IBAN
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="IBAN"
                        placeholder="IBAN"
                        value={infoVirement.IBAN}
                        onChange={(e) => setInforVirement({...infoVirement,IBAN:e.target.value})}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className=" mt-3">
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>BIC/SWIFT</Form.Label>
                      <Form.Control
                        type="text"
                        name="BIC"
                        value={infoVirement.BIC}
                        placeholder="BIC/SWIFT"
                        onChange={(e) => setInforVirement({...infoVirement,BIC:e.target.value})}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Form.Group>
                      <Form.Label>Code bancaire</Form.Label>
                      <Form.Control
                        type="text"
                        name="codeBancaire"
                        value={infoVirement.codeBanc}
                        placeholder="Code bancaire"
                        onChange={(e) => setInforVirement({...infoVirement,codeBanc:e.target.value})}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {type === "preuve" && (
              <>
                {" "}
                <Row className=" mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h3 className="student-attestation-save-title">
                     Preuve de paiement
                    </h3>
                  </Col>
                </Row>
                <Row className=" mt-2">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="attestation-save-container ">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={preuvePaiement}
                 setExistingFile={setPreuvePaiement}
                
                />

                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
                  </Col>

                  
                </Row>
                
              </>
            )}
          </Form>
        </Col>
      </Row>

      <Row
        className="mt-4 mb-3 d-flex justify-content-end submit-button-container"
        style={{ height: "53px" }}
      >
        <button
          onClick={handleAssuranceClick}
          type="submit"
          className="button-assurance"
        >
          {
                     !loading ? (
                       <>
                         <span>{text}</span>
                         <svg
                           viewBox="0 0 29 30"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                           className="profil-fleche"
                         >
                           <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
                           <path
                             d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
                             stroke="#015192"
                             strokeWidth="1.375"
                             strokeMiterlimit="10"
                             strokeLinecap="round"
                             strokeLinejoin="round"
                           />
                         </svg>
                       </>
                     ) : (
                       <Spinner animation="border" size="md" />
                     )
                   }
        </button>
      </Row>
    </div>
  );
}
