import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

function FilterComponent({
  // Email search
  emailQuery,
  setEmailQuery,

  // Name/phone search
  searchQuery,
  setSearchQuery,

  // Country dropdown
  countryFilter,
  setCountryFilter,

  // Account status filter (optional)
  statusFilter,
  setStatusFilter,
  showStatusFilter = false,

  // Reset all filters
  handleReset,

  // List of countries for dropdown
  countries = [],
}) {
  return (
    <Row className="my-3">
      {/* Email Search */}
      <Col xs={12} md={3} className="mb-2">
        <Form.Control
          type="text"
          placeholder="Rechercher par email"
          value={emailQuery}
          onChange={(e) => setEmailQuery(e.target.value)}
        />
      </Col>

      {/* Name/Phone Search */}
      <Col xs={12} md={3} className="mb-2">
        <Form.Control
          type="text"
          placeholder="Rechercher par nom ou téléphone..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Col>

      {/* Country Filter */}
      <Col xs={12} md={3} className="mb-2">
        <Form.Select
          value={countryFilter}
          onChange={(e) => setCountryFilter(e.target.value)}
        >
          <option value="">Tous les pays</option>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </Form.Select>
      </Col>

      {/* Status Filter (only if showStatusFilter = true) */}
      {showStatusFilter && (
        <Col xs={12} md={3} className="mb-2">
          <Form.Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">Tous les statuts</option>
            <option value="active">Activé</option>
            <option value="inactive">Désactivé</option>
          </Form.Select>
        </Col>
      )}

      {/* If NOT showing status, we fill remaining space to keep layout consistent */}
      {!showStatusFilter && <Col xs={12} md={3}></Col>}

      {/* Reset Button (always at full width on small devices) */}
      <Col xs={12} md={3} className="mb-2 mt-2">
        <Button variant="secondary" className="w-100" onClick={handleReset}>
          Réinitialiser
        </Button>
      </Col>
    </Row>
  );
}

export default FilterComponent;
