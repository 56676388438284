import React, { useState } from 'react'
import {  Row, Col, Accordion } from "react-bootstrap"
import "../Student.css"
import profileImg from "../../../images/profileStu.png"
import LittleStudent from '../../../images/littleStudent'
import LittleUni from '../../../images/littleUni'
import LocationSVG from '../../../images/Location'
import DeleteSVG from '../../../images/DeleteSVG'
import TelephoneSVG from '../../../images/TelephoneSVG'
import MailSvg from '../../../images/MailSvg'
import Gender from '../../../images/Gender'
import CakeSvg from '../../../images/CakeSvg'
import EyeSvg from '../../../images/EyeSvg'
import task from '../../../images/tasks.png'
import folder from "../../../images/folders.png"
import download from "../../../images/download.png"
import eye from "../../../images/eye.png"
import PaymentHistoric from '../../../Components/PaymentHistoric/PaymentHistoric'

import { useNavigate } from 'react-router'
import ServiceAbonne from './ServiceAbonne'
import api from '../../api/axiosInstance'
import { useParams } from 'react-router'
import { useEffect } from 'react'
import sanitizeImageUrl from '../../../StudentModule/utils/anitizeImageUrl'
import FileToDownload from './FileToDownload'

function DétailEtudiant() {


    const [data, setData] = useState({
        nom: "",
        prenom: "",
        email: "",
        phone: "",
        genre: "",
        universite: "",
        annee: "",
        picture: "",
        pays: "",
        ville: "",
        dateN: "",
        statut: "",


    })
    const navigate = useNavigate();
    const id = useParams().id
    const [servicesData, setServicesData] = useState({
        insurance: {},
        housing: {},
        insurances: {},
        ticketing: {},
    });
     const handleBackClick = () => {
        navigate(-1); // Retourne à la page précédente
    };
    useEffect(() => {
        const fetchStudent = async () => {
            try {
                const response = await api.get(`/admin/students/${id}/`);
                const student = response.data.student;
                const services = response.data.services;

                console.log('student', student);
                console.log('services', data);
                setServicesData(prev => ({
                    ...prev,
                    insurance: services.insurances?.[0] || {},
                    housing: services.housing?.[0] || {},
                    insurances: services.irrevocable_payments?.[0] || {},
                    ticketing: services.ticketing?.[0] || {},
                }));

                // Check if irrevocable_payments exists and access the first item in the array
              
                setData({
                    nom: student.last_name || "",
                    prenom: student.first_name || "",
                    email: student.email || "",
                    phone: (student.country_code ? student.country_code + " " : "") + (student.phone_number || ""),
                    pays: student.country || "",
                    ville: student.town || "",
                    genre: student.genre || "",
                    universite: student.university || "",
                    annee: student.anneeScolaire || "",
                    dateN: student.date_of_birth || "",
                    picture: student.picture,
                    statut: student.is_active,

                });
            

       

            } catch (err) {
                console.log(err);
            }
        };
        fetchStudent();
    }, [id]); // Ensure `id` is included in the dependency array

    const documentConfigs = [
        { title: "Accord préliminaire", key: "preliminary_acceptance" },
        { title: "Preuve de paiement", key: "payment_proof" },
        { title: "Copie de passport", key: "passport_copy" },
        { title: "Document additionnel", key: "additional_pdf" },
    ];

    const renderDocuments = () =>
        documentConfigs.map((config, index) => {
          const documentUrls = ["insurance", "housing", "insurances", "ticketing"]
            .map((serviceKey) => servicesData[serviceKey]?.files?.[config.key])
            .filter(Boolean); // Supprime les valeurs nulles ou undefined
      
          return documentUrls.length > 0 ? (
            <Col xl={4} sm={4} className={`ColDoc ${index === 3 ? "mt-1" : ""}`} key={index}>
              <Accordion className="reasons-accordion p-0 m-0">
                <Accordion.Item eventKey="0" className="DocInp3">
                  <Accordion.Header className="DocInp3">
                    <div className="DocInp2 p-0 m-0">
                      <span className="foldInp">
                        <img src={folder} alt="Folder" />
                      </span>
                      <span className="PDown">
                        <p>{config.title}</p>
                      </span>
                      <span className="downl">
                        <div>
                          <img src={download} alt="Download" />
                        </div>
                        <div className="DOWNIMG">
                          <img src={eye} alt="Preview" />
                        </div>
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {documentUrls.map((url, i) => (
                      <FileToDownload
                        key={i}
                        titre={config.title}
                        fichier={sanitizeImageUrl(url)}
                        value={sanitizeImageUrl(url)}
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          ) : null;
        });
      

    return (
        <div>
            <Row>
                <Col>
                    <span className='RouteStu'>
                        <p className='routeStu'>Étudiant</p>
                        <p className='RouteStuRoute'>/ Détail Étudiant</p>
                    </span>
                </Col>
                <Col>
                    <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
                </Col>
            </Row>
            <Row className='ContentDetailStu'>
                <Row className='row1etu'>
                    <Col sm={2} className='proStu'>
                        <div className='bgImgStu'>
                            <img src={data.picture ? sanitizeImageUrl(data.picture) : profileImg} className="img-fluid img-rounded img-prof-stu" />
                        </div>
                    </Col>
                    <Col sm={4} className='secondProStu'>
                        <span>
                            <p className='titleStu'>{data.nom} {data.prenom}</p>
                            <span className='sousStu'>

                                <LittleStudent />

                                <p className="schoolY"> Année scolaire: {data.annee}</p>
                            </span>
                            <span className='sousStu'>

                                <LittleUni />

                                <p className="schoolY" style={{ marginLeft: "5px" }}>{data.universite}</p>
                            </span>
                            <span style={{ display: "flex" }}>
                                <button className='locationStu'>
                                    <span className='locationSvg'>
                                        <LocationSVG />
                                    </span>

                                    {data.pays}

                                </button>
                                <button className='deleteBtnStu'>
                                    <span className='locationSvg'>
                                        <DeleteSVG />
                                    </span>
                                    Supprimer l'utilisateur
                                </button>
                            </span>
                        </span>
                    </Col>
                    <Col sm={2} className='persCol'>
                        <span>
                            <span className='persSpan'>
                                <p className='personal'>INFORMATIONS </p>
                                <span className='svgPers'>
                                    <span className='spanSvg'>
                                        <TelephoneSVG />
                                    </span>
                                    <p className='personalsous'>{data.phone}</p>
                                </span>
                                <span className='svgPers'>
                                    <span className='spanSvg'>
                                        <MailSvg />
                                    </span>

                                    <p className='personalsous'>{data.email}</p>
                                </span>
                                <span className='svgPers'>
                                    <span className='spanSvg'>
                                        <Gender />
                                    </span>
                                    <p className='personalsous'>{data.genre == "male" ? "Homme" : "Femme"}</p>
                                </span>
                                <span className='svgPers'>
                                    <span className='spanSvg'>
                                        <CakeSvg />
                                    </span>
                                    <p className='personalsous'>{data.dateN}</p>
                                </span>
                            </span>
                        </span>
                    </Col>
                    <Col sm={3} className='fourthCol'>
                        <div className='fourthPers' >
                            <div className='div1Four'>
                                <p className='textPers'>
                                    Votre Plan de Forfait
                                </p>
                                <button className='btnViewPers'>
                                    <span className='svgEye'>
                                        <EyeSvg />
                                    </span>
                                    Voir
                                </button>
                            </div>
                            <div>
                                <img src={task} className='taskImg' />
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col className='UrDoc'>
                        <Row>
                            <p className='Doc'>
                                Vos Documents
                            </p>
                        </Row>

                        {/* DOCUMENT CARD */}
                        <Row>
                            {renderDocuments()}
                        </Row>
                        <Row className='mt-3'>
                            <p className='Doc'>
                                Les Services Abonnés
                            </p>
                        </Row>
                        <Row>
                            <ServiceAbonne titre="Attestation de virement" />
                            <ServiceAbonne titre="Assurance complète" />
                            <ServiceAbonne titre="Recherche de logement" />
                            <ServiceAbonne titre="Service de billetrie" />

                        </Row>

                        {/* <Row>
                            {data.irrevocablePayment.map((payment, index) => (
                                <ServiceAbonne key={`payment-${index}`} titre="Attestation de virement" />
                            ))}
                            {data.insurances.map((insurance, index) => (
                                <ServiceAbonne key={`insurance-${index}`} titre="Assurance complète" />
                            ))}
                            {data.housing.map((house, index) => (
                                <ServiceAbonne key={`house-${index}`} titre="Recherche de logement" />
                            ))}
                            {data.ticketing.map((ticket, index) => (
                                <ServiceAbonne key={`ticket-${index}`} titre="Service de billetrie" />
                            ))}
                        </Row> */}

                    </Col>
                </Row>

                <Row className='RowTrans'>
                    <Row>
                        <Col>
                            <p className='transAEtu'>Transactions</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='ColTrans'>
                            {/* <Table responsive>
                        <table className='tablTrans'>
                            <tr>
                                <th className='thTrans'>Numéro</th>
                                <th className='thTrans'>Nom du Service</th>
                                <th className='thTrans'>Date de Paiement</th>
                                <th className='thTrans'>Statut du Paiement</th>
                                <th className='thTrans'>Montant</th>
                                <th className='thTrans'>Détails</th>
                            </tr>

                        
                            {
                                paiements.map((val,index)=>{
                                    return(
                                        <tr className='trTrans'>
                                            <td className='tdTrans'>{val.numero} </td>
                                            <td className='tdTrans'>{val.nomDuService} </td>
                                            <td className='tdTrans'>{val.dateDePaiement} </td>
                                            <td className='tdTrans'>{val.statutDuPaiement} </td>
                                            <td className='tdTrans'>{val.montant} </td>
                                            <td className='tdTrans'> ... </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </Table> */}
                            <PaymentHistoric />
                        </Col>
                    </Row>
                </Row>

            </Row>

        </div>
    )
}

export default DétailEtudiant