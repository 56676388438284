import { useEffect } from "react";
import rightArrowIcon from "../images/AVI/Path (1).png"
import leftArrowIcon from "../images/AVI/Path.png"

 
export default function Pagination({itemsPages, setItemsPerPage, setCurrentPage, currentPage,totalItems}){
    
    const pages = Object.keys(itemsPages)

    const showPage = page => {
        pages.includes(String(page)) && setCurrentPage(page)
    }

    useEffect(()=>{
        console.log(currentPage);
    },[currentPage])

    return(
        <div className="pagination">
            <div className="pageItemsCount">
                <span>Afficher</span>
                <select name="" id="" className="pageItemsCountSelect" onChange={e=>setItemsPerPage(parseInt(e.target.value))}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                </select>
                <span>sur {totalItems}</span>
            </div>

            <div className="otherPages">
                <div onClick={()=>showPage(currentPage - 1)} className={`${(currentPage - 1) == 0 ? '' : 'otherPage'} paginationIcon`}>
                    <img src={leftArrowIcon} alt="go back" />
                </div>
                {
                    pages.map(page => {
                        return(
                            <div key={page} onClick={()=>showPage(page)} className={`otherPage ${currentPage == page && 'otherPageActive'}`}>{page}</div>
                
                        )
                    })
                }
             
                <div onClick={()=> showPage(currentPage + 1)} className = {`${!pages.includes(String(currentPage + 1)) ? '' : 'otherPage'} paginationIcon`}>
                    <img src={rightArrowIcon} alt="go front" />
                </div>
            </div>
        </div>
    )
}