import React, { useState } from "react";
import "./RechercheLogement.css";
import FileToDownload from "../FileToDownload";
import countries from "../../../Layout/COUNTRY.json";

import CustomCountrySelect from "../../Layouts/CountrySelect";
import Button from "../../../Components/Button";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router";
import api from "../../api/axiosInstance";
import { success, error } from "../../utils/alerts";
import extractError from "../../utils/extractError";
import { useEffect } from "react";
import InputLabel from "../../../Components/InputLabel";
import SelectLabel from "../../../Components/SelectLabel";
import { Form, Spinner } from "react-bootstrap";
import sanitizeImageUrl from "../../utils/anitizeImageUrl";
const ErrorContext = React.createContext();

const RechercheLogement = () => {
  const navigate = useNavigate();
  const typeHebergement = [
    "Appartement",
    "Maison",
    "Studio",
    "Colocation",
    "Résidence étudiant",
    "Hôtel",
  ];

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // information stocke dans le localstorage
  const getLocalStorageItem = (key, defaultValue = "") => localStorage.getItem(key) || defaultValue;

  const [data, setData] = useState({
    firstname: getLocalStorageItem("firstname"),
    lastname: getLocalStorageItem("lastname"),
    phone: getLocalStorageItem("phone"),
    birthPlace: getLocalStorageItem("birthPlace"),
    birthDate: getLocalStorageItem("birthDate"),
    email: getLocalStorageItem("email"),
    nationality: getLocalStorageItem("nationality"),
    codeCountry: getLocalStorageItem("codeCountry"),
    hebergementType: "",
    preferedPlace: "",
    movingDate: "",
    sejourDuration: "",
    funds: "",
    specialNeeds: "",
    sejourObject: "",
    workPlace: "",
    occupantCount: "",

    passport: null,
    preuveAcceptance: null,
  });


  
  
  function renameFile(file, newName) {
    return new File([file], newName, { type: file.type });
  }
  
 



  function updatePassport(value) {
    setData({ ...data, passport: value })
  }
  function updateAcceptance(value) {
    setData({ ...data, preuveAcceptance: value })
  }
  const updateField = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let currentErrors = {};
    console.log("Soumission du formulaire en cours...");

    const validNomPattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{9,15}$/;
    const datePattern = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
    const numberPattern = /^[0-9-]+$/;
    const sejourObjectPattern = /^.{1,64}$/;
    


    if (!data.lastname.trim()) {
      currentErrors.lastname =
        "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validNomPattern.test(data.lastname)) {
      currentErrors.lastname =
        "Le nom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.lastname;
    }

    if (!data.firstname.trim()) {
      currentErrors.firstname =
        "Veuillez entrer votre prénom (pas d'espaces vides)";
    } else if (!validPrenomPattern.test(data.firstname)) {
      currentErrors.firstname =
        "Le prénom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.firstname;
    }

    if (!data.birthPlace.trim()) {
      currentErrors.birthPlace = "Veuillez entrer votre lieu de naissance.";
    } else if (!validVillePattern.test(data.birthPlace)) {
      currentErrors.birthPlace =
        "Le lieu de naissance ne doit contenir que des lettres, des espaces, des apostrophes ou des tirets.";
    } else {
      delete currentErrors.birthPlace;
    }

    if (!data.email.trim()) {
      currentErrors.email = "Veuillez entrer une adresse e-mail valide";
    } else if (!emailPattern.test(data.email)) {
      currentErrors.email = "Adresse e-mail invalide";
    } else {
      delete currentErrors.email;
    }

    if (!data.phone.trim()) {
      currentErrors.phone =
        "Veuillez entrer un numéro de téléphone valide";
    } else if (!phonePattern.test(data.phone)) {
      currentErrors.phone =
        "Le numéro de téléphone doit contenir uniquement des chiffres";
    } else {
      delete currentErrors.phone;
    }

    if (!data.nationality.trim()) {
      currentErrors.nationality = "Veuillez entrer un pays valide";
    } else if (!countryPattern.test(data.nationality)) {
      currentErrors.nationality = "Le nom du pays est invalide";
    } else {
      delete currentErrors.nationality;
    }

    // 
    const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD

    if (!String(data.birthDate).trim()) {
      currentErrors.birthDate = "Veuillez entrer une date de départ valide.";
    } else if (!datePattern.test(data.birthDate)) {
      currentErrors.birthDate =
        "La date de départ doit être au format AAAA-MM-JJ.";
    } else if (data.birthDate >= today) {
      currentErrors.birthDate =
        "La date de déplacement doit être dans le futur.";
    }
    else {
      delete currentErrors.birthDate;
    }
    

    // const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD
    const oneYearAgo = new Date(new Date().getTime() - 31536000000).toISOString().split("T")[0]; // Format YYYY-MM-DD
    if (data.movingDate <= today) {
      currentErrors.movingDate =
        "La date de déplacement doit être dans le futur.";
    } else if (data.movingDate < oneYearAgo) {
      currentErrors.movingDate =
        "La date de déplacement doit être dans le futur.";
    }

    else if (!data.movingDate.trim()) {
      currentErrors.movingDate = "Veuillez entrer une date valide";
    } else if (!datePattern.test(data.movingDate)) {
      currentErrors.movingDate = "La date est invalide";
    } else {
      delete currentErrors.movingDate;
    }

    if (!data.hebergementType.trim()) {
      currentErrors.hebergementType =
        "Sélectionner un type d'hébergement valide";
    } else {
      delete currentErrors.hebergementType;
    }
    if (!data.nationality.trim()) {
      currentErrors.nationality = "la nationalité ne doit pas être vide"
    }
    else {
      delete currentErrors.nationality;
    }

    if (!data.preferedPlace.trim()) {
      currentErrors.preferedPlace = "Vous devez entrer un lieu de préférence";
    } else if (!validVillePattern.test(data.preferedPlace)) {
      currentErrors.preferedPlace = "Vous devez entrer un lieu valide";
    } else {
      delete currentErrors.preferedPlace;
    }

    if (!String(data.sejourDuration).trim()) {
      currentErrors.sejourDuration = "Vous devez entrer une durée";
    } else {
      const sejourDuration = Number(data.sejourDuration.toString().trim());

      if (isNaN(sejourDuration) || sejourDuration < 1 || sejourDuration > 64) {
        currentErrors.sejourDuration =
          "La durée du séjour doit être comprise entre 1 et 64 mois.";
      } else {
        delete currentErrors.sejourDuration;
      }
    }

    if (!data.funds || data.funds.toString().trim() === "") {
      currentErrors.funds = "Vous devez entrer votre budget";
    } else {
      const fundsValue = Number(data.funds.toString().trim());

      if (isNaN(fundsValue) || fundsValue < 1) {
        currentErrors.funds = "Votre budget doit être valide";
      } else {
        delete currentErrors.funds;
      }
    }

    if (!data.specialNeeds.trim()) {
      currentErrors.specialNeeds = "Entrer un besoin spécial";
    } else {
      delete currentErrors.specialNeeds;
    }

    if (!String(data.sejourObject.trim())) {
      currentErrors.sejourObject = "Vous devez entrer un objet de séjour";
    } else if (!sejourObjectPattern.test(data.sejourObject)) {
      currentErrors.sejourObject = "Votre objet est trop long";
    } else {
      delete currentErrors.sejourObject;
    }

    if (!data.workPlace.trim()) {
      currentErrors.workPlace = "Vous devez remplir ce champ";
    } else {
      delete currentErrors.workPlace;
    }

    if (!String(data.occupantCount).trim()) {
      currentErrors.occupantCount = "Vous devez entrer le nombre d'occupant";
    } else if (data.occupantCount >  10 || data.occupantCount < 1) {
      currentErrors.occupantCount = "Vous devez entrer un nombre valide etre (1 et 10)";
    } else if (!numberPattern.test(data.occupantCount)) {
      currentErrors.occupantCount = "Vous devez entrer un nombre valide";
    }

    else {
      delete currentErrors.occupantCount;
    }

    if (!data.passport || data.passport < 1) {
      currentErrors.passport = "Vous devez fournir un fichier pour la preuve de copie du passeport.";
    } else {
      delete currentErrors.passport;
    }

    if (!data.preuveAcceptance || data.preuveAcceptance < 1) {
      currentErrors.preuveAcceptance = "Vous devez fournir un fichier pour la preuve d'accord préliminaire.";
    } else {
      delete currentErrors.preuveAcceptance;
    }




    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("first_name", data.firstname);
      formData.append("last_name", data.lastname);
      formData.append("phone_number", data.phone);
      formData.append("place_of_birth", data.birthPlace);
      formData.append("date_of_birth", data.birthDate);
      formData.append("email", data.email);
      formData.append("nationality", data.nationality || "");
      formData.append("country_code", data.codeCountry);
      formData.append("accommodation_type", data.hebergementType);
      formData.append("preferred_location", data.preferedPlace);
      formData.append("move_in_date", data.movingDate);
      formData.append("stay_duration", data.sejourDuration);
      formData.append("budget", data.funds);
      formData.append("special_needs", data.specialNeeds);
      formData.append("purpose_of_stay", data.sejourObject);
      formData.append("number_of_occupants", data.occupantCount);
      formData.append("university_or_workplace", data.workPlace);

      // preliminary_acceptance

      if (data.preuveAcceptance && data.preuveAcceptance.name) {
        const newName = data.preuveAcceptance.name.slice(0, 95) + data.preuveAcceptance.name.slice(-5);
        const renamedFile = renameFile(data.preuveAcceptance, newName);
        formData.append("files.preliminary_acceptance", renamedFile);
      }

      // preliminary_acceptance

      if (data.passport && data.passport.name) {
        const newName = data.passport.name.slice(0, 95) + data.passport.name.slice(-5);
        const renamedFile = renameFile(data.passport, newName);
        formData.append("files.passport_copy", renamedFile);
      }
      // api create or update 
      const response = await api.post("/student/housing/", formData)

      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Le service de recherche de logement a été mis à jour avec succès.";
        success(msg);
        setLoading(false)
      }
    } catch (err) {
      console.error("Une erreur est survenue:", err);
      if (err.response && err.response.data?.data) {
        const errorObject = err.response.data.data;
        const errorMessages = extractError(errorObject);
        errorMessages.forEach((message) => error(message));
        setLoading(false)
      } else {
        error("Erreur inattendue lors de la soumission.");
        setLoading(false)
        console.log(err.message)
      }
    } finally {
      setLoading(false)
    }
  };
  //get les données


  useEffect(() => {
    const fetchLogement = async () => {
      try {
        const response = await api.get("/student/housing/");
        const logementData = response.data;


        setData({
          id: logementData.id || null,
          firstname: logementData.first_name || "",
          lastname: logementData.last_name || "",
          codeCountry: logementData.country_code || "+33",
          phone: logementData.phone_number || "",
          birthPlace: logementData.place_of_birth || "",
          birthDate: logementData.date_of_birth || "",
          email: logementData.email || "",
          nationality: logementData.nationality || "",
          hebergementType: logementData.accommodation_type || "",
          preferedPlace: logementData.preferred_location || "",
          movingDate: logementData.move_in_date || "",
          sejourDuration: logementData.stay_duration || "",
          funds: logementData.budget || "",
          specialNeeds: logementData.special_needs || "",
          sejourObject: logementData.purpose_of_stay || "",
          workPlace: logementData.university_or_workplace || "",
          occupantCount: logementData.number_of_occupants || "",
          passport: sanitizeImageUrl(logementData.files.passport_copy) || null,
          preuveAcceptance: sanitizeImageUrl(logementData.files.preliminary_acceptance) || null,
    
        });
      } catch (err) {
        console.error(
          "Erreur lors du chargement des données de logement:",
          err
        );
      }
    };

    fetchLogement();
  }, []);

  const validNomPattern = /^[A-Za-z\- ]+$/;


  return (
    <main className="rechercheLogement animParent">
      <div className="backSectionButton">
        <Button
          value={
            <>
              <IoArrowBackCircle />
              <span>Retour</span>
            </>
          }
          onClick={() => navigate("/etudiant/prestations-de-service")}
        />
      </div>

      <ErrorContext.Provider value={errors}>
        <PersonnalInfos
          title={"Recherche de logement"}
          data={data}
          setData={setData}
          updateField={updateField}
        />
      </ErrorContext.Provider>

      <section className="preferenceHebergementSection">
        <h1 className="preferenceHebergementTitreSection">
          Préférences d'hébergement
        </h1>
        <div className="preferenceHebergementForm">

          <SelectLabel
            label={"Type d'hébergement"}
            name={"hebergementType"}
            isInvalid={!!errors.hebergementType}
            errorMessage={errors?.hebergementType}

            onChange={(e) => updateField(e)}
          >
            <option selected value="" disabled>Sélectionner un type d'hébergement</option>

            {typeHebergement.map((type, index) => (
              <option
                key={index}
                value={type}
                selected={
                  type.toLowerCase() === data.hebergementType.toLowerCase()
                }
              >
                {type}
              </option>
            ))}

          </SelectLabel>
          <InputLabel
            label={"Lieu préféré"}
            name="preferedPlace"
            placeholder={'Entrez votre lieu préféré'}
            value={data.preferedPlace}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.preferedPlace}

          />
          <InputLabel
            label={"Date de déménagement"}
            name="movingDate"
            placeholder={''}
            value={data.movingDate}
            type="date"
            onChange={(e) => updateField(e)}
            errorMessage={errors?.movingDate}

          />

          <InputLabel
            label={"Durée du séjour (mois)"}
            name="sejourDuration"
            placeholder={'1'}
            value={data.sejourDuration}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.sejourDuration}

          />

          <InputLabel
            label={"Budget (€)"}
            name="funds"
            placeholder={'20'}
            value={data.funds}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.funds}

          />

          <InputLabel
            label={"Besoins speciaux"}
            name="specialNeeds"
            value={data.specialNeeds}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.specialNeeds}

          />

        </div>
      </section>

      <section className="informationComplementaireSection">
        <h1 className="student-attestation-title">
          Informations Complémentaires
        </h1>
        <div className="informationComplementaireForm">

          <InputLabel
            label={"Objet du séjour"}
            name="sejourObject"
            placeholder={"Entrez l'objet du séjour"}
            value={data.sejourObject}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.sejourObject}

          />

          <InputLabel
            label={"Université ou Lieu de travail"}
            name="workPlace"
            placeholder={"Hardward"}
            value={data.workPlace}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.workPlace}

          />

          <InputLabel
            label={"Nombre d'occupants"}
            name="occupantCount"
            placeholder={"Entrez le nommbre d'occupant"}
            value={data.occupantCount}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.occupantCount}

          />

        </div>
      </section>

      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Preuve d'identité"}
            acceptanceText={"Passeport, ID Carte"}
            fichier={data.passport}
            fieldName="files[passport_copy]"
            setFichier={updatePassport}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.passport ? true : false}
            errorMessage={errors?.passport}

          />
          {/* <Form.Control.Feedback type="invalid">
              {errors?.passport && errors.passport}
            </Form.Control.Feedback> */}
          <FileToDownload
            titre={"Preuve d'admission / d'emploi"}
            acceptanceText={"Lettre d'acceptance, Contrat de travail"}
            fichier={data.preuveAcceptance}
            fieldName="files[preliminary_acceptance]"

            setFichier={updateAcceptance}
            onChange={(e) => updateField(e)}
            isInvalid={errors?.preuveAcceptance ? true : false}
            errorMessage={errors?.preuveAcceptance}

          />

        </div>
      </section>
      <div className="submitSectionButton">
        <button type="submit" onClick={handleSubmit} className="buttonComponent">
          {
            !loading ? (
              <>
                Soumettre
                <svg
                  viewBox="0 0 29 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="profil-fleche"
                >
                  <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
                  <path
                    d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
                    stroke="#015192"
                    strokeWidth="1.375"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </>
            ) : (
              <Spinner animation="border" size="md" />
            )
          }

        </button>
      </div>

    </main>
  );
};

export default RechercheLogement;
export function PersonnalInfos({ data, updateField, title, setData }) {
  const [errors, setErrors] = useState({});

  const updateCodeCountry = (code) => {
    setData((data) => ({
      ...data,
      codeCountry: code,
    }));
  };

  const validNomPattern = /^[A-Za-z\- ]+$/;
  const validNumberPattern = /^\d{7-30}$/;

  const validateField = (name, value) => {
    switch (name) {
      case "firstname":
        if (!value.trim()) {
          return "Veuillez entrer votre prénom (pas d'espaces vides)";
        } else if (!validNomPattern.test(value)) {
          return "Le prénom ne doit contenir que des lettres et des apostrophes.";
        }
        break;
      case "lastname":
        if (!value.trim()) {
          return "Veuillez entrer votre nom (pas d'espaces vides)";
        } else if (!validNomPattern.test(value)) {
          return "Le nom ne doit contenir que des lettres et des apostrophes.";
        }

        break;
      case "email":
        if (!value.trim()) {
          return "Veuillez entrer votre adresse email";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          return "Veuillez entrer une adresse email valide";
        }
        break;
      case "phone":
        if (!value.trim()) {
          return "Veuillez entrer votre numéro de téléphone";
        }
        break;
      case "birthPlace":
        if (!value.trim()) {
          return "Veuillez entrer votre lieux de naissance";
        }

        break;


      case "birthDate":
        if (!value.trim()) {
          return "Veuillez entrer votre date de naissance";
        } else if (new Date().toISOString().split("T")[0] <= data.birthDate) {
          return "La date de naissance ne peut pas être postérieure à aujourd'hui";
        }

        break;


    

      case "nationality":
        if (!value.trim()) {
          return "Veuillez entrer votre nationnailte";
        } else if (value.length < 3) {
          return "Veuillez entrer une nationnailte avec au moins 3 caractères";
        }
        break;


      default:
        return "";
    }
    return ""; // Pas d'erreur

  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  return (
    <section className="rechercheLogementSection ">
      <h1 className="student-attestation-title">{title}</h1>
      <div className="rechercheLogementForm">


        <InputLabel
          label={"Prénom"}
          name="firstname"
          placeholder={"Entrez votre prénom"}
          value={data.firstname}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.firstname}
          onBlur={handleBlur}

        />

        <InputLabel
          label={"Nom de famille"}
          name="lastname"
          placeholder={"Entrez votre nom de famille"}
          value={data.lastname}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.lastname}
          onBlur={handleBlur}

        />


        <div className="composedInput">
          <div className="inputLabel">
            <label htmlFor="codeCountry">Numéro de Téléphone</label>
            <div style={{ height: "45px" }}>
              <CustomCountrySelect
                name="codeCountry"
                codeCountry={updateCodeCountry}
                dialCode={data.codeCountry}
              />
            </div>
          </div>

          <InputLabel
            label={"Nom de famille"}
            name="phone"
            placeholder={"XX-XXX-XXX"}
            value={data.phone}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.phone}
            onBlur={handleBlur}

          />

        </div>

        <InputLabel
          label={"Lieu de naissance"}
          name="birthPlace"
          placeholder={"Entrez le lieu de naissance"}
          value={data.birthPlace}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.birthPlace}
          onBlur={handleBlur}

        />
        <InputLabel
          label={"Date de naissance"}
          name="birthDate"
          type="date"
          placeholder={"Entrez le lieu de naissance"}
          value={data.birthDate}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.birthDate}
          onBlur={handleBlur}

        />
        <InputLabel
          label={"Adresse email"}
          name="email"
          placeholder={"Entrez votre adresse email"}
          value={data.email}
          onChange={(e) => updateField(e)}
          isInvalid={!!errors.email}
          errorMessage={errors?.email}
          onBlur={handleBlur}

        />

        <SelectLabel
          label={"Nationalité"}
          name={"nationality"}
          onBlur={handleBlur}
          isInvalid={!!errors.nationality}
          errorMessage={errors?.nationality}

          onChange={(e) => setData({ ...data, nationality: e.target.value })}
        >
          <option selected value=""  >Sélectionner un nationnalite</option>

          {countries.map((country) => (
            <option
              key={`${country}`}
              value={country}
              selected={
                country ==
                data.nationality
              }
            >
              {country}
            </option>
          ))}
        

        </SelectLabel>

      </div>
    </section>
  );
}