import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "./Parent.css"
import LocationSVG from '../../images/Location'
import profile from "../../images/profParent.png"
import CakeSvg from '../../images/CakeSvg'
import LittleStudent from '../../images/littleStudent'
import Gender from '../../images/Gender'
import MailSvg from '../../images/MailSvg'
import Live from '../../images/Live.png'
import heure from '../../images/heure.png'
import telecharger from '../../images/telecharger.png'
import TelephoneSVG from '../../images/TelephoneSVG'
import { useNavigate, useParams } from 'react-router'
import api from '../api/axiosInstance'
import sanitizeImageUrl from '../../StudentModule/utils/anitizeImageUrl'
function Parent() {
      const id = useParams().id
  
  const navigate = useNavigate();
  const [parent, setParent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParent = async () => {
      try {
        const response = await api.get(`/admin/parents/${id}/`);
        setParent(response.data);
      } catch (err) {
        console.error("Error fetching parent details:", err);
        setError("Failed to fetch parent details");
      }
    };

    fetchParent();
  }, [id]);

  const handleBackClick = () => {
    navigate(-1); // Retourne à la page précédente
  };
  // Fonction pour formater la date
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("fr-FR", options).format(new Date(dateString));
  };
  if (error) {
    return <p>{error}</p>;
  }
  if (!parent) {
    return <p>Loading...</p>; // Afficher un état de chargement tant que les données ne sont pas récupérées
  }
  return (
    <div>
      <Row>
        <Col>
                <span className='RouteStu'>
                    <p className='routeStu'>Parents</p>
                    <p className='RouteStuRoute'>/ Parent Détails </p>
                </span>
        </Col>
      <Col>
      <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
    </Col> 
      </Row>
      <Row className='ParentRow'>
        <Row className='headerParent'>
          <Col sm={2} className='RickImg'>
            <span className='parentCercle'>
            <img src={parent.picture ? sanitizeImageUrl(parent.picture) : profile} className="img-fluid img-rounded img-prof-stu" />

            {/* <img src={parent.picture ? sanitizeImageUrl(parent.picture) : profile} className="imgProParent" /> */}

              {/* <img src={profile} className='imgProParent'/> */}
            </span>
          </Col>
          <Col sm={4}  className='secondParent'>
            <span >
              <p className='parentNom'>{parent.first_name} {parent.last_name}</p>
              <p className='sousNomParent'>Brother</p>
              <button className='locationStu'>
                <span className='locationSvg'>
                  <LocationSVG/>
                 </span>
                {parent.country}
              </button>
            </span>
          </Col>
          <Col   className='persPartWidth gap-4 px-2 '>
           <span>
            <p className='InfoTextPers'>PERSONAL INFORMATION</p>
            <Row>
            <Col  className='TelLeftCol '>
              <span  className='persParent'>
                <span>
                  <TelephoneSVG/>
                </span>
                <p className='textPersParent '>({parent.country_code}) {parent.phone_number}</p>
              </span>
              <span  className='persParent'>
                <span>
                  <MailSvg/>
                </span>
                <p className='textPersParent textPersParentEmail '>{parent.email}</p>
              </span>
            </Col>
            <Col className='TelLeftCol mx-5' >
              <span  className='persParent'>
                <span className='secondSvgPers'>
                  <Gender/>
                </span>
                <p className='textPersParent'>Male</p>
              </span>
              <span  className='persParent'>
                <span className='secondSvgPers'>
                  <CakeSvg/>
                </span>
                <p className="textPersParent px-2"> {formatDate(parent.date_of_birth)}</p>
                </span>
            </Col>
            </Row>
            
           </span>
          </Col>
        </Row>
        <Row className='Row'>
          <Col>
            <p className='RecentPar'>Activités Récente</p>
          </Col>
        </Row>
        <Row>
          <Col className='supportCol'>
            <div className='supportDiv'>
              <img src={Live} className='supportImg' />
              <div className='suppTextlive' >
                <p className='supportLive'>Assistance en direct</p>
                <p className='sousSupportLive'>Rick gourd has started the live support chat</p>
              </div>
            </div>
            <div className='rightLive' >
              <img src={heure} className='heureRight'/>
              <p className='rightsupport'>
              Aujourd'hui, 11:10 am
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='supportCol'>
            <div className='supportDiv1'>
              <img src={telecharger} className='supportImg' />
              <div className='suppTextlive' >
                <p className='supportLive'>Télécharger le PDF</p>
                <p className='sousSupportLive'>Rick Gourd a téléchargé le PDF - Ressources et documentation</p>
              </div>
            </div>
            <div className='rightLive' >
              <img src={heure} className='heureRight'/>
              <p className='rightsupport1'>
              Hier, 11:10 am
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default Parent
