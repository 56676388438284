import { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useParams,useNavigate } from "react-router-dom";
import api from "../../StudentModule/api/axiosInstance";
import bg from "../../images/dash.png";
import logo from "../../images/logo-colorful.png";
import rectangle from "../../images/Rectangle 18.png";
import laptop from "../../images/laptop-with-login-password-form-screen 1.png";
import './ModifierMotPasse.css';

export default function ModifierMotPasse() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { uid, token } = useParams(); // Extraire uid et token depuis l'URL

  const handleBackClick = () => {
    navigate(-1); // Retourne à la page précédente
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      setError("Veuillez remplir tous les champs.");
      return;
    }

    

    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      // Vérifiez la validité du mot de passe avant l'appel API
     
    
      // Appel API pour mettre à jour le mot de passe
      const response = await api.patch(
        `/password-reset-confirm/${uid}/${token}/`,
        { password: newPassword } // Corps de la requête
      );
    
      // Vérification du statut de la réponse
      if (response.status >= 200 && response.status < 300) {
        setSuccessMessage("Votre mot de passe a été modifié avec succès.");
        setError(""); // Effacer les erreurs précédentes
        navigate("/"); // Remplacez "/login" par le chemin correct pour votre application

      } else {
        // Traiter les erreurs de la réponse
        const errorData = response.data; // Récupérer les données de l'erreur
        alert(`Erreur : ${errorData.message || "Une erreur est survenue. Vérifiez le lien."}`);
      }
    } catch (err) {
      // Gestion des erreurs (par exemple, problème réseau ou statut >= 500)
      setError("Une erreur est survenue. Veuillez réessayer.");
      setSuccessMessage(""); // Effacer les messages de succès précédents
      console.error("Erreur lors de l'appel API :", err);
    }
    
  };

  return (
    <Container fluid>
      <Row>
        <Col
          className="RegisterStu ResetPassword"
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Row>
            <Col xl={6} lg={6} md={12} sm={12} xs={12} className="mt-1">
              <div className="logo-card">
                <img src={logo} className="img-fluid" alt="Logo" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="student-login-container-form p-xl-2 p-lg-0 p-3"
            >
              <Row className="mt-4 mt-xl-0 mt-lg-0">
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                  <button style={{ background: "none", border: "none", padding: "0", cursor: "pointer" }}>
                  <p
                    className="d-flex align-items-center"
                    onClick={() => navigate(-1)} 
                    style={{ marginBottom: "-50px" }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
                        stroke="#2E2E2E"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.2602 15.5297L9.74023 11.9997L13.2602 8.46973"
                        stroke="#2E2E2E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="dos-renitialisation">PRECEDENT</span>
                  </p>
                </button>

                  

                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <h1 className="title-Renitialiser mt-5">
                    Modifier le mot de passe
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p
                    className="student-login-p1 mt-1"
                    style={{ lineHeight: "20px", wordSpacing: "1px" }}
                  >
                    Entrez et confirmez votre nouveau mot de passe ci-dessous.
                  </p>
                </Col>
              </Row>

              <Row>
                <Form
                  className="student-login-form mt-4"
                  onSubmit={handleSubmit}
                >
                  {/* Nouveau mot de passe */}
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Form.Group controlId="newPassword">
                        <Form.Label>
                          Nouveau mot de passe <span className="star">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Entrez votre nouveau mot de passe"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Confirmation du mot de passe */}
                  <Row className="mt-3">
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Form.Group controlId="confirmPassword">
                        <Form.Label>
                          Confirmez le mot de passe <span className="star">*</span>
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Retapez votre mot de passe"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Messages d'erreur ou de succès */}
                  {error && (
                    <p className="error-message mt-3" style={{ color: "red" }}>
                      {error}
                    </p>
                  )}
                  {successMessage && (
                    <p className="success-message mt-3" style={{ color: "green" }}>
                      {successMessage}
                    </p>
                  )}

                  <div className="suivant-login-container mt-3">
                    <button
                      type="submit"
                      className="button-Register"
                      aria-label="Submit"
                    >
                      Soumettre
                      <svg
                        className="Register-fleche"
                        width="29"
                        height="30"
                        viewBox="0 0 31 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="0.5"
                          width="29"
                          height="29"
                          rx="14.5"
                          fill="white"
                        />
                        <path
                          d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
                          stroke="#015192"
                          strokeWidth="1.375"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </Form>
              </Row>
            </Col>
          </Row>
        </Col>

        {/* Section image */}
        <Col
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="student-login-image reset-login-image d-none d-xl-block d-lg-block"
        >
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className="student-login-para">
                Embarquez pour une aventure mondiale : <br />
                Explorez l'étranger
                <br />
                Étudiez les <br />
                opportunités <br />
                avec nous !
              </p>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="rectangle-wrap">
                <img src={rectangle} className="image-rectangle-pass" alt="" />
                <img src={laptop} className="image-laptop-student" alt="" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
