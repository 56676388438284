import { Container, Row, Col, Form, Button } from "react-bootstrap";

import "./Dashboard.css";
import books from "../../images/books.png";
import cloche from "../../images/cloche.png";
import pc from "../../images/pc.png";
import profil from "../../images/account.png"
import writing from "../../images/writing.png";
import { Link, useNavigate } from "react-router-dom";
import api from "../api/axiosInstance";
import { success, error } from "../utils/alerts";
import { useEffect, useState } from "react";
import sanitizeImageUrl from "../utils/anitizeImageUrl";

export default function Dashboard() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [serviceAbon, setServiceAbon] = useState(0);
  useEffect(() => {
    // Fetch the service abonn count when the component mounts
    api.get("/student/service-abon/")
      .then((response) => {
        // Assuming the API response has `services_abon`
        setServiceAbon(response.data.services_abon);
      })
      .catch((err) => {
        console.error("Error fetching service abonn:", err);
        error("Failed to load service abonn.");
      });
  }, []);

  useEffect(() => {
    api.get("/student/profile/")
      .then((response) => {
        const { first_name, last_name } = response.data;

        if (first_name && last_name) {
          setFirstName(first_name);
          setLastName(last_name);
          localStorage.setItem("firstname", first_name);
          localStorage.setItem("lastname", last_name);
        } else {
          const storedFirstName = localStorage.getItem("firstname");
          const storedLastName = localStorage.getItem("lastname");

          setFirstName(storedFirstName || "");
          setLastName(storedLastName || "");
        }
      })
      .catch(() => {
        const storedFirstName = localStorage.getItem("firstname");
        const storedLastName = localStorage.getItem("lastname");

        setFirstName(storedFirstName || "");
        setLastName(storedLastName || "");
      });
  }, []);

  const [profile, setProfile] = useState({});
   const [profileImage, setProfileImage] = useState('');
   const [completionPercentage, setCompletionPercentage] = useState(null);
   const [notificationCount, setNotificationCount] = useState(0);

   // Fetch notifications and set the count
   useEffect(() => {
     const fetchNotifications = async () => {
       try {
         const response = await api.get('/chat/notifications/');
         const unreadNotifications = response.data.filter(notification => !notification.is_read);
        // Set the count of unread notifications
        setNotificationCount(unreadNotifications.length);
       } catch (error) {
         console.error('Error fetching notifications:', error);
       }
     };
 
     fetchNotifications();
   }, []); 
    
 const navigate = useNavigate();
  
 const handleMarkNotificationsAsRead = async () => {
    try {
      await api.put('/chat/notifications/'); // Assuming this endpoint marks notifications as read
      navigate("/etudiant/notifications");
     
    } catch (err) {
      navigate("/etudiant/notifications");
    }
  };
  
  const handleLogout = async () => {
    try {
      const refreshToken = window.localStorage.getItem("refreshToken");
      const response = await api.post("logout/", { refresh: refreshToken });
      if (response.status === 204) {
        window.localStorage.clear();
        success("Déconnecté avec succès");
        navigate("/etudiant/login");
      }
    } catch (err) {
      error("Échec de la déconnexion");
    }
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  const fetchProfile = async () => {
    try {
      const response = await api.get('/student/profile/');
      setProfile(response.data); // Set the full profile data
      setProfileImage(sanitizeImageUrl(response.data.picture)); // Set the profile picture
      setCompletionPercentage(response.data.completion_percentage); // Set the completion percentage
    } catch (error) {
      console.error('Erreur lors de la récupération du profil:', error);
    }
  };
  return (
    <div className="animParent">
      <Row className="p-md-2 p-sm-3 p-xs-4 ">
        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
          <div className="container1-dashboard-student">
            <Row style={{ height: "100%" }}>
              <Col xxl={6} xl={8} lg={8} md={7} sm={7} xs={7} className="mt-5">
                <h3 className="hello-dashboard-student">
                  bonjour {firstName} 👋
                </h3>
                <p>
                  {completionPercentage < 50
                    ? "Profile incomplet, veuillez le compléter."
                    : completionPercentage <= 80
                    ? "Profile en cours de complétion, continuez."
                    : completionPercentage === 100
                    ? "Profile complet, félicitations !"
                    : "Presque terminé, vous êtes sur la bonne voie!"}
                </p>
              </Col>

              <Col
                xxl={6}
                xl={3}
                lg={4}
                md={5}
                sm={5}
                xs={5}
                className="mt-3"
                style={{ height: "100%" }}
              >
                <img src={books} alt="books" className="dashboard-books" />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="dashboad-student-profil-container">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <img
                  src={profileImage || profil}
                  alt="profile"
                  className="profile-picture"
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className="student-dashboard-nom">
                  {firstName} {lastName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <button
                  onClick={handleLogout}
                  className="dashboard-student-deconnecter"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.42 4.08L11.3325 5.1675C11.9933 5.56548 12.5401 6.12745 12.9199 6.79892C13.2996 7.47039 13.4995 8.22858 13.5 9C13.5 10.1935 13.0259 11.3381 12.182 12.182C11.3381 13.0259 10.1935 13.5 9 13.5C7.80653 13.5 6.66194 13.0259 5.81802 12.182C4.97411 11.3381 4.5 10.1935 4.5 9C4.5 7.3725 5.37 5.955 6.66 5.16L5.58 4.08C4.78338 4.62955 4.1323 5.36452 3.68285 6.22161C3.2334 7.07871 2.99905 8.03221 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 6.96 13.98 5.16 12.42 4.08ZM9.75 2.25H8.25V9.75H9.75"
                      fill="white"
                    />
                  </svg>{" "}
                  déconnecter
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="dashbord-student-containerBox mt-xl-4 mt-md-1 p-md-3  p-sm-3 p-xs-4">
<Row className="dashbord-student-containerBox mt-xl-4 mt-md-1 p-md-3  p-sm-3 p-xs-4">
      <Col xl={4} lg={4} md={12} sm={12} xs={12} className="mb-md-3">
          <div className="dashboard-student-container2-box ">
            <div
              onClick={handleMarkNotificationsAsRead}
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              <Row className="mt-2">
                <Col xl={7} lg={7} md={7} sm={7} xs={7}>
                  <h3>{notificationCount > 100 ? "99+" : notificationCount}</h3>
                  <p>Notifications</p>
                </Col>
                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <img src={cloche} alt="cloche" />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="mb-md-3">
          <div className="dashboard-student-container2-box " id="mybox2">
            <Row className="mt-2">
              <Col xl={7} lg={7} md={7} sm={7} xs={7}>
                <h3>{serviceAbon}</h3>
                <p>
                  {" "}
                  Services
                  <br /> Abonées
                </p>
              </Col>

              <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                <img
                  src={pc}
                  style={{ marginTop: "15px", marginLeft: "-2px" }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="mb-md-3">
          <div className="dashboard-student-container2-box" id="mybox3">
            <Link to="/etudiant/profile" style={{ textDecoration: "none" }}>
              <Row className="mt-2">
              <Col xl={7} lg={7} md={7} sm={7} xs={7}>
                <h3>{Math.round(completionPercentage)}%</h3>
                <p>
                  Profil
                  <br /> Terminé
                </p>
              </Col>

                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <img src={writing} />
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
      </Row>

      <Row className="mt-1  p-md-2  p-sm-3 p-xs-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-md-5 ">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className="">
              <h3 className="dashboard-student-prestations-title">
                {" "}
                Prestations de service
              </h3>
            </Col>
          </Row>

          <Row className="mt-5 prestation-service-group-card">
            <Col className="student-prestations-container1">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className="dashboard-student-Subtitle-service">
                    Certificat de cession <br />
                    irrévocable (AVI)
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <p className="student-dashbord-number">01</p>
                </Col>
                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <Link
                    to="/etudiant/prestations-de-service/attestation-de-virement"
                    className="student-dashboard-abonner"
                  >
                    S'abonner
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col className="student-prestations-container2">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className="dashboard-student-Subtitle-service">
                    Assurance tous risques
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <p className="student-dashbord-number">02</p>
                </Col>
                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <Link
                    to="/etudiant/prestations-de-service/assurance-completes"
                    className="student-dashboard-abonner"
                  >
                    S'abonner
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col className="student-prestations-container3">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className="dashboard-student-Subtitle-service">
                    Billetterie <br />
                    Services
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <p className="student-dashbord-number">03</p>
                </Col>
                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <Link
                    to="/etudiant/prestations-de-service/services-de-billeterie"
                    className="student-dashboard-abonner"
                  >
                    S'abonner
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col className="student-prestations-container4">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className="dashboard-student-Subtitle-service">
                    Recherche de <br />
                    logement
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <p className="student-dashbord-number">04</p>
                </Col>
                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <Link
                    to="/etudiant/prestations-de-service/recherche-de-logement"
                    className="student-dashboard-abonner"
                  >
                    S'abonner
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4 mb-5"></Row>
        </Col>
      </Row></Row>
    </div>
  );
}