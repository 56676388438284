import TableData from "../../Components/TableData";
import TableHeading from "../../Components/TableHeading";
import EditIcon from "../../images/AVI/edit.png";
import DeleteIcon from "../../images/AVI/trash.png";
import './AdminAVI.css';
import api from '../api/axiosInstance';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { Col, Container, Row, Table } from "react-bootstrap";

export default function AdminAVI() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsPages, setItemsPages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/candidates/");
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Confirmer la suppression ?")) {
      try {
        await api.delete(`/admin/candidates/${id}/`);
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
      } catch (error) {
        console.error(error);
      }
    }
  }; 

  useEffect(() => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    let current = 0;
    const newItemsPages = {};

    for (let index = 1; index <= totalPages; index++) {
      newItemsPages[index] = data.slice(current, current + itemsPerPage);
      current += itemsPerPage;
    }

    setItemsPages(newItemsPages);
  }, [data, itemsPerPage]);

  return (
    <div className="adminAVI">
      <Table responsive>
        <table className="adminAVItable">
          <thead>
            <tr>
              <TableHeading value={'UFS ID ETUDIANT'} />
              <TableHeading value={'Nom du candidat'} />
              <TableHeading value={'Prenom du candidat'} />
              <TableHeading value={'Date de naissance'} />
              <TableHeading value={'Email ID'} />
              <TableHeading value={'Lieu de naissance'} />
              <TableHeading value={'Université'} />
              <TableHeading value={'Année Scolaire'} />
              <TableHeading value={'Genre'} />
              <TableHeading value={'Status Candidat'} />
              <TableHeading value={'Date de validité'} />
              <TableHeading value={'Clef RIB'} />

            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td style={{ textAlign: 'center', width: '100%' }}>
                  Chargement des données...
                </td>
              </tr>
            ) : (
              itemsPages[currentPage]?.map((item, index) => (
                <tr key={index}>
                    <TableData value={item.id} />
                    <TableData value={item.nom_du_candidat} />
                    <TableData value={item.prenom_du_candidat} />
                    <TableData value={item.date_de_naissance} />
                    <TableData value={item.email} />
                    <TableData value={item.lieu_de_naissance} />
                    <TableData value={item.candidat_universitaire} />
                    <TableData value={item.annee_scolaire} />
                    <TableData value={item.sexe} />
                    <TableData value={item.statut_du_candidat} />
                    <TableData value={item.date_de_validite} />
                    <TableData value={item.cle_rib} />
                  <TableData
                    value={
                      <div className="tableDataActions">
                        <Link to={`/administrateur/avi/editer-compte/${item.id}`}>
                          <img src={EditIcon} alt="edit" className="tableDataAction" />
                        </Link>
                        <img src={DeleteIcon} alt="delete" onClick={() => handleDelete(item.id)} className="tableDataAction" />
                      </div>
                    }
                  />
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Table>

      <Pagination
        itemsPages={itemsPages}
        setItemsPerPage={setItemsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
}
