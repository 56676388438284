export default function SelectLabel({
  name,
  label,
  options = [],
  children,
  isInvalid = false,
  errorMessage = "",
  ...props
}) {
  return (
    <div className={`selectLabel ${isInvalid ? "has-error" : ""}`}>
      {label && (
        <label htmlFor={name} className="selectLabelLabel">
          {label}
        </label>
      )}
      <select
        name={name}
        id={name}
        className={`selectLabelInput ${isInvalid ? "is-invalid" : ""}`}
        {...props}
      >
        {children ? (
          children
        ) : (
          <>
            <option value=""></option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </>
        )}
      </select>
      {isInvalid && errorMessage && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
}
