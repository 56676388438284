import React, { useEffect, useState } from 'react';
import './TransactionStyles.css';
import TableHeading from '../../Components/TableHeading';
import TableData from '../../Components/TableData';
import profil1 from '../../images/Ellipse 991.png';
import profil2 from '../../images/Ellipse 992.png';
import profil3 from '../../images/Ellipse 993.png';
import Pagination from '../../Components/Pagination';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import dateIcon from '../../images/Parent/uil_calender.png';
// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Layout from './Layout';
import api from '../api/axiosInstance';
import show from "../../images/ShowEtu.png";
import delet from "../../images/deleteEtu.png";
import edit from "../../images/editEtu.png";
import profile from "../../images/etuAdm.png"

import { useNavigate } from 'react-router';
import sanitizeImageUrl from '../../StudentModule/utils/anitizeImageUrl';

export default function Transactions() {
    const navigate = useNavigate();

    const [showDelete, setShowDelete] = useState(false);
    const [selectedElement, setSelectedElement] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [transactions, setTransactions] = useState([]); // Store fetched data
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // État de chargement
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemsPages, setItemsPages] = useState({});
    const [filter, setFilter] = useState({
        transaction_id: 'desc',
        montant: 'desc',
        type: 'desc',
        status: 'desc'
    });

    // Fonction pour mapper les données de la transaction
    const mapTransactionData = (transactions) => {
        return transactions.map(transaction => ({
            id: transaction.id || 'N/A',
            student: {
                profil: "default-profile.png", // Image par défaut si aucune n'est fournie
                name: transaction.user_email || 'Nom non disponible'
            },
            date: transaction.date || 'Date non disponible',
            achat: transaction.service_name || 'Service inconnu',
            montant: transaction.amount || '0.00',
            transactionID: transaction.id || 'ID non disponible',
            type: transaction.method === 1 ? "Carte" : "Autre",
            status: transaction.status === "pending" ? "En cours" :
                    transaction.status === "success" ? "Succes" : "Echoué"
        }));
    };

    // Récupérer les données de l'API
    useEffect(() => {
        async function fetchContacts() {
            try {
                const response = await api.get("/admin/payments/");
                const filteredData = response.data;

                console.log("Fetched data:", filteredData); // Vérifiez ici les données
                setData(mapTransactionData(filteredData));
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Assurez-vous que le chargement est arrêté même en cas d'erreur
            }
        }
        fetchContacts();
    }, []);

    // Pagination
    useEffect(() => {
        const totalPages = Math.ceil(data.length / itemsPerPage);
        const newItemsPages = Array.from({ length: totalPages }, (_, index) => {
            const start = index * itemsPerPage;
            const end = start + itemsPerPage;
            return data.slice(start, end);
        });

        setItemsPages(newItemsPages);
    }, [data, itemsPerPage]);

    // Filtre de transaction
    const TransactionFilter = ({ filterTitle }) => {
        const varTitle = filterTitle.toLowerCase().replaceAll(' ', '_');
        const changeSelfFilter = () => {
            const status = filter[varTitle] === 'asc' ? 'desc' : 'asc';
            setFilter(prevFilter => ({
                ...prevFilter,
                [varTitle]: status
            }));
        };

        return (
            <div onClick={() => changeSelfFilter()} className='transactionListFilter'>
                <span>{filterTitle}</span>
                {filter[varTitle] === 'asc' ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
        );
    };

    // Fonction pour exporter les données vers un PDF
    const extractDataToPDF = () => {
        const invoice = document.getElementById("transactionTableContainer");
        html2canvas(invoice, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 10; // Définir la marge à 10 mm
            const imgWidth = pageWidth - margin * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const xPos = margin;
            const yPos = margin;
            pdf.addImage(imgData, "PNG", xPos, yPos, imgWidth, imgHeight);
            pdf.save("transactions.pdf");
        });
    };

    return (
        <Layout>
            <div className='adminTransactionList'>
                <header>
                    <div className="transactionListFilters">
                        <TransactionFilter filterTitle={'Transaction ID'} />
                        <TransactionFilter filterTitle={'Status'} />
                        <TransactionFilter filterTitle={'Type'} />
                        <TransactionFilter filterTitle={'Montant'} />

                        {/* version mobile */}
                        <select name="" id="">
                            <option value="">Transaction</option>
                            <option value="">Status</option>
                            <option value="">Type</option>
                            <option value="">Montant</option>
                        </select>

                        <div className="transactionDateFilter">
                            <span>Date</span>
                            <img src={dateIcon} />
                        </div>

                        <div className='transactionFilterButton'>
                            Filter
                        </div>
                    </div>

                    <div className="exportDataButton" onClick={extractDataToPDF}>
                        <div>
                            <span>Exporter données</span>
                            <IoIosArrowDown />
                        </div>
                    </div>
                </header>

                <div className="transactionTableContainer" id="transactionTableContainer">
                    <table>
                        <thead>
                            <tr>
                                <TableHeading value="ID" />
                                <TableHeading value="Étudiant" />
                                <TableHeading value="Date" />
                                <TableHeading value="Achat" />
                                <TableHeading value="Montant" />
                                <TableHeading value="ID transaction" />
                                <TableHeading value="Type" />
                                <TableHeading value="Status" />
                            </tr>
                        </thead>

                        <tbody>
                            {loading ? (
                                <tr>
                                    <td style={{ textAlign: "center", width: "100%" }} colSpan="8">
                                        Chargement des données...
                                    </td>
                                </tr>
                            ) : (
                                itemsPages[currentPage - 1]?.map((data, index) => (
                                    <tr key={data.id} className={index % 2 === 0 ? "OddTableRow" : ""}>
                                        <TableData value={data.id} />
                                        <TableData
                                            className="composedTD"
                                            value={
                                                <>
                                                    <img src={ data.student.picture ?sanitizeImageUrl(data.student.picture): profile} alt="profil" className="profileImage" />
                                                    <span>{data.student.name}</span>
                                                </>
                                            }
                                        />
                                        <TableData value={data.date} />
                                        <TableData value={data.achat} />
                                        <TableData
                                            value={
                                                <>
                                                    <span style={{ fontWeight: "bold" }}>{data.montant}</span>
                                                    <span style={{ fontWeight: "bold" }}> €</span>
                                                </>
                                            }
                                        />
                                        <TableData value={data.transactionID} />
                                        <TableData value={data.type} />
                                        <TableData
                                            value={
                                                <span
                                                    className={`${
                                                        data.status === "Succes" && "successStatusTD"
                                                    } ${
                                                        data.status === "Echoué" && "failStatusTD"
                                                    } ${
                                                        data.status === "En cours" && "pendingStatusTD"
                                                    }`}
                                                >
                                                    {data.status}
                                                </span>
                                            }
                                        />
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    {data.length > 0 && (
                        <Pagination
                            itemsPages={itemsPages}
                            setItemsPerPage={setItemsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}
