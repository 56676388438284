import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import Navbar from "../Layouts/Navbar";
import next from "../../images/nextAdd.png"
import CustomCountrySelect from "../Layouts/CountrySelect";
import { useState, useEffect } from "react";
import "./AttestationVirement.css";
import api from "../api/axiosInstance";
import FileUpload from "../Layouts/FileUpload";
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";
import { success, error } from "../utils/alerts";
import countries from '../../Layout/COUNTRY.json';
import findCitiesOfCountry from "../../Layout/findCitiesOfCountry";
import sanitizeImageUrl from "../utils/anitizeImageUrl";

export default function AttestationVirement() {
  const [cities, setCities] = useState([]);
  const [villes, setVilles] = useState([]);
  const [Lieux, setLieux] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(true);
  const [loading, setLoading] = useState(false)

  //useState pour Backend
  const getLocalStorageItem = (key, defaultValue = "") => localStorage.getItem(key) || defaultValue;
  const [prenom, setPrenom] = useState(getLocalStorageItem("firstname"));
  const [nom, setNom] = useState(getLocalStorageItem("lastname"));
  const [tel, setTel] = useState(getLocalStorageItem("phone"));
  const [lieu, setLieu] = useState(getLocalStorageItem("birthPlace"));
  const [dateN, setDateN] = useState(getLocalStorageItem("birthDate"));
  const [email, setEmail] = useState(getLocalStorageItem("email"));
  const [nationalite, setNationality] = useState("");
  const [codeCountry, setCodeCountry] = useState(getLocalStorageItem("codeCountry"));
  const [pays, setPays] = useState(getLocalStorageItem("country"));
  const [university, setUniversity] = useState("");
  const [programme, setProgramme] = useState("");
  const [niveauEtude, setNiveauEtude] = useState("");
  const [villeDestination, setVilleDestination] = useState("");
  const [paysDestination, setPaysDestination] = useState("");
  const [AutreNiveau, setAutreNiveau] = useState("");
  const [preliminary_acceptance, setpreliminary_acceptance] = useState(null);
  const [payment_proof, setpayment_proof] = useState(null);
  const [passport_copy, setpassport_copy] = useState(null);
  const [errors, setErrors] = useState({});



  console.log(getLocalStorageItem("nationality"))
  const niveaux = [
    "Diplôme de fin d'études secondaires",
    "Baccalauréat",
    "Licence",
    "Master",
    "Doctorat",
    "Certificat professionnel",
    "Diplôme technique",
    "Formation continue",
    "Études à l'étranger",
    "Autre",
  ];


  const handleChangeNiveau = (e) => {
    const selectedNiveau = e.target.value;
    setNiveauEtude(selectedNiveau);
    setShowInput(selectedNiveau === "Autre");
  };


  const handleInputChangeAutreNiveau = (e) => {
    setAutreNiveau(e.target.value);
  };


  // const sanitizeImageUrl = (url) => {
  //   if (typeof url === 'string' && url.includes('192.162.68.92:8000')) {
  //     // return url.replace('http://192.162.68.92:8000', 'https://app.universfrancesucces.com' );
  //     return url.replace('http://192.162.68.92:8000', process.env.REACT_APP_API_URL_IMAGE);
  //   }
  //   return url;
  // };
  // Fetch certificat Data
  useEffect(() => {
    const fetchCertificatData = async () => {
      const accessToken = localStorage.getItem("accessToken");
      try {
        const response = await api.get("student/certificate/");
        const certificatData = response.data;


        // Set state based on API response
        setPrenom(certificatData.first_name || "");
        setNom(certificatData.last_name || "");
        setTel(certificatData.phone_number || "");
        setLieu(certificatData.place_of_birth || "");
        setDateN(certificatData.date_of_birth || "");
        setEmail(certificatData.email || "");
        setNationality(certificatData.nationality || "");
        setCodeCountry(certificatData.country_code || "");
        setPays(certificatData.country || "");
        setUniversity(certificatData.accepted_university || "");
        setProgramme(certificatData.accepted_program || "");
        setNiveauEtude(certificatData.accepted_level || "");
        setVilleDestination(certificatData.destination_city || "");
        setPaysDestination(certificatData.destination_country);
        setAutreNiveau(certificatData.autreNiveau || "");
        setpreliminary_acceptance(sanitizeImageUrl(certificatData.files.preliminary_acceptance || null));
        setpayment_proof(sanitizeImageUrl(certificatData.files.payment_proof || null));
        setpassport_copy(sanitizeImageUrl(certificatData.files.passport_copy || null));
        function renameFile(file, newName) {
          return new File([file], newName, { type: file.type });
        }
      } catch (err) {
        // Show error toast
        console.log("Erreur lors de la récupération des données");
      }
    };


    fetchCertificatData();
  }, []);



  const handleCountryDestination = (e) => {
    setPaysDestination(e.target.value);
  };


  const handlePays = (e) => {
    setPays(e.target.value);
  };

  function handleCodeCountry(c) {

  }


  const handleCertificationSubmit = async () => {
    const newErrors = {};
    if (!prenom) newErrors.prenom = "Le prénom est requis.";
    if (!nom) newErrors.nom = "Le nom est requis.";
    if (!codeCountry) newErrors.codeCountry = "Le code du pays est requis.";
    if (!tel) newErrors.tel = "Le numéro de téléphone est requis.";
    if (!dateN) newErrors.dateN = "La date de naissance est requise.";
    if (!pays) newErrors.pays = "Le pays est requis.";
    if (!lieu) newErrors.lieu = "Le lieu de naissance est requis.";
    if (!email) newErrors.email = "L'email est requis.";
    if (!university) newErrors.university = "L'université acceptée est requise.";
    if (!niveauEtude) newErrors.niveauEtude = "Le niveau d'études accepté est requis.";
    if (!programme) newErrors.programme = "Le programme accepté est requis.";
    if (!paysDestination) newErrors.paysDestination = "Le pays de destination est requis.";
    if (!villeDestination) newErrors.villeDestination = "La ville de destination est requise.";
    if (!preliminary_acceptance) newErrors.preliminary_acceptance = "L'acceptation préliminaire est requise.";
    if (!payment_proof) newErrors.payment_proof = "La preuve de paiement est requise.";
    if (!passport_copy) newErrors.passport_copy = "La copie du passeport est requise.";
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      console.error("Validation failed:", errors);
      return;
    }

    console.log("Form is valid, proceed with submission.");

    console.log("Form is valid, proceed with submission.");




    try {
      setLoading(true);
      const formData = new FormData();

      // Append text fields using backend JSON keys

      formData.append("first_name", prenom);
      formData.append("last_name", nom);
      formData.append("country_code", codeCountry);
      formData.append("phone_number", tel);
      formData.append("date_of_birth", dateN);
      formData.append("country", pays);
      formData.append("place_of_birth", lieu);
      formData.append("nationality", nationalite || "");
      formData.append("email", email);
      formData.append("accepted_university", university);
      formData.append("accepted_level", niveauEtude);
      formData.append("autre_niveau", AutreNiveau); // Ensure this matches backend naming if applicable
      formData.append("accepted_program", programme || null);
      formData.append("destination_country", paysDestination || null);
      formData.append("destination_city", villeDestination || null);

      if (preliminary_acceptance instanceof File) {
        formData.append("files.preliminary_acceptance", preliminary_acceptance);
      } else {
        console.log("preliminary_acceptance n'est pas un fichier");
      }

      if (payment_proof instanceof File) {
        formData.append("files.payment_proof", payment_proof);
      } else {
        console.log("payment_proof n'est pas un fichier");
      }

      if (passport_copy instanceof File) {
        formData.append("files.passport_copy", passport_copy);
      } else {
        console.log("passport_copy n'est pas un fichier");
      }

      const response = await api.post("/student/certificate/", formData);

      // Handle the response
      if (response.status >= 200 && response.status < 300) {
        let msg;
      
        // Check if it's a successful creation (status 201 for POST requests)
        if (response.status === 201) {
          msg = "Votre service Avi a été créé avec succès."; // Creation message
        } 
        // Check if it's a successful update (status 200 for PUT/PATCH requests)
        else if (response.status === 200) {
          msg = "Votre service Avi a été mis à jour avec succès."; // Update message
        } 
        else {
          msg = response.data?.data?.message || "Action réussie"; // Default success message
        }
      
        success(msg);
        setLoading(false);
      
      } else {
        setLoading(false);
        console.error("Failed to submit form:", response);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during form submission: ", error);
    }
    finally {
      setLoading(false);
    }
  };
  //get les données


  const navigate = useNavigate();


  return (
    <div className="animParent">
      <Row className="mt-4 mb-3 d-flex button-retour-container justify-content-end">
        <button
          onClick={() => navigate("/etudiant/prestations-de-service")}
          type="submit"
          className="button-retour-prestation"
        >
          <IoArrowBackCircle className="retour-assurance-icon" />
          Retour
        </button>
      </Row>


      <Row
        className="student-attestation-container mt-3"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">
            Attestation de virement irrévocable (AVI)
          </h3>
          <Form className="">
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={prenom}
                    placeholder="Entrez votre prénom"
                    onChange={(e) => {
                      setPrenom(e.target.value)
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.prenom}  {/* Display the error message */}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Nom de famille
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={nom}
                    placeholder="Entrez votre nom de famille"
                    onChange={(e) => {
                      setNom(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nom}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Numéro de téléphone</Form.Label>
                  <Row>
                    <Col xl={4} lg={4} md={3} sm={3} xs={12}>
                      <CustomCountrySelect dialCode={codeCountry}
                        codeCountry={setCodeCountry} />
                    </Col>
                    <Col xl={8} lg={8} md={9} sm={9} xs={12}>
                      <Form.Control
                        type="text"
                        name="tel"
                        value={tel}
                        placeholder="XXX XXX XXXX"
                        onChange={(e) => {
                          setTel(e.target.value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.tel}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Pays
                  </Form.Label>
                  <Form.Select
                    name="pays"
                    value={pays}
                    onChange={handlePays}
                  >
                    <option value="" disabled>
                      Sélectionnez le pays
                    </option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.pays}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateN"
                    value={dateN}
                    onChange={(e) => {
                      setDateN(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.dateN}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Lieu de naissance
                  </Form.Label>
                  {
                    Lieux.length > 0 ?
                      <Form.Select
                        name="lieu"
                        value={lieu}
                        defaultValue=""
                        onChange={(e) => {
                          setLieu(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Sélectionnez lieu de naissance
                        </option>
                        {Lieux.length > 0 &&
                          Lieux.map((ville) => (
                            <option key={ville} value={ville}>
                              {ville}
                            </option>
                          ))}
                      </Form.Select> :
                      <Form.Control
                        type="text"
                        name="lieu"
                        value={lieu}
                        placeholder="Entrez le lieu de naissance"
                        onChange={(e) => {
                          setLieu(e.target.value);
                        }}
                      />
                  }
                  <Form.Control.Feedback type="invalid">
                    {errors.lieu}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Adresse Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Entrez l'adresse e-mail "
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Université acceptée
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="university"
                    value={university}
                    placeholder="Université acceptée"
                    onChange={(e) => {
                      setUniversity(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.university}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Niveau d'études accepté</Form.Label>
                  <Form.Select
                    name="niveauEtude"
                    value={niveauEtude}
                    defaultValue=""
                    onChange={handleChangeNiveau}
                  >
                    <option value="" disabled>
                      Sélectionnez
                    </option>
                    {niveaux.map((niveau, index) => (
                      <option key={index} value={niveau}>
                        {niveau}
                      </option>
                    ))}

                  </Form.Select>
                  {showInput && (
                    <Form.Control
                      type="text"
                      name="AutreNiveau"


                      placeholder="Entrez niveau d'études accepté"
                      className="mt-3"
                      onChange={(e) => {
                        setAutreNiveau(e.target.value);
                      }}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.niveauEtude}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Programme accepté
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="programme"
                    value={programme}
                    placeholder="Entrez programme accepté"
                    onChange={(e) => {
                      setProgramme(e.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.programme}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className=" mt-3">
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label>Pays de destination</Form.Label>
                  <Form.Select
                    name="pays"
                    value={paysDestination} // Controlled value linked to state
                    onChange={handleCountryDestination} // Update state on selection change
                  >
                    <option value="" disabled>
                      Sélectionnez le pays de destination
                    </option>
                    {countries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.paysDestination}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Group>
                  <Form.Label className="label-student-attestation">
                    Ville de destination
                  </Form.Label>
                  {
                    villes.length > 0 ?
                      <Form.Select
                        name="villeDestination"
                        value={villeDestination}
                        defaultValue=""
                        onChange={(e) => {
                          setVilleDestination(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Sélectionnez la ville de destination
                        </option>
                        {villes.length > 0 &&
                          villes.map((ville) => (
                            <option key={ville} value={ville}>
                              {ville}
                            </option>
                          ))}
                      </Form.Select> :
                      <Form.Control
                        type="text"
                        name="villeDestination"
                        value={villeDestination}
                        placeholder="Entrez la ville de destination"
                        onChange={(e) => {
                          setVilleDestination(e.target.value);
                        }}
                      />
                  }
                  <Form.Control.Feedback type="invalid">
                    {errors.villeDestination}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>


      <Row
        className="student-attestation-container2 mt-4"
        style={{ marginLeft: "1%" }}
      >
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <h3 className="student-attestation-title">Documents à télécharger</h3>
          <Row className="">
            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                Pré-acceptation à l'inscription
              </h3>
              <div className="attestation-save-container ">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={preliminary_acceptance}
                  setExistingFile={setpreliminary_acceptance}
                  nameF="preliminary_acceptance"
                />
                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.preliminary_acceptance}
              </Form.Control.Feedback>
            </Col>

            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
              <h3 className="student-attestation-save-title">
                Preuve de règlement
              </h3>

              <div className="attestation-save-container">
                <FileUpload
                  svgIcon={icon}
                  label="Télécharger"
                  acceptedTypes=".png, .jpeg, .pdf"
                  existingFile={payment_proof}
                  setExistingFile={setpayment_proof}
                  fieldName="files[additional_pdf]"
                  nameF="payment_proof"
                />

                <p className="attestation-save-p2">
                  {" "}
                  Fichiers autorisés Fichiers PDF, JPEG, PNG
                </p>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.payment_proof}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-between"
          >
        <Col
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className="student-Attestation-container-pdf mt-4 pb-4"
          id="pdfContainer1"
        >
          <h3 className="student-attestation-title mx-2">
            Documents à télécharger
          </h3>
          <h3 className="student-attestation-save-title mx-2">
            Télécharger la copie du passeport
          </h3>
          <div className="attestation-save-container">
            <FileUpload
              svgIcon={icon}
              label="Télecharger"
              acceptedTypes=".png, .jpeg, .pdf"
              setExistingFile={setpassport_copy}
              existingFile={passport_copy}
              nameF="passport_copy"
            />

            <p className="attestation-save-p2" style={{ marginTop: "10px" }}>
              {" "}
              Fichiers autorisés Fichiers PDF, JPEG, PNG
            </p>
          </div>
          <small
  className="text-danger"

>
  {errors.passport_copy}
</small>

        </Col>

      </Row>


      <Row className="mt-4 mb-3 d-flex justify-content-end submit-button-container">
        <button
          type="submit"
          className='creer'
          onClick={handleCertificationSubmit}
        >
          {
            !loading ? (
              <>
                Soumettre
                <img src={next} className='imgbtnadd' />
              </>
            ) : (
              <Spinner animation="border" size="md" />
            )
          }

        </button>
      </Row>
    </div>
  );
}
const icon = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.5"
      width="39"
      height="39"
      rx="10"
      fill="url(#paint0_linear_991_5415)"
      fill-opacity="0.1"
    />
    <path
      d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5"
      stroke="url(#paint1_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75"
      stroke="url(#paint2_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z"
      stroke="url(#paint3_linear_991_5415)"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_991_5415"
        x1="-0.37396"
        y1="10.8019"
        x2="37.6153"
        y2="11.1255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_991_5415"
        x1="14.685"
        y1="20.0849"
        x2="18.5815"
        y2="20.107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_991_5415"
        x1="9.43002"
        y1="14.783"
        x2="28.9117"
        y2="14.949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_991_5415"
        x1="21.622"
        y1="11.6132"
        x2="29.4147"
        y2="11.6796"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#015192" />
        <stop offset="1" stop-color="#003D6E" />
      </linearGradient>
    </defs>
  </svg>
);
