import { React, useState } from "react";
import DownloadFileIcon from "../../images/Frame.png";
import FileUpload from "../Layouts/FileUpload";

export default function FileToDownload({titre, acceptanceText,fichier,setFichier,  errorMessage = "",...props }) {

    const icon=  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.5" width="39" height="39" rx="10" fill="url(#paint0_linear_991_5415)" fill-opacity="0.1"/>
    <path d="M16.749 24.5V18.5M16.749 18.5L14.749 20.5M16.749 18.5L18.749 20.5" stroke="url(#paint1_linear_991_5415)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.75 17.5V22.5C29.75 27.5 27.75 29.5 22.75 29.5H16.75C11.75 29.5 9.75 27.5 9.75 22.5V16.5C9.75 11.5 11.75 9.5 16.75 9.5H21.75" stroke="url(#paint2_linear_991_5415)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.75 17.5H25.75C22.75 17.5 21.75 16.5 21.75 13.5V9.5L29.75 17.5Z" stroke="url(#paint3_linear_991_5415)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
    <linearGradient id="paint0_linear_991_5415" x1="-0.37396" y1="10.8019" x2="37.6153" y2="11.1255" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    <linearGradient id="paint1_linear_991_5415" x1="14.685" y1="20.0849" x2="18.5815" y2="20.107" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    <linearGradient id="paint2_linear_991_5415" x1="9.43002" y1="14.783" x2="28.9117" y2="14.949" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    <linearGradient id="paint3_linear_991_5415" x1="21.622" y1="11.6132" x2="29.4147" y2="11.6796" gradientUnits="userSpaceOnUse">
    <stop stop-color="#015192"/>
    <stop offset="1" stop-color="#003D6E"/>
    </linearGradient>
    </defs>
    </svg>
  

  const [justificatif, setJustificatif] = useState("");
  const [label, setLabel] = useState("Télécharger");

    return(
        <div className='documentATelecharger'    {...props}>
            <h5 className='documentATelechargerTitre'>{titre}</h5>
            <div className='documentATelechargerCarte'>
                
                <FileUpload svgIcon={icon} setLabel={setLabel} label={label} acceptedTypes=".png, .jpeg, .pdf"  existingFile={fichier}  setExistingFile={setFichier}/>

                <div className='documentATelechargerConsigne'>
                    <p>Fichiers autorisés Fichiers JPEG, PNG, PDF</p>
                    <p>({acceptanceText})</p>
                </div>
            </div>
          
            {errorMessage && (
                
          <div className="invalid-feedback">{errorMessage}</div>
        )}
        </div>
    )
}
