import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Interceptor pour gérer le Content-Type dynamiquement et ajouter l'Authorization
api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    

    // Ajouter le token d'accès s'il est disponible
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    } else {
      delete config.headers["Authorization"];
    }

    // Définir dynamiquement le Content-Type
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor pour gérer les erreurs de réponse et le rafraîchissement du token
api.interceptors.response.use(
  (response) => response, // Retourner la réponse si pas d'erreur
  async (error) => {
    const originalRequest = error.config;

    // Gérer le cas du rafraîchissement de token
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");

        if (!refreshToken) {
          throw new Error("No refresh token available.");
        }

        // Envoyer une requête pour rafraîchir le token
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}token/refresh/`,
          { refresh: refreshToken }
        );

        const newAccessToken = refreshResponse.data.access;
        const newRefreshToken = refreshResponse.data.refresh;

        // Mettre à jour les tokens dans localStorage
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        // Ajouter le nouveau token à l'en-tête Authorization
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        // Relancer la requête originale
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Refresh token failed", refreshError);

        // Supprimer les tokens invalides et rediriger vers la page de connexion
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    }

    return Promise.reject(error);
  }
);

export default api;