import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import "../../Student_Admin/Student.css";
import next from "../../../images/nextAdd.png";
import profil from "../../../images/inpProfil.png";
import add from "../../../images/add.png";
import api from '../../api/axiosInstance'; // Assuming your axiosInstance is set up
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect';

function AdminUpdate() {
  const fileInputRef = useRef(null);
  const { id } = useParams(); // Get the ID from the URL
  const [codeCountry, setCodeCountry] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [adminData, setAdminData] = useState(null); // To store admin data fetched from API
  const [universities, setUniversities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [profileImage, setProfileImage] = useState(null); // For storing the profile image file
  const [picture, setPicture] = useState(null); // Fix undefined setPicture issue
  const [profilImagePreview, setProfilImagePreview] = useState(profil); // Fix undefined setProfilImagePreview issue
  const navigate = useNavigate();
  const [data, setData] = useState({
    picture: ""
  });

  // Fetch admin data by ID
  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await api.get(`/admin/admin/${id}/`); // Make the GET request for the admin
        const admin = response.data;
        console.log('Fetched Admin Data:', admin);
        setCodeCountry(admin.country_code); // Set the country code
        setAdminData({
          nom: admin.last_name || "",
          prenom: admin.first_name || "",
          email: admin.email || "",
          codeCountry: admin.country_code || null,
          phone: admin.phone_number || "",
          pays: admin.country || "",
          dateN: admin.date_of_birth || "",
          picture: admin.picture,
          role: admin.role || "",
        })
        setDialCode(admin.country_code)
        setCodeCountry(admin.country_code)
        ;

        if (admin.picture) {
          setProfilImagePreview(admin.picture); 
          console.log(admin.picture); 
        }
      } catch (err) {
        console.error('Error fetching admin data:', err);
      }
    };

    fetchAdminData();

    // Fetch universities and countries
    fetch("http://universities.hipolabs.com/search?country=France")
      .then(response => response.json())
      .then(data => {
        const sortedUniversities = data.sort((a, b) => a.name.localeCompare(b.name));
        setUniversities(sortedUniversities);
      })
      .catch(error => console.error('Error fetching universities:', error));

    fetch("https://restcountries.com/v3.1/all")
      .then(response => response.json())
      .then(data => {
        const sortedCountries = data.sort((a, b) => a.name.common.localeCompare(b.name.common));
        setCountries(sortedCountries);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, [id]); // Depend on `id` to fetch data whenever the ID changes

  // Handle image file input
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setPicture({ ...data, picture: file }); // Update picture state
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilImagePreview(reader.result); // Set the image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCodeCountry = (selectedCode) => {
    setCodeCountry(selectedCode); // Update the codeCountry state
    setAdminData((prevState) => ({
      ...prevState,
      codeCountry: selectedCode, // Synchronize with adminData
    }));
  };

  // Handle back navigation
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Container>
      <Row>
        <Col>
          <span className='RouteStu'>
            <p className='routeStu'>Administrateur</p>
            <p className='RouteStuRoute'>/ modifier Administrateur </p>
          </span>
        </Col>
        <Col>
          <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col>
      </Row>

      {adminData ? (
        <form onSubmit={""}>
          <Row>
            <Col className="addStu1">
              <Row>
                <Col sm={6}>
                  <p className='titleEtu'>Compte Administrateur</p>
                </Col>
                <Col sm={6}>
                  <span className='spanInpImg'>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      className='imgInpModif'
                      style={{ display: 'none' }}
                      //onChange={handleImageChange}
                    />
                    <img
                      src={profilImagePreview || profil} // If profile image exists, use it; otherwise, use default image
                      alt="Cliquez pour changer l'image"
                      className="spanInpImg img-fluid img-rounded"
                      //onClick={() => fileInputRef.current.click()}
                      style={{ borderRadius: '50%' }}
                    />
                    
                  </span>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <p className='labelInp'>Nom</p>
                  <input
                    type='text'
                    placeholder='Votre Nom'
                    className='inpStu'
                    name="first_name"
                    defaultValue={adminData.prenom} // Pre-fill with admin's first name
                    readOnly // Make the field read-only
                  />
                </Col>
                <Col sm={6}>
                  <p className='labelInp'>Prenom</p>
                  <input
                    type='text'
                    placeholder='Votre Prénom'
                    className='inpStu'
                    name="last_name"
                    defaultValue={adminData.nom} // Pre-fill with admin's last name
                    readOnly // Make the field read-only
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <p className='labelInp'>Numero de telephone</p>
                  <Row>
                    <Col sm={3}>
                      <CustomCountrySelectAdmin
                        codeCountry={setCodeCountry} dialCode={dialCode} // Pass the function to handle country code changes
                        readOnly // Make it read-only if needed
                      />
                    </Col>
                    <Col>
                      <input
                        type='text'
                        placeholder='XXX XXX XXXX'
                        className='inpTelEtu'
                        name="phone_number"
                        defaultValue={adminData.phone} // Pre-fill phone number
                        readOnly // Make the field read-only
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm={6}>
                  <p className='labelInp'>Date De Naissance</p>
                  <input
                    type='date'
                    className='inpStu'
                    name="date_of_birth"
                    defaultValue={adminData.dateN} // Pre-fill date of birth
                    readOnly // Make the field read-only
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <p className='labelInp'>Pays</p>
                  <select
                    className='inpStu'
                    name="country"
                    value={adminData.pays || ''} // Ensure the value is correctly set
                    onChange={(e) => setAdminData({ ...adminData, pays: e.target.value })} // Update the country in adminData
                    disabled // Disable the select field
                  >
                    <option value="">Sélectionner</option>
                    {countries.map(country => (
                      <option key={country.cca3} value={country.name.common}>
                        {country.name.common}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col sm={6}>
                  <p className='labelInp'>Adresse Email</p>
                  <input
                    type='email'
                    placeholder='Votre Adresse Email'
                    className='inpStu'
                    name="email"
                    defaultValue={adminData.email} // Pre-fill email
                    readOnly // Make the field read-only
                  />
                </Col>
              </Row>

              <Row className='rowBtnAdd'>
                <Col>
  
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </Container>
  );
}

export default AdminUpdate;
