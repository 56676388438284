import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./profile.css";
import { useParams, useNavigate } from "react-router-dom";
import successImage from "../../images/success-icon.png";
import errorImage from "../../images/error-icon.png";
import document from "../../images/document.png";
import phone from "../../images/icon-tel.png";
import ModifyImage from "../../images/icon-image.png";
import deconnecter from "../../images/icon-deconnecter.png";
import mail from "../../images/icon-mail.png";
import modify from "../../images/icon-modify.png";
import Profile from "../../images/account.png";
import axios from "axios";
import { success, error as showError } from "../utils/alerts";
import api from "../api/axiosInstance";
import FileToDownload from "./FileToDownload";
import sanitizeImageUrl from "../../StudentModule/utils/anitizeImageUrl";



function Prameter() {
  // const [profileImage, setProfileImage] = useState(Profile);

  const fileInputRef = useRef(null);
  const [cardNumber, setCardNumber] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiration, setExpiration] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [navbarTitle, setNavbarTitle] = useState("Parameter");
  const { section } = useParams();
  const [activeSection, setActiveSection] = useState(section || "Documents");
  const [profile, setProfile] = useState({});
  const [profileImage, setProfileImage] = useState('');

  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/parent/profile');
  };
  const [firstname, setFirstname] = useState(localStorage.getItem("firstname"));
  const [lastname, setLastname] = useState(localStorage.getItem("lastname"));
  const picture = localStorage.getItem("picture");
  const email = localStorage.getItem("email");
  const phone_nomber = localStorage.getItem("phone");

  const fetchProfile = async () => {
    try {
      const response = await api.get('/parent/profile/');
      setProfileImage(sanitizeImageUrl(response.data.picture));
      setProfile(response.data);
      setFirstname(response.data.first_name);
      setLastname(response.data.last_name);
    } catch (error) {
      console.error('Erreur lors de la récupération du profil:', error);
    }
  };

  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");

  const handleLogout = async () => {
    try {
      const refreshToken = window.localStorage.getItem("refreshToken");
      const response = await api.post("logout/", { refresh: refreshToken });
      if (response.status === 204) {
        window.localStorage.clear();
        success("Déconnecté avec succès");
        navigate("/etudiant/login");
      }
    } catch (err) {
      error("Échec de la déconnexion");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if we are submitting card details or password change
    if (activeSection === 'Payment') {
      // Validate card details
      if (cardNumber.length < 16 || cvv.length < 3 || !expiration.match(/^\d{2}\/\d{2}$/)) {
        setShowErrorModal(true);
      } else {
        setShowSuccessModal(true);
      }
    } else if (activeSection === 'motdepasse') {
      const { currentPassword, newPassword, confirmPassword } = formData;

      // Check if new passwords match
      if (newPassword !== confirmPassword) {
        setAlertType("error");
        setAlert("Les nouveaux mots de passe ne correspondent pas.");
        return;
      }

      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.put(
          "/api/change-password/",
          {
            old_password: currentPassword, // Correction du nom du paramètre
            new_password: newPassword,       // Correction du nom du paramètre
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200) {
          setAlertType("success");
          setAlert("Mot de passe changé avec succès.");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setAlertType("error");
            setAlert("Le mot de passe actuel est incorrect.");
          } else {
            setAlertType("error");
            setAlert("Erreur lors du changement de mot de passe.");
          }
        } else {
          setAlertType("error");
          setAlert("Une erreur inconnue s'est produite.");
        }
        console.error("Error changing password:", error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchProfile();
  }, []);





  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('picture', file);

    try {
      const response = await api.put('/parent/profile/', formData);
      setProfileImage(sanitizeImageUrl(response.data.picture));
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image :", error);
    }
  };
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    ticketing: [],
    irrevocablePayments: [],
    insurances: [],
    housing: [],
  });

  useEffect(() => {
    const fetchServiceFiles = async () => {
      try {
        const response = await api.get('parent/services/files/');
        if (!response) {
          throw new Error('Une erreur est survenue lors de la récupération des données.');
        }
  
        const result = response.data.children;
        console.log('test', result);
  
        if (result && result.length > 0) {
          const servicesData = result[0].services_files; // Access services_files of the first child (adjust logic as needed)
  
          setData({
            ticketing: servicesData.ticketing || [],
            irrevocablePayments: servicesData.irrevocable_payments || [],
            insurances: servicesData.insurances || [],
            housing: servicesData.housing || [],
          });
        } else {
          setError('Aucune donnée trouvée.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchServiceFiles();
  }, []);
  

  const renderFileLinks = (files) => {
    if (!files || files.length === 0) return <p>Aucun fichier disponible.</p>;

    return files.map((file, index) => (
      <div style={{ display: 'flex', }} key={index}>
        <div>
          <strong>Preuve d'acceptation </strong>{' '}
          {file.preliminary_acceptance ? (
            <FileToDownload
              fieldName="files[preliminary_acceptance]"
              fichier={sanitizeImageUrl(file.preliminary_acceptance)}
            />

          ) : (
            ': Non disponible'
          )}
        </div>
        <div className="px-2">
          <strong>Copie du passeport </strong>{' '}
          {file.passport_copy ? (
            <FileToDownload
              fieldName="files[passport_copy]"
              fichier={sanitizeImageUrl(file.passport_copy)}
            />
          ) : (
            ': Non disponible'
          )}
        </div>
        <div className="px-2">
          <strong>Preuve de paiement </strong>{' '}
          {file.payment_proof ? (
            <FileToDownload
              fieldName="files[payment_proof]"
              fichier={sanitizeImageUrl(file.payment_proof)}
            />
          ) : (
            ': Non disponible'
          )}
        </div>

        <div className="px-2">
          <strong>Document additionnel </strong>{' '}
          {file.additional_pdf ? (
            <FileToDownload
              fieldName="files[additional_pdf]"
              fichier={sanitizeImageUrl(file.additional_pdf)}
            />
          ) : (
            ': Non disponible'
          )}
        </div>
      </div>
    ));
  };
  const renderModalContent = (isSuccess) => {
    return (
      <div className="text-center">
        <img
          src={isSuccess ? successImage : errorImage}
          alt={isSuccess ? "Succès" : "Erreur"}
          className="img-msg"
        />
        <p className="p-msg">
          {isSuccess
            ? "Votre carte de débit a été ajoutée avec succès !"
            : "Les informations de la carte de débit que vous avez saisies ne sont pas correctes. Veuillez saisir la bonne information."}
        </p>
        <Button
          className="btn-msg"
          onClick={() => {
            if (isSuccess) {
              window.location.href = "/";
            } else {
              setShowErrorModal(false);
            }
          }}
        >
          {isSuccess ? "Retour à l'accueil" : "Réessayer"}
        </Button>
      </div>
    );
  };


  const renderSection = () => {
    switch (activeSection) {
      case "Documents":
        return (
          <div>
            
            <Accordion className="reasons-accordion" defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Documents Certificat de cession irrévocable (AVI)</Accordion.Header>
                <Accordion.Body>
                  <div className="btn-modify">
                    {renderFileLinks(data.irrevocablePayments)}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
        
              <Accordion.Item eventKey="1">
                <Accordion.Header>Documents Assurance tous risques</Accordion.Header>
                <Accordion.Body>
                  <div className="btn-modify">
                    {renderFileLinks(data.insurances)}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
        
              <Accordion.Item eventKey="2">
                <Accordion.Header>Documents Billetterie Services</Accordion.Header>
                <Accordion.Body>
                  <div className="btn-modify">
                    {renderFileLinks(data.ticketing)}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
        
              <Accordion.Item eventKey="3">
                <Accordion.Header>Documents Recherche de logement</Accordion.Header>
                <Accordion.Body>
                  <div className="btn-modify">
                    {renderFileLinks(data.housing)}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      case "motdepasse":
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="newPassword" className="row mb-3">
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className='label-form'>Mot de passe actuel</Form.Label>
                <Form.Control
                  type="password"
                  name="currentPassword"
                  className="input"
                  placeholder='Entrez votre mot de passe actuel'
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className='label-form'>Nouveau mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  className="input"
                  placeholder='Entrez le nouveau mot de passe'
                  value={formData.newPassword}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={6} className="mb-4">
                <Form.Label className='label-form'>Confirmez le mot de passe</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  className="input"
                  placeholder='Confirmez le mot de passe'
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Button className='btn-sauveguard' type="submit">Sauvegarder</Button>
            {alert && <div className={`alert alert-${alertType}`}>{alert}</div>}
          </Form>
        );
      
        return null;
    }
  };

  // Update title when activeSection changes
  useEffect(() => {
    if (activeSection === "motdepasse") {
      setNavbarTitle("Profil");
    } else {
      setNavbarTitle("Parameter");
    }
  }, [activeSection]);



  return (
    <>
      <Container fluid className="container-profile">
        <Col>
          <div className="header">
            <div className="d-flex align-items-center gap-3 mb-3">
              <div className="col1">
                <div className="position-relative">
                  <img
                    src={profileImage?.startsWith('http:') ? profileImage.replace('http://', 'https://') : profileImage || Profile}
                    alt="profil"
                    className="img-fluid rounded-circle profile-image"
                  />
                  <span className="img-overlay" onClick={() => fileInputRef.current.click()}>
                    <img src={ModifyImage} alt='modify' className="img-icon img-fluid rounded-circle" />
                  </span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
              <div className='col2'>
                <h1 id='nom'>{firstname} {lastname}</h1>
                <p>
                  <img src={mail} alt='mail' className='icon-profile' /> {email}
                </p>
                <p>
                  <img src={phone} alt='tel' className='icon-profile' /> {phone_nomber}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-start mb-3">
              <img
                src={deconnecter}
                alt="déconnecter"
                id="deconnecter"
                className="me-2"
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              />
              <button className="btn-modify" onClick={handleClick}>
                <img src={modify} alt="modify" /> Modifier
              </button>
            </div>
          </div>
          <hr />
          <Row xs={12} md={7}>
            <div className="nav-buttons d-flex align-items-center ">
              <a
                onClick={() => setActiveSection("Documents")}
                className={`${activeSection === "Documents"
                  ? "nav-button-active"
                  : "nav-button-inactive"
                  } nav-link-margin`}
              >
                Documents
              </a>

             
              <a
                onClick={() => setActiveSection("motdepasse")}
                className={`${activeSection === "motdepasse"
                  ? "nav-button-active"
                  : "nav-button-inactive"
                  } nav-link-margin`}
              >
                Changer le mot de passe
              </a>
            </div>
          </Row>
          <Row xs={12} md={9}>
            <div className="p-3">{renderSection()}</div>
          </Row>
        </Col>
      </Container>
    </>
  );
}

export default Prameter;
