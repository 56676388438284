import React, { useState, useEffect, useRef } from 'react';
import flag from "../../../images/Flag.png";
import { Col, Container, Row, Form } from 'react-bootstrap';
import "../../Student_Admin/Student.css";
import next from "../../../images/nextAdd.png";
import profile from "../../../images/inpProfil.png";
import add from "../../../images/add.png";
import { useNavigate } from 'react-router';
import CustomCountrySelectAdmin from '../../../Layout/CountrySelect';
import api from '../../api/axiosInstance';
import { success } from '../../utils/alerts';
import { error } from '../../utils/alerts';
import { Spinner } from 'react-bootstrap';
import countries from "../../../Layout/COUNTRY.json"
import findCitiesOfCountry from '../../../Layout/findCitiesOfCountry';

function StudentAdd() {
  const fileInputRef = useRef(null);
  const [codeCountry, setCodeCountry] = useState("+33");
  const [loading, setLoading] = useState(false)
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [universities, setUniversities] = useState([]);
  const [profil, setProfil] = useState(profile)
  const [data, setData] = useState({
    nom: "",
    prenom: "",
    phone: "",
    dateN: "",
    pays: "",
    gender: "",
    email: "",
    universite: "",
    school_year: "",
    town: "",
    statut: "",
    picture: ""

  })
  const [errors, setErrors] = useState({})
  const navigate = useNavigate();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, picture: file })
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfil(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [cities, setCities] = useState([]);

  const handlePays = async (e) => {
    const selectedCountry = e.target.value;
    setData({ ...data, pays: selectedCountry });
    setCities(findCitiesOfCountry(selectedCountry));
  };
  const handleBackClick = () => navigate(-1);


  async function ajouterStudent() {

    let currentErrors = {};



    const validNamePattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const phonePattern = /^\d{9,15}$/;
    const anneePattern = /^\d{4}$/;
    const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
    const anneeActuelle = new Date().getFullYear();
    const universitePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9'’\s\-]+$/;


    if (!data.nom.trim()) {
      currentErrors.nom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validNamePattern.test(data.nom)) {
      currentErrors.nom = "Le nom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.nom;
    }

    if (!data.prenom.trim()) {
      currentErrors.prenom = "Veuillez entrer votre nom (pas d'espaces vides)";
    } else if (!validPrenomPattern.test(data.prenom)) {
      currentErrors.prenom = "Le prénom ne doit contenir que des lettres et des apostrophes.";
    } else {
      delete currentErrors.prenom;
    }

    if (!data.town.trim()) {
      currentErrors.ville = "Veuillez Entrer une ville";
    } else {
      delete currentErrors.ville;
    }

    if (!data.email.trim()) {
      currentErrors.email = "Veuillez entrer une adresse e-mail valide";
    } else if (!emailPattern.test(data.email)) {
      currentErrors.email = "Adresse e-mail invalide";
    } else {
      delete currentErrors.email;
    }

    if (!data.phone.trim()) {
      currentErrors.phoneNumber = "Veuillez entrer un numéro de téléphone valide";
    } else if (!phonePattern.test(data.phone)) {
      currentErrors.phoneNumber = "Le numéro de téléphone doit contenir uniquement des chiffres";
    } else {
      delete currentErrors.phoneNumber;
    }

    if (!data.pays || data.pays === "Sélectionnez le pays") {
      currentErrors.pays = "Veuillez sélectionner un pays";
    } else {
      delete currentErrors.pays;
    }
    const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD




    if (!(data.dateN).trim()) {
      currentErrors.dateN = "Veuillez entrer une date  valide.";
    } else if (data.dateN >= today) {
      currentErrors.dateN =
        "La date de déplacement doit être dans le passe.";
    }
    else {
      delete currentErrors.dateN;
    }

    if (!data.universite.trim()) {
      currentErrors.universite = "Veuillez saisir le nom d'une université.";
    } else if (!universitePattern.test(data.universite)) {
      currentErrors.universite = "Veuillez entrer un nom d'université valide";
    }
    else {
      delete currentErrors.universite;
    }

    if (!data.gender.trim()) {
      currentErrors.gender = "Veuillez sélectionner un genre."
        ;
    } else {
      delete currentErrors.gender;
    }

    if (!data.school_year.trim()) {
      currentErrors.school_year = "Veuillez entrer une année scolaire";
    } else if (!anneePattern.test(data.school_year)) {
      currentErrors.school_year = "année scolaire invalide";
    } // else if (data.school_year < anneeActuelle) {
      // currentErrors.school_year = "L'année scolaire saisie est antérieure à l'année en cours.";
    //}
    else {
      delete currentErrors.school_year;
    }
    setErrors(currentErrors);


    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("first_name", data.prenom);
      formData.append("last_name", data.nom);
      formData.append("email", data.email);
      formData.append("country_code", codeCountry);
      formData.append("phone_number", data.phone);
      formData.append("country", data.pays);
      formData.append("date_of_birth", data.dateN);
      formData.append("gender", data.gender);
      formData.append("university", data.universite);
      formData.append("school_year", data.school_year);
      formData.append("picture", data.picture);
      formData.append("town", data.town);

      const response = await api.post("/admin/students/", formData);
      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "L'étudiant a été ajouté avec succès.";
        success(msg);
        navigate("/administrateur/etudiant");
      }

    } catch (err) {
      error("Une erreur s'est produite lors de l'ajout de l'étudiant.")
      setLoading(false);
    }


  }

  return (
    <Container>
      <Row>
        <Col>
          <span className='RouteStu'>
            <p className='routeStu'>Etudiant</p>
            <p className='RouteStuRoute'>/ Ajouter Etudiant </p>
          </span>
        </Col>
        <Col>
          <button onClick={handleBackClick} className='buttonRetour'>Retour</button>
        </Col>
      </Row>

      <Row>
        <Col className="addStu1">
          <Row>
            <Col sm={6}>
              <p className='titleEtu'>Compte Etudiant</p>
            </Col>
            <Col sm={6}>
              <span className='spanInpImg'>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  className='imgInpModif'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <img
                  src={profil}
                  alt="Cliquez pour changer l'image"
                  className="spanInpImg img-fluid img-rounded"
                  onClick={() => fileInputRef.current.click()}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
                <img src={add} className='addImg' onClick={() => fileInputRef.current.click()} style={{ cursor: 'pointer' }} />
              </span>
            </Col>
          </Row>

          {/* Formulaire */}
          <Row>
            <Col sm={6}>
              <p className='labelInp'>Nom</p>
              <input type='text' placeholder='Votre Nom' className='inpStu' onChange={(e) => setData({ ...data, nom: e.target.value })} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.nom && errors.nom
                }
              </Form.Control.Feedback>

            </Col>
            <Col sm={6}>
              <p className='labelInp'>Prenom</p>
              <input type='text' placeholder='Votre Prénom' className='inpStu' onChange={(e) => setData({ ...data, prenom: e.target.value })} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.prenom && errors.prenom
                }
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Numero de telephone</p>
              <Row>
                <Col sm={3}>
                  <CustomCountrySelectAdmin codeCountry={setCodeCountry} />
                </Col>
                <Col>
                  <input type='text' placeholder='XXX XXX XXXX' className='inpTelEtu' onChange={(e) => setData({ ...data, phone: e.target.value })} />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {
                    errors?.phoneNumber && errors.phoneNumber
                  }
                </Form.Control.Feedback>
              </Row>
            </Col>
            <Col sm={6}>
              <p className='labelInp'>Date De Naissance</p>
              <input type='date' className='inpStu' onChange={(e) => setData({ ...data, dateN: e.target.value })} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.dateN && errors.dateN
                }
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Pays</p>
              <Form.Select name="pays" defaultValue="" onChange={handlePays} >
                <option value="" disabled>
                  Sélectionnez le pays
                </option>
                {countries.map((country) => (
                  <option key={country} value={country} >
                    {country}
                  </option>
                ))}

              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {
                  errors?.pays && errors.pays
                }
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="prvilleénom">
                <Form.Label>Ville <span className='star'>*</span></Form.Label>
                <Form.Control type="text" placeholder="Entrez votre prénom" name="villeDestination" onChange={(e) => { setData({ ...data, town: e.target.value }) }} isInvalid={errors?.ville ? true : false} />
                <Form.Control.Feedback type="invalid">
                  {
                    errors?.ville && errors.ville
                  }
                </Form.Control.Feedback>
              </Form.Group>
              {/* <p className='labelInp'>Ville</p>
              {
                cities.length > 0 ?

                  <Form.Select name="villeDestination" defaultValue="" onChange={(e) => { setData({ ...data, town: e.target.value }) }} >
                    <option value="" disabled>
                      Sélectionnez la ville
                    </option>
                    {cities.length > 0 && cities.map((ville) => (
                      <option key={ville} value={ville} >
                        {ville}
                      </option>
                    ))}

                  </Form.Select> :

                  <Form.Control type="text" placeholder="Entrez votre ville" name="town" onChange={(e) => { setData({ ...data, town: e.target.value }) }} value={data.ville} />



              }               <Form.Control.Feedback type="invalid">
                {
                  errors?.ville && errors.ville
                }
              </Form.Control.Feedback> */}
            </Col>

          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Adresse Email</p>
              <input type='email' placeholder='Votre Adresse Email' className='inpStu' onChange={(e) => setData({ ...data, email: e.target.value })} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.email && errors.email
                }
              </Form.Control.Feedback>

            </Col>
            <Col sm={6}>
              <p className='labelInp'>Genre</p>
              <select className='inpStu' onChange={(e) => setData({ ...data, gender: e.target.value })}>
                <option value="">Selectionner</option>
                <option value="male">Homme</option>
                <option value="female">Femme</option>
              </select>
              <Form.Control.Feedback type="invalid">
                {
                  errors?.gender && errors.gender
                }
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <p className='labelInp'>Université</p>
              <input type='text' placeholder='Université choisie' className='inpStu' onChange={(e) => setData({ ...data, universite: e.target.value })} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.universite && errors.universite
                }
              </Form.Control.Feedback>
            </Col>

            <Col sm={6}>
              <p className='labelInp'>Année Scolaire</p>
              <input type='text' placeholder='Votre Année Scolaire' className='inpStu' onChange={(e) => setData({ ...data, school_year: e.target.value })} />
              <Form.Control.Feedback type="invalid">
                {
                  errors?.school_year && errors.school_year
                }
              </Form.Control.Feedback>
            </Col>

          </Row>

          {/* Champ "Autre Etablissement" conditionnel */}
          {selectedUniversity === "Autre" && (
            <Row>
              <Col sm={6}>
                <p className='labelInp'>Autre Etablissement</p>
                <input type='text' placeholder='Votre Etablissement' className='inpStu' />
              </Col>
            </Row>
          )}

          <Row className='rowBtnAdd'>
            <Col>
              <button className='creer' onClick={ajouterStudent}>


                {
                  !loading ? (
                    <>
                      Créer
                      <img src={next} className='imgbtnadd' />
                    </>
                  ) : (
                    <Spinner animation="border" size="md" />
                  )
                }
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default StudentAdd;