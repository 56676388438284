import './DocumentationRessource.css'
import StudyAwayImg from '../../images/Parent/service-details 1.png'
import DownloadIcon from '../../images/Parent/Group.png'
import Faq from "../Faq";
import { Container, Row } from 'react-bootstrap';
import { useRef, useState } from 'react';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function DocumentationRessource({faqData}){

    const accessHelpConnection = [
        {
            question: 'Comment je télécharge l’application Universe France success ?',
            response: "Je peux télécharger l’application Universe France success sur mon téléphone depuis l’App Store et Google Play Store. Si mon téléphone n'a pas accès à l'une de ces plateformes, je ne peux pas installer l’application."
        },
        {
            question: "Comment créer mon espace sur l’application Universe France success ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        {
            question: "L’inscription en ligne est-elle payante ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        {
            question: "Devrai-je créer un compte sur l’application Universe France success si j’ai déjà ouvert auparavant un espace sur espace.Universe France success.com ?",
            response: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt quod eveniet possimus, excepturi at ea nostrum quisquam mollitia dolorem illum officiis earum tenetur fugit dolore ratione quam nobis consectetur."
        },
        
    ]
    const paiementAccount = [
        {
            question: "Qu'est-ce qu'un compte de paiement ?",
            response: "Un compte de paiement est un type de compte qui permet de gérer des transactions financières, comme les dépôts, retraits et paiements en ligne."
        },
        {
            question: "Comment puis-je ouvrir un compte de paiement ?",
            response: "Pour ouvrir un compte de paiement, vous devez généralement fournir une pièce d'identité, une preuve de résidence et remplir un formulaire d'inscription en ligne ou en agence."
        },
        {
            question: "Y a-t-il des frais associés à mon compte de paiement ?",
            response: " Oui, des frais peuvent s'appliquer, notamment des frais de maintenance mensuels, des frais de retrait ou de transfert. Consultez les conditions de votre fournisseur pour plus de détails."
        },
        {
            question: "Comment puis-je sécuriser mon compte de paiement ?",
            response: "Pour sécuriser votre compte, utilisez un mot de passe fort, activez l'authentification à deux facteurs et surveillez régulièrement vos transactions pour détecter toute activité suspecte."
        },
        
    ]
    const chequeRemise = [
        {
            question: "Comment puis-je remettre un chèque ?",
            response: "Vous pouvez remettre un chèque en personne à votre banque ou l'envoyer par la poste. Assurez-vous de le signer au dos avant de le remettre."
        },
        {
            question: "Quels documents sont nécessaires pour remettre un chèque ?",
            response: "En général, il suffit de présenter le chèque. Si c'est un dépôt important, la banque peut demander une pièce d'identité."
        },
        {
            question: "Combien de temps faut-il pour que les fonds soient disponibles ?",
            response: "Les délais de disponibilité varient, mais généralement, les fonds d'un chèque local sont disponibles dans un à deux jours ouvrables."
        },
        {
            question: "Que faire si le chèque est perdu ou volé ?",
            response: "Si votre chèque est perdu ou volé, contactez immédiatement votre banque pour signaler la situation et demander l'annulation du chèque."
        },
        
    ]
    const virement = [
        {
            question: "Qu'est-ce qu'un virement bancaire ?",
            response: "Un virement bancaire est un transfert d'argent d'un compte bancaire à un autre, que ce soit au sein de la même banque ou entre différentes banques."
        },
        {
            question: "Quels sont les frais associés à un virement ?",
            response: "Les frais de virement peuvent varier selon les banques. Certains virements internes sont gratuits, tandis que les virements internationaux peuvent entraîner des frais."
        },
        {
            question: "Combien de temps faut-il pour que les fonds soient disponibles ?",
            response: "Un virement national peut prendre de quelques heures à un jour ouvrable. Les virements internationaux peuvent prendre plusieurs jours, selon les banques et les pays impliqués."
        },
        {
            question: "Combien de temps prend un virement ?",
            response: "Pour annuler un virement, contactez immédiatement votre banque. Si le virement n’a pas encore été traité, il est souvent possible de l’annuler."
        },
        
    ]
    const physicCardPaiement = [
        {
            question: "Comment fonctionne un paiement par carte physique ?",
            response: "Vous glissez ou tapez votre carte sur un terminal de paiement. Cela transfère les fonds."
        },
        {
            question: "Quels sont les frais associés à l'utilisation d'une carte physique ?",
            response: "Des frais peuvent s'appliquer selon la carte et le type de transaction. Vérifiez auprès de votre banque."
        },
        {
            question: "Que faire si ma carte est perdue ou volée ?",
            response: "Contactez immédiatement votre banque pour bloquer la carte. Demandez une nouvelle carte."
        },
        {
            question: "Comment sécuriser mes paiements avec une carte physique ?",
            response: "Ne partagez jamais vos codes PIN. Utilisez des terminaux fiables et surveillez vos relevés."
        },
        
    ]
    const security = [
        {
            question: "Qu'est-ce que la sécurité des données ?",
            response: "La sécurité des données protège les informations sensibles contre les accès non autorisés, les vols ou les pertes."
        },
        {
            question: "Comment puis-je renforcer la sécurité de mes comptes en ligne ?",
            response: "Utilisez des mots de passe forts, activez l'authentification à deux facteurs et changez régulièrement vos mots de passe."
        },
        {
            question: "Que faire si je reçois un e-mail suspect ?",
            response: "Ne cliquez pas sur les liens et ne téléchargez pas de fichiers. Signalez l'e-mail à votre fournisseur de services et supprimez-le."
        },
        {
            question: "Combien de temps prend un virement ?",
            response: "Quels sont les signes d'une violation de sécurité ?"
        },
        
    ]
 const [activeSection, setActiveSection] = useState("")
    const activeRef = useRef(null);

    const StudyAwaySection = ({title}) => {
        return(
            <div className={`${activeSection == title && 'studyAwaySectionTitleActive'} studyAwaySectionTitle`} onClick={()=>setActiveSection(title)}>
                {title}
            </div>
        )
    }
   


    // Fonction pour télécharger le PDF
    const pdfRef = useRef();
    // const handleDownloadPDF = async () => {
    //     const element = activeRef.current || pdfRef.current;
    //     const canvas = await html2canvas(element, { scale: 2 });
    //     const imgData = canvas.toDataURL("image/png");
      
    //     const pdf = new jsPDF("p", "mm", "a4");
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      
    //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    //     pdf.save("Documentation.pdf");
    //   };
      // Fonction pour afficher toutes les sections avant le téléchargement
const handleDownloadPDF = async () => {
    setActiveSection(""); // Désactive la sélection pour afficher toutes les sections

    const element = pdfRef.current; // Capture tout le contenu de pdfRef
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Documentation.pdf");
};

  
    return(
        <div ref={pdfRef} className='containerdOC'>
                    
            <section className="studyAwaySection">
                <div className="studyAwaySectionDetails">
                    <h3 className="studyAwaySectionTitle">Étudier à l'étranger</h3>
                    <h5 className="studyAwaySectionSubtitle">Aperçu des visas</h5>
                    <p className="studyAwaySectionContent">Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                </div>
                <div className="studyAwaySectionImgCard">
                    <img src={StudyAwayImg} alt="" className="studyAwaySectionImg"/>
                </div>
            </section>


            <section className="studyAwaySections">
                <StudyAwaySection title={'Entrer et sortir du pays'}/>
                <StudyAwaySection title={'Citoyennete du pays'}/>
                <StudyAwaySection title={'Installation dans le pays'}/>
                <StudyAwaySection title={'Processus rapide et facile'}/>
                <StudyAwaySection title={'Documents et paiements'}/>
                <StudyAwaySection title={'Recevez votre visa'}/>
            </section>

            <section className="StudyAwaySectionDownloadFile" onClick={handleDownloadPDF}>
                <img src={DownloadIcon} alt="download" className="StudyAwaySectionDownloadImg" />
                <span>Télécharger le fichier PDF</span>
            </section>

            <section className="parentDocumentationRessourceFAQ">
                <h4 className='Question'>Questions fréquemment posées</h4>

                {
                    (activeSection == '' || !activeSection) && <Faq data={faqData}/>
                }

                {
                    activeSection == "Entrer et sortir du pays" && <Faq data={accessHelpConnection}/>
                }
                {
                    activeSection == "Citoyennete du pays" && <Faq data={paiementAccount}/>
                }
                {
                    activeSection == "Installation dans le pays" && <Faq data={chequeRemise}/>
                }
                {
                    activeSection == "Processus rapide et facile" && <Faq data={virement}/>
                }
                {
                    activeSection == "Documents et paiements" && <Faq data={physicCardPaiement}/>
                }
                {
                    activeSection == "Recevez votre visa" && <Faq data={security}/>
                }
                
            </section>
        </div>
    )
}
    