import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../Container/SideBar2';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import bg from "../../images/dash.png";
import profil from "../../images/Ellipse 806.png";
import Navbar from '../Layouts/Navbar';
import "./style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { error } from '../utils/alerts';

const Layout = () => {

  const navigate = useNavigate();

  useEffect(() => {
  
    const accessToken = localStorage.getItem("accessToken");
    const role = localStorage.getItem("role");
    if (!accessToken) {
        navigate("/etudiant/login");
    }
    if (role !== 'student') {
      error("vous avez pas la permission pour cette page");
      navigate("/etudiant/login");

    }


}, [navigate]);


  const location = useLocation();
  const handleContact = () => {
    navigate('/etudiant/contact');
  };
  
  const handlePasswordChange = () => {
    navigate('/etudiant/parametre/motdepasse'); // Navigate to the Password Change section
  };
  const [navHeading, setNavHeading] = useState('');
  const updateHeading = (newHeading) => {
    setNavHeading(newHeading);
  };

  // Determine whether to show FAQ or Messages/Help based on the current route
  const showFAQ = location.pathname === '/etudiant/faq';
  
  const showMessagesAndHelp = [
    '/etudiant/notification',
    '/etudiant/messages',
    '/etudiant/support-technique/help'
  ].includes(location.pathname);

  
  return (<>
    {/* <div
    style={{
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      position: "fixed",
      overflowY: "scroll",
      overflowX: 'hidden'

    }}>
    <Row>

      <Col xl={3} lg={1} md={0} className="mt-5 mb-5 sidebarIndex p-0">
      <Sidebar updateHeading={updateHeading} />
      </Col>

      <Col lg={7}  className="mt-5 mr-3 main-content-part">
              <Row >

                     <Col xl={12} lg={12} md={12} sm={12} xs={12} >
                     <Navbar title={navHeading}/> 
                      <Outlet />
                    </Col>
                    
                </Row>
       
      </Col>
    </Row>
  </div>  */}
  
  <Container 
  fluid
  style={{
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    position: "fixed",
    overflowY: "scroll",
    overflowX: 'hidden'

  }}
>
  <Row>
    <Col  lg={3} md={0} className="mt-5 mb-5 sidebarIndex p-0">
      <Sidebar updateHeading={updateHeading} />
    </Col>

    <Col  lg={9}  className="mt-5 main-content-part">
      <Row>
        <Col>
        <Navbar title={navHeading}/> 
        </Col>
      </Row>
      <Row className='outletanime'>
      <Outlet />
      </Row>
    </Col>
  </Row>
</Container>
</>
  );
};

export default Layout;