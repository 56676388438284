import React, { useEffect, useState } from "react";
import "./content.css";
import img_salutation from "../assets/image/Frame_1000005263.png";
import img_parent from "../assets/image/Ellipse 806.png";
import img_notification from "../assets/image/Wavy_Gen-01_Single-02-ai 1.png";
import { Col, Row } from "react-bootstrap";
import hand from "../assets/hand.png"
import abonne from "../assets/abonne.png"
import { success, error } from "../utils/alerts";
import api from "../api/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import sanitizeImageUrl from "../../StudentModule/utils/anitizeImageUrl";
import PaymentHistoric from "./PaymentHistoric/PaymentHistoric";

const Content = () => {
  const [profile, setProfile] = useState({});
  const [profileImage, setProfileImage] = useState('');

  const [firstname, setFirstname] = useState(localStorage.getItem("firstname"));
  const [lastname, setLastname] = useState(localStorage.getItem("lastname"));
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfile();
  }, []);
  const fetchProfile = async () => {
    try {
      const response = await api.get('/parent/profile/');
      setProfile(response.data);
      setFirstname(response.data.first_name);
      setLastname(response.data.last_name);
      setProfileImage(sanitizeImageUrl(response.data.picture));
    } catch (error) {
      console.error('Erreur lors de la récupération du profil:', error);
    }
  };

  const handleMarkNotificationsAsRead = async () => {
    try {
      await api.put('/chat/notifications/'); // Assuming this endpoint marks notifications as read
      navigate("/parent/notification");
     
    } catch (err) {
      navigate("/parent/notification");
    }
  };

  const handleLogout = async () => {
    try {
      const refreshToken = window.localStorage.getItem("refreshToken");
      const response = await api.post("logout/", { refresh: refreshToken });
      if (response.status === 204) {
        window.localStorage.clear();
        success("Déconnecté avec succès");
        navigate("/parent/login");
      }
    } catch (err) {
      error("Échec de la déconnexion");
    }
  };
const [notificationCount, setNotificationCount] = useState(0);
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await api.get('/chat/notifications/');
        const unreadNotifications = response.data.filter(notification => !notification.is_read);
       // Set the count of unread notifications
       setNotificationCount(unreadNotifications.length);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
    fetchNotifications();
   }, []);


   const [serviceAbonnement, setServiceAbonnement] = useState({
    servicesAbon: 0,
    studentName: "",
  });

  useEffect(() => {
    const fetchServiceAbonnement = async () => {
      try {
        const response = await api.get('/parent/service_abo/');
        const { services_abon, student_names } = response.data;
        setServiceAbonnement({
          servicesAbon: services_abon,
          studentName: student_names,
          
        });
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchServiceAbonnement();
  }, []);
  

  return (
    <div className="mt-2 animParent">
      <div className="newRow salutation_profile">
        <div className="salutation newRow ">
          <Col className="text_salutation">
            <span className=" ">
              <span className="hederParentDash">
                <h2 className="Titleparent ">
                  Bonjour {firstname}
                </h2>
                <span>
                  <img src={hand} className="handTitle" />
                </span>

              </span>

              {
  profile && (
    <p className="mt-0 bonne_journée">
      {profile.completion_message}
    </p>
  )
}
            </span>
          </Col>
          <Col className="secondParentCol">
            <span>
              <img
                src={img_salutation}
                alt="img_salutation"
                className=" imgFamill"
              />
            </span>
          </Col>
        </div>
        <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
          <div className="dashboad-student-profil-container">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <img
                  src={profileImage ||img_parent}
                  alt="profile"
                  className="profile-picture   "
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <p className="student-dashboard-nom">
                  {firstname} {lastname}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <button
                  onClick={handleLogout}
                  className="dashboard-student-deconnecter"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.42 4.08L11.3325 5.1675C11.9933 5.56548 12.5401 6.12745 12.9199 6.79892C13.2996 7.47039 13.4995 8.22858 13.5 9C13.5 10.1935 13.0259 11.3381 12.182 12.182C11.3381 13.0259 10.1935 13.5 9 13.5C7.80653 13.5 6.66194 13.0259 5.81802 12.182C4.97411 11.3381 4.5 10.1935 4.5 9C4.5 7.3725 5.37 5.955 6.66 5.16L5.58 4.08C4.78338 4.62955 4.1323 5.36452 3.68285 6.22161C3.2334 7.07871 2.99905 8.03221 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 6.96 13.98 5.16 12.42 4.08ZM9.75 2.25H8.25V9.75H9.75"
                      fill="white"
                    />
                  </svg>{" "}
                  déconnecter
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </div>

      <div className="d-flex gap-3 mt-4 displaynone">
        
        <div
          className="col notification p-4"

        >
          <div className="d-flex align-items-center gap-5 p-4"  onClick={handleMarkNotificationsAsRead}
              style={{ cursor: "pointer", textDecoration: "none" }}>
            <div className="text_notification_and_number">
              <p className="text_notification">Notifications</p>
              <p className="number">{notificationCount > 100 ? "99+" : notificationCount}</p>
            </div>
            <div className="img_notification">
              <img src={img_notification} alt="img_notification" />
            </div>
          </div>
        </div>
      </div>
      <div className=" notification_services newRow mt-4">
        <div
          className="col notification notification-second"

        >
          <div className="d-flex align-items-center gap-5 p-4"  onClick={handleMarkNotificationsAsRead}
              style={{ cursor: "pointer", textDecoration: "none" }}>
            <div className="text_notification_and_number">
              <p className="text_notification">Notifications</p>
              <p className="number">{notificationCount > 100 ? "99+" : notificationCount}</p>
            </div>
            <div className="img_notification">
              <img src={img_notification} alt="img_notification" />
            </div>
          </div>
        </div>
        <div className=" services_abonnés">
          <Row>
            <Col className="leftAbonne">
              <div className=" NotifParentDash">
                <div className="text_services_abonnés col-6 ms-3 d-flex justify-content-center">
                  <h2 className="title_services">Services abonnés</h2>
                  <p className="content_services">
                  {serviceAbonnement.studentName} a souscrit à {serviceAbonnement.servicesAbon} services.
                  </p>
                </div>

              </div>
            </Col>
            <Col className="abonneCol">
              <img src={abonne} className="abonne" />
            </Col>
          </Row>
        </div>
      </div>
      <div className="text_services">
        <h2 className="text-left">Services abonnés</h2>
      </div>

      <div
        className="tableau_services"
      >

        <div className="mb-4">
          <PaymentHistoric />

        </div>
      </div>
    </div>
  );
};

export default Content;
