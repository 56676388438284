import React, { useEffect, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import Pagination from '../../Components/Pagination';
import './Dash.css';
import action from '../../images/action.png';
import imageTab from '../../images/profildashtab.png';
import down from '../../images/down.png';
import { error, success } from '../utils/alerts';
import api from '../api/axiosInstance';
import sanitizeImageUrl from '../../StudentModule/utils/anitizeImageUrl';

function TableDash() {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [studentsData, setStudentsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5); // Par défaut à 5 éléments
    const [itemsPages, setItemsPages] = useState({});
    const [loading, setLoading] = useState(true);

    // Charger les données des étudiants
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await api.get('/admin/students/');
                setStudentsData(response.data.students);
                setSelectedStudents(Array(response.data.students.length).fill(false));
                setLoading(false);
            } catch (err) {
                error('Erreur lors de la récupération des données.');
                setLoading(false);
            }
        };

        fetchStudents();
    }, []);

    // Gérer la pagination
    useEffect(() => {
        const totalPages = Math.ceil(studentsData.length / itemsPerPage);
        const newItemsPages = {};

        for (let index = 1; index <= totalPages; index++) {
            const start = (index - 1) * itemsPerPage;
            const end = start + itemsPerPage;
            newItemsPages[index] = studentsData.slice(start, end);
        }

        setItemsPages(newItemsPages);
    }, [studentsData, itemsPerPage]);

    const handleSelectAll = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        setSelectedStudents(Array(studentsData.length).fill(newSelectAll));
    };

    const handleSelectStudent = (index) => {
        const newSelectedStudents = [...selectedStudents];
        newSelectedStudents[index] = !newSelectedStudents[index];
        setSelectedStudents(newSelectedStudents);

        if (newSelectedStudents.includes(false)) {
            setSelectAll(false);
        } else {
            setSelectAll(true);
        }
    };

    return (
        <>
            <Table responsive className="tableDash">
                <thead>
                    <tr className="trHead">
                        <th className="thDash etuTab">
                            <span className="thDash-stu">
                                <input
                                    type="checkbox"
                                    className="inpStuTab"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                Étudiant
                                <img className="downTabl" src={down} alt="dropdown" />
                            </span>
                        </th>
                        <th className="thDash emailTab">Email ID</th>
                        <th className="thDash numTab">Numéro de Téléphone</th>
                        <th className="thDash numTab">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="4" style={{ textAlign: 'center' }}>
                                Chargement des données...
                            </td>
                        </tr>
                    ) : (
                        itemsPages[currentPage]?.map((val, index) => (
                            <tr key={index} className="trDash">
                                <td className="textTabDashname">
                                    <input
                                        type="checkbox"
                                        className="inpStuTab"
                                        checked={selectedStudents[index]}
                                        onChange={() => handleSelectStudent(index)}
                                    />
                                    {/* <img src={imageTab} className="imgDashTab" alt="profile" /> */}
                                    <img src={val.picture ? sanitizeImageUrl(val.picture) : imageTab} className='etuimg img-fluid img-rounded' />

                                    {val.first_name} {val.last_name}
                                </td>
                                <td className="textTabDash">{val.email}</td>
                                <td className="textTabDash">{val.phone_number}</td>
                                <td className="textTabDash">{val.country}</td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>

            <Pagination
                itemsPages={itemsPages}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
}

export default TableDash;
