import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import pro from '../../images/prodash.png'
// Importing images
import notif from "../../images/notifi.png";
import bg from "../../images/dash.png";
import { error } from '../utils/alerts';
// Importing components
import Sidebar from '../Container/Sidebar';

// Importing styles
import "./style.css";
import api from '../api/axiosInstance';
import { success } from '../utils/alerts';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [navHeading, setNavHeading] = useState('Tableau de bord');
  const [show, setShow] = useState(false);

  const updateHeading = (newHeading) => {
    setNavHeading(newHeading);
  };


   useEffect(() => {
  
          const accessToken = localStorage.getItem("accessToken");
          const role = localStorage.getItem("role");
          if (!accessToken) {
              navigate("/etudiant/login");
          }
          if (role !== 'admin') {
            error("vous avez pas la permission pour cette page");
            navigate("/administrateur/login");

          }
    

      }, [navigate]);

  const handleNotif = () => {
    navigate('/administrateur/notification');
  };

  const toggleDropdown = () => {
    setShow((prevShow) => !prevShow);
  };

 async function logout(){
  try {
    const refreshToken = window.localStorage.getItem("refreshToken");
    const response = await api.post("logout/", { refresh: refreshToken });
    if (response.status === 204) {
      window.localStorage.clear();
      success("Déconnecté avec succès");
      navigate("/administrateur/login");
    }
  } catch (err) {
    error("Échec de la déconnexion");
  }

  }

  return (
    <Container 
      fluid
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        position: "fixed",
        overflowY: "scroll",
        overflowX: 'hidden'
      }}
    >
      <Row>
        <Col lg={3} md={0} className="mt-5 mb-5 sidebarIndex p-0">
          <Sidebar updateHeading={updateHeading} />
        </Col>

        <Col lg={9} className="mt-5 main-content-part">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <section className='header'>
                <h3 className='titleDashH'>{navHeading}</h3>
                <div className='other-navigation'>
                  <Row>
                    <div className="profileDiv">
                      <Col>
                        <img onClick={handleNotif}
                             src={notif}
                             alt="Notification"
                             className="profile-imgI"
                        /> 
                      </Col>
                      <Col>
                        <Dropdown show={show} onToggle={toggleDropdown} className='drop-profil'>
                          <Dropdown.Toggle as="div" onClick={toggleDropdown} id="dropdown-basic" style={{ cursor: 'pointer',width:"10px" }}>
                            
                            <img
                              src={pro}
                              alt="Profile"
                              className="profile-imgI1"
                            /> 
                           
                          </Dropdown.Toggle>
                          <Dropdown.Menu >
                            <Dropdown.Item onClick={() => navigate('/administrateur/profile')}>Profile</Dropdown.Item>
                            <Dropdown.Item  onClick={() => navigate('/administrateur/parametres/details-generals')}>Parametre</Dropdown.Item>
                            <Dropdown.Item  onClick={logout}>Se déconnecter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </div>
                  </Row>
                </div>
              </section>
            </Col>
          </Row>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
