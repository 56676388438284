import './Dash.css';
import { Col, Container, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import Chart1 from './ChartHeader/Chart1';
import Chart2 from './ChartHeader/Chart2';
import Chart3 from './ChartHeader/Chart3';
import Chart4 from './secondChart/Chart4';
import Chart5 from './secondChart/Chart5';
import TableDash from './TableDash';
import api from "../api/axiosInstance";
import React, { useEffect, useState } from 'react';
import { error ,success} from "../utils/alerts";
import DatePicker from 'react-datepicker';

function Tableau_boardAdm() {
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0); // État pour le total des montants
  const [payments, setPayments] = useState([]); // État pour les paiements
  const [selectedDate, setSelectedDate] = useState(new Date()); // État pour la date sélectionnée
  const [selectedYear, setSelectedYear] = useState(() => {
    // Récupérer l'année sélectionnée depuis le localStorage si elle existe, sinon utiliser l'année actuelle
    const storedYear = localStorage.getItem('selectedYear');
    return storedYear ? parseInt(storedYear, 10) : new Date().getFullYear();
  });

  const [selectDash, setselectDash] = useState(() => {
    // Récupérer l'année sélectionnée depuis le localStorage si elle existe, sinon utiliser l'année actuelle
    const storedYear = localStorage.getItem('selectDash');
    return storedYear ? parseInt(storedYear, 10) : new Date().getFullYear();
  });

  // Fonction pour récupérer les paiements et filtrer par année
  const fetchPaymentsData = async () => {
    try {
      const response = await api.get("/admin/payments/");
      const paymentsData = response.data;

      if (Array.isArray(paymentsData)) {
        // Filtrer les paiements par année
        const filteredPayments = paymentsData.filter((payment) => {
          // Extraire l'année de la date
          const paymentYear = new Date().getFullYear();
          return paymentYear === selectedYear; // Comparer l'année de la date de paiement avec l'année sélectionnée
        });

        // Calculer la somme des montants pour les paiements filtrés
        const total = filteredPayments.reduce((sum, payment) => sum + parseFloat(payment.amount || 0), 0);
        setTotalAmount(total);
        setPayments(filteredPayments); // Mettre à jour la liste des paiements filtrés

        // Sauvegarder les paiements et le total dans le localStorage
        localStorage.setItem('payments', JSON.stringify(filteredPayments));
        localStorage.setItem('totalAmount', total.toString());
      } else {
        console.error("La réponse de l'API n'est pas un tableau.");
      }

      setLoading(false);
    } catch (err) {
      error("Erreur lors de la récupération des données.");
      setLoading(false);
    }
  };

  // Utilisation de useEffect pour récupérer les données chaque fois que l'année sélectionnée change
  useEffect(() => {
    // Sauvegarder l'année sélectionnée dans le localStorage
    localStorage.setItem('selectedYear', selectedYear);
    fetchPaymentsData(); // Récupérer les données pour l'année sélectionnée
  }, [selectedYear]); // Cela se déclenche chaque fois que l'année sélectionnée change
  useEffect(() => {
    localStorage.setItem('selectDash', selectDash);
  }, [selectDash]);

  return (
    <div className='DashAnim'>
      <Row className='rowHeaderDash'  >
        <Col sm={3}  className='headerDash'>
          <div className='chartRes'>
            <Chart1/>
          </div>
        </Col>
        <Col sm={3} className='headerDash'>
          <Chart2/>
        </Col>
        <Col sm={3} className='headerDash'>
          <Chart3/>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className='Chart3'>
              <div className='titleChart1'>
                    <p className='titleDash'>Performance globale</p>
                    <select className='selectDash'
                      value={selectDash}
                      onChange={(e) => setselectDash(Number(e.target.value))}
                    >
                      {[2024, 2025,2026,2027,2028,2029,2030].map((year) => (
                        <option key={year} value={year}> {year} </option>
                      ))}
                    </select>
                </div>
                <Chart4 selectDash={selectDash} />
        </Col>
        <Col sm={3} className='chart4Col Chart4'>
                <Col>
                <div className="chart2div">
              <div className="divChart2">
                <p className="pChart2">Gagner du profit</p>
                <select
                  className="selectYear"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))} // Mettre à jour l'année sélectionnée
                >
                  {[2025,2026,2027,2028,2029,2030].map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <p className="numChart2">{totalAmount} €</p>
            </div>
                <Chart5  />
                </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className='tableDash1'>
          <TableDash/>
        </Col>
      </Row>
    </div>
  );
}

export default Tableau_boardAdm;
