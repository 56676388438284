import React from "react";
import './help.css';
import { useNavigate } from 'react-router-dom';
import { Accordion, Container } from "react-bootstrap";
const Reasons = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/etudiant/support-technique/chat');
  };
  return (

    <div className="reasons animParent container-fluid mt-4">
      <h2 className='title-conv text-center'>Choisir les raisons</h2>

      <Accordion className="reasons-accordion" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Comment puis-je effectuer le paiement pour le plan ?</Accordion.Header>
          <Accordion.Body>
            <div className="reasons-accordion-button" onClick={handleRedirect}>Discutez avec nous</div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Comment puis-je ajouter une carte pour le paiement ?</Accordion.Header>
          <Accordion.Body>
            <div className="reasons-accordion-button" onClick={handleRedirect}>Discutez avec nous</div>

          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Question relative au paiement et à la facturation</Accordion.Header>
          <Accordion.Body>
            <div className="reasons-accordion-button" onClick={handleRedirect}>Discutez avec nous</div>

          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>J'ai une question concernant un coupon pour cette commande.</Accordion.Header>
          <Accordion.Body>
            <div className="reasons-accordion-button" onClick={handleRedirect}>Discutez avec nous</div>

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className='div-footer text-center'>
        <h2 className='title-conv'>Chat direct avec notre support</h2>
        <button onClick={handleRedirect}>Discutez avec nous</button>
      </div>

    </div>

  );
}
export default Reasons;