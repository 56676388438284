import { React, useState } from "react";
import DownloadFileIcon from "../../images/Frame.png";
import FileUpload from "../Layouts/FileUpload";

export default function FileToDownload({titre, acceptanceText,fichier,setFichier,  errorMessage = "",...props }) {

    
  const [label, setLabel] = useState("Télécharger");

    return(
        <div className='documentATelecharger'    {...props}>
            <h5 className='documentATelechargerTitre'>{titre}</h5>
            <div className='documentATelechargerCarte'>
                
                <FileUpload setLabel={setLabel} label={label} acceptedTypes=".png, .jpeg, .pdf"  existingFile={fichier}  setExistingFile={setFichier}/>

                <div className='documentATelechargerConsigne'>
                    <p>Aperçu du fichier</p>
                </div>
            </div>
          
            {errorMessage && (
                
          <div className="invalid-feedback">{errorMessage}</div>
        )}
        </div>
    )
}
