import React, { useEffect, useState } from "react";
// import './RechercheLogement.css'
// import { PersonnalInfos } from "../RechercheLogement/RechercheLogement";
import FileToDownload from "../FileToDownload";
import SelectLabel from "../../../Components/SelectLabel";
import InputLabel from "../../../Components/InputLabel";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";
import api from "../../api/axiosInstance";
import findCitiesOfCountry from "../../../Layout/findCitiesOfCountry";
import countries from "../../../Layout/COUNTRY.json";
// import nationalities from "../../Layouts/nationalities.json";
import { success, error } from "../../utils/alerts";
import extractError from "../../utils/extractError";

import CustomCountrySelect from "../../Layouts/CountrySelect";
import { Spinner } from "react-bootstrap";
import sanitizeImageUrl from "../../utils/anitizeImageUrl";

const ErrorContext = React.createContext();

export default function ServiceBilleterie() {

  const getLocalStorageItem = (key, defaultValue = "") => localStorage.getItem(key) || defaultValue;

  const [data, setData] = useState({
    firstname: getLocalStorageItem("firstname"),
    lastname: getLocalStorageItem("lastname"),
    phone: getLocalStorageItem("phone"),
    birthPlace: getLocalStorageItem("birthPlace"),
    birthDate: getLocalStorageItem("birthDate"),
    email: getLocalStorageItem("email"),
    nationality: getLocalStorageItem("nationality"),
    codeCountry: getLocalStorageItem("codeCountry"),
    departureCountry: "",
    departureCity: "",
    destinationCountry: "",
    destinationCity: "",
    departureDate: "",
    returnDate: "",
    travelClass: "",
    preferedAirline: "",
    specialNeeds: "",
    numberOfPassengers: "",

    // Files
    passport: null,
    documentAdditionel: null,
    payementPreuve: null,
    preuveAcceptance: null

  });
  const {
    departureCity,
    destinationCity,

  } = data;

  const [villesDepart, setVillesDepart] = useState([]);
  const [villesArrivee, setVillesArrivee] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const updateField = (e) => {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  };




  const navigate = useNavigate();

  function renameFile(file, newName) {
    return new File([file], newName, { type: file.type });
  }

  function updatePassport(value) {
    setData({ ...data, passport: value })
  }
  function updateDocument(value) {
    setData({ ...data, documentAdditionel: value })
  }
  function updatePayement(value) {
    setData({ ...data, payementPreuve: value })
  }
  function updateAcceptance(value) {
    setData({ ...data, preuveAcceptance: value })
  }


  // Load cities when a country is selected
  const handleCitiesOfSelectedCountry = async (e, state) => {
    const countryName = e.target.value;
    if (state === 'depart')
      setVillesDepart(findCitiesOfCountry(countryName))
    else if (state === 'arrivee')
      setVillesArrivee(findCitiesOfCountry(countryName))

  };


  useEffect(() => {
    console.log("local", getLocalStorageItem("nationality"));

    // Fetch existing ticketing data
    const fetchTicketingData = async () => {
      try {
        const response = await api
          .get("/student/ticketing/")
          .then((res) => res.data);

        setData({
          firstname: response.first_name || 'test',
          lastname: response.last_name,
          phone: response.phone_number,
          birthPlace: response.place_of_birth,
          birthDate: response.date_of_birth,
          email: response.email,
          nationality: response.nationality,
          codeCountry: response.country_code || "",
          departureCountry: response.departure_country,
          departureCity: response.departure_city,
          destinationCountry: response.destination_country,
          destinationCity: response.destination_city,
          departureDate: response.departure_date,
          returnDate: response.return_date,
          numberOfPassengers: response.number_of_passengers,
          travelClass: response.travel_class,
          preferredAirlines: response.preferred_airlines,
          specialRequests: response.special_requests,
          passport: sanitizeImageUrl(response.files.passport_copy) || null,
          documentAdditionel: sanitizeImageUrl(response.files.additional_pdf) || null,
          payementPreuve: sanitizeImageUrl(response.files.payment_proof) || null,
          preuveAcceptance: sanitizeImageUrl(response.files.preliminary_acceptance) || null,
        });
      } catch (error) {
        console.error("Error fetching ticketing data:", error);
      }
    };




    fetchTicketingData();
  }, []);

  // Handle submission of ticketing form (POST or PUT)
  const handleTicketingSubmit = async (event) => {
    event.preventDefault();
    let currentErrors = {};



    if (!data.passport || data.passport < 1) {
      currentErrors.passport = "Vous devez fournir un fichier pour la preuve de copie du passeport.";
    } else {
      delete currentErrors.passport;
    }

    if (!data.documentAdditionel || data.documentAdditionel < 1) {
      currentErrors.documentAdditionel = "Vous devez fournir un fichier pour la preuve de document additionnel.";
    } else {
      delete currentErrors.documentAdditionel;
    }

    if (!data.payementPreuve || data.payementPreuve < 1) {
      currentErrors.payementPreuve = "Vous devez fournir un fichier pour la preuve de preuve de paiement.";
    } else {
      delete currentErrors.payementPreuve;
    }

    if (!data.preuveAcceptance || data.preuveAcceptance < 1) {
      currentErrors.preuveAcceptance = "Vous devez fournir un fichier pour la preuve d'accord préliminaire.";
    } else {
      delete currentErrors.preuveAcceptance;
    }



    const validNomPattern = /^[A-Za-z\- ]+$/;
    const validPrenomPattern = /^[A-Za-z\- ]+$/;
    const validVillePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/;

    const datePattern = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    const countryPattern = /^[a-zA-ZÀ-ÿ_\s-]+$/;
    const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD

    if (!data.lastname.trim()) {
      currentErrors.lastname = "Veuillez entrer votre nom (pas d'espaces vides).";
    } else if (!validNomPattern.test(data.lastname)) {
      currentErrors.lastname =
        "Le nom ne doit contenir que des lettres, des espaces et des tirets.";
    } else {
      delete currentErrors.lastname;
    }

    if (!data.firstname.trim()) {
      currentErrors.firstname =
        "Veuillez entrer votre prénom (pas d'espaces vides).";
    } else if (!validPrenomPattern.test(data.firstname)) {
      currentErrors.firstname =
        "Le prénom ne doit contenir que des lettres, des espaces et des tirets.";
    } else {
      delete currentErrors.firstname;
    }

    if (!data.birthPlace.trim()) {
      currentErrors.birthPlace = "Veuillez entrer votre lieu de naissance.";
    } else if (!validVillePattern.test(data.birthPlace)) {
      currentErrors.birthPlace =
        "Le lieu de naissance ne doit contenir que des lettres, des espaces, des apostrophes ou des tirets.";
    } else {
      delete currentErrors.birthPlace;
    }

    if (!String(data.birthDate).trim()) {
      currentErrors.birthDate = "Veuillez entrer une date de départ valide.";
    } else if (!datePattern.test(data.birthDate)) {
      currentErrors.birthDate =
        "La date de départ doit être au format AAAA-MM-JJ.";
    } else if (data.birthDate >= today) {
      currentErrors.birthDate =
        "La date de déplacement doit être dans le futur.";
    }
    else {
      delete currentErrors.birthDate;
    }
    

    if (!data.departureCountry.trim()) {
      currentErrors.departureCountry =
        "Veuillez entrer le pays de départ .";
    } else {
      delete currentErrors.departureCountry;
    }

    if (!String(data.departureCity).trim()) {
      currentErrors.departureCity =
        "Veuillez entrer la ville de départ .";
    } else {
      delete currentErrors.departureCity;
    }


    if (!String(data.destinationCountry).trim()) {
      currentErrors.destinationCountry =
        "Veuillez entrer la ville de départ .";
    } else {
      delete currentErrors.destinationCountry;
    }



    if (!String(data.destinationCity).trim()) {
      currentErrors.destinationCity =
        "Veuillez entrer la ville de départ.";
    } else {
      delete currentErrors.destinationCity;
    }


    if (!data.nationality.trim()) {
      currentErrors.nationality =
        "Veuillez entrer votre nationalité (pas d'espaces vides).";
    } else if (!countryPattern.test(data.nationality)) {
      currentErrors.nationality =
        "La nationalité ne doit contenir que des lettres, des espaces et des tirets.";
    } else {
      delete currentErrors.nationality;
    }

    const oneYearAgo = new Date(new Date().getTime() - 31536000000).toISOString().split("T")[0]; // Format YYYY-MM-DD
    // if (data.movingDate <= today) {
    //   currentErrors.movingDate =
    //     "La date de déplacement doit être dans le futur.";
    // } else if (data.movingDate < oneYearAgo) {
    //   currentErrors.movingDate =
    //     "La date de déplacement doit être dans le futur.";
    // }
    if (!String(data.departureDate).trim()) {
      currentErrors.departureDate = "Veuillez entrer une date de départ valide.";
    } else if (!datePattern.test(data.departureDate)) {
      currentErrors.departureDate =
        "La date de départ doit être au format AAAA-MM-JJ.";
    } else if (data.departureDate < oneYearAgo) {
      currentErrors.departureDate =
        "La date de déplacement doit être dans le futur.";
    }
    else if (data.departureDate <= today) {
      currentErrors.departureDate =

        "La date de déplacement doit être dans le futur.";
    }
    else {
      delete currentErrors.departureDate;
    }

    if (!String(data.returnDate).trim()) {
      currentErrors.returnDate = "Veuillez entrer une date de retour valide.";
    } else if (!datePattern.test(data.returnDate)) {
      currentErrors.returnDate =
        "La date de retour doit être au format AAAA-MM-JJ.";
    } else if (data.returnDate <= data.departureDate) {

      currentErrors.returnDate =
        "La date de retour doit être au supérieur de date de depart..";
    }

    else {
      delete currentErrors.returnDate;
    }

    if (!String(data.numberOfPassengers).trim()) {
      currentErrors.numberOfPassengers =
        "Veuillez entrer le nombre de passagers.";
    } else if (isNaN(data.numberOfPassengers) || data.numberOfPassengers <= 0) {
      currentErrors.numberOfPassengers =
        "Le nombre de passagers doit être un nombre supérieur à 0.";
    } else {
      delete currentErrors.numberOfPassengers;
    }

    if (!String(data.travelClass).trim()) {
      currentErrors.travelClass = "Veuillez entrer la classe de voyage.";
    } else {
      delete currentErrors.travelClass;
    }


    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }


    setErrors(currentErrors);

    if (Object.keys(currentErrors).length > 0) {
      return;
    }

    try {
      setLoading(true);


      const formData = new FormData(event.target);
      formData.append("first_name", data.firstname);
      formData.append("last_name", data.lastname);
      formData.append("phone_number", data.phone);
      formData.append("place_of_birth", data.birthPlace);
      formData.append("date_of_birth", data.birthDate);
      formData.append("email", data.email);
      formData.append("nationality", data.nationality || "");
      formData.append("country_code", data.codeCountry);
      formData.append("departure_country", data.departureCountry);
      formData.append("departure_city", data.departureCity);
      formData.append("departure_date", data.departureDate);
      formData.append("destination_country", data.destinationCountry);
      formData.append("destination_city", data.destinationCity);
      formData.append("return_date", data.returnDate);
      formData.append("number_of_passengers", data.numberOfPassengers);
      formData.append("travel_class", data.travelClass);
      formData.append("preferred_airlines", data.preferredAirlines || "");
      formData.append("special_requests", data.specialRequests || "");

      // files

      if (data.preuveAcceptance && data.preuveAcceptance.name) {
        const newName = data.preuveAcceptance.name.slice(0, 95) + data.preuveAcceptance.name.slice(-5);
        const renamedFile = renameFile(data.preuveAcceptance, newName);
        formData.append("files.preliminary_acceptance", renamedFile);
      }

      if (data.passport && data.passport.name) {
        const newName = data.passport.name.slice(0, 95) + data.passport.name.slice(-5);
        const renamedFile = renameFile(data.passport, newName);
        formData.append("files.passport_copy", renamedFile);
      } else {
        console.error("Passport File est invalide ou vide.");
      }

      if (data.documentAdditionel && data.documentAdditionel.name) {
        const newName = data.documentAdditionel.name.slice(0, 95) + data.documentAdditionel.name.slice(-5);
        const renamedFile = renameFile(data.documentAdditionel, newName);
        formData.append("files.additional_pdf", renamedFile);
      } else {
        console.error("Document Additionnel File est invalide ou vide.");
      }
      if (data.payementPreuve && data.payementPreuve.name) {
        const newName = data.payementPreuve.name.slice(0, 95) + data.payementPreuve.name.slice(-5);
        const renamedFile = renameFile(data.payementPreuve, newName);
        formData.append("files.payment_proof", renamedFile);
      } else {
        console.error("Paiement Proof File est invalide ou vide.");
      }


      // Create new ticketing information
      const response = await api.post("/student/ticketing/", formData);
      if (response.status >= 200 && response.status < 300) {
        let msg = response.data?.data?.message || "Le service de billetterie a été mis à jour avec succès.";
        success(msg);
        setLoading(false)
      }
    } catch (err) {
      console.error("Une erreur est survenue:", err);
      if (err.response && err.response.data?.data) {
        const errorObject = err.response.data.data;
        const errorMessages = extractError(errorObject);
        errorMessages.forEach((message) => error(message));
        setLoading(false)
      } else {
        error("Erreur inattendue lors de la soumission.");

        setLoading(false)
        console.log(err.message)
      }
    } finally {
      setLoading(false)
    }
  };
  const validNomPattern = /^[A-Za-z\- ]+$/;
  const validateField = (name, value) => {
    switch (name) {
      case "departureCountry":
        if (!value.trim()) {
          return "Veuillez entrer votre pays de départ (pas d'espaces vides)";
        }
        break;

      case "departureCity":
        if (!value.trim()) {
          return "Veuillez entrer votre ville de départ (pas d'espaces vides)";
        }
        break;

      case "destinationCountry":
        if (!value.trim()) {
          return "Veuillez entrer votre pays de destination (pas d'espaces vides)";
        }
        break;

      case "destinationCity":
        if (!value.trim()) {
          return "Veuillez entrer votre ville de destination (pas d'espaces vides)";
        }
        break;

      case "departureDate":
        if (!value.trim()) {
          return "Veuillez choisir une date.";
        } else {
          const today = new Date();
          const selectedDate = new Date(value);

          // Vérifier si la date sélectionnée est avant aujourd'hui
          if (selectedDate < today.setHours(0, 0, 0, 0)) {
            return "La date de départ doit être dans le passe.";
          }
        }
        break;

      case "returnDate":
        if (!value.trim()) {
          return "Veuillez choisir une date.";
        } else {
          const departureDate = new Date(data.departureDate); // Utiliser la date de départ existante
          const returnDate = new Date(value);

          if (!data.departureDate) {
            return "Veuillez d'abord sélectionner une date de départ.";
          }

          if (returnDate <= departureDate) {
            return "La date de retour doit être postérieure à la date de départ.";
          }
        }
        break;
      case "numberOfPassengers":
        if (!value.trim()) {
          return "Veuillez choisir nombre de passagers.";

        }
        break;

      case "preferredAirlines":
        if (!value.trim()) {
          return "Veuillez choisir nombre de passagers.";

        }
        break;

      default:
        return "";
    }
    return ""; // Pas d'erreur

  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  return (
    <form
      className="serviceBilleterie animParent rechercheLogement"
      onSubmit={handleTicketingSubmit}
    >
      <div className="backSectionButton">
        <Button
          value={
            <>
              <IoArrowBackCircle />
              <span>Retour</span>
            </>
          }
          onClick={() => navigate("/etudiant/prestations-de-service")}
        />
      </div>
      <ErrorContext.Provider value={errors}>
        <PersonnalInfos
          title={"Services de Billetterie"}
          data={data}
          setData={setData}
          updateField={updateField}
          handleBlur={handleBlur}
        />
      </ErrorContext.Provider>
      <section className="detailsVoyageSection">
        <h1 className="student-attestation-title">Détails du Voyage</h1>
        <div className="detailsVoyageForm">
          <SelectLabel
            label={"Pays de départ"}
            name={"departureCountry"}
            onBlur={handleBlur}

            errorMessage={errors?.departureCountry}
            isInvalid={!!errors.departureCountry}
            onChange={(e) => updateField(e)}


          // onChange={(e) => handleCitiesOfSelectedCountry(e, 'depart')}
          >
            <option value="" selected disabled>
              Sélectionnez le pays de départ
            </option>

            {countries.map((country, index) => (

              <option
                key={index}
                value={country}
                selected={country === data.departureCountry}
              >
                {country}
              </option>
            ))}

          </SelectLabel>


          <InputLabel
            label={"Ville de départ"}
            name="departureCity"
            placeholder={'Entrez la ville de depart'}
            value={data.departureCity}
            onChange={(e) => updateField(e)}
            onBlur={handleBlur}
            errorMessage={errors?.departureCity}

          />

          <SelectLabel
            label={"Pays de destination"}
            name={"destinationCountry"}
            onBlur={handleBlur}
            errorMessage={errors?.destinationCountry}
            isInvalid={!!errors.destinationCountry}
            onChange={(e) => updateField(e)}



          >
            <option value="" selected disabled >
              Sélectionnez le pays de destination
            </option>

            {countries.map((country) => (
              <option key={country} value={country}
                selected={country === data.destinationCountry}
              >
                {country}
              </option>
            ))}
          </SelectLabel>


          <InputLabel
            label={"Ville de destination"}
            name="destinationCity"
            placeholder={'Entrez la ville de destination'}
            value={destinationCity}
            onChange={(e) => updateField(e)}
            onBlur={handleBlur}
            errorMessage={errors?.destinationCity}

          />


      
          <><InputLabel
            label={"Date de départ"}
            name="departureDate"
            value={data.departureDate}
            type="date"
            placeholder={""}
            onChange={(e) => updateField(e)}
            onBlur={handleBlur}
            errorMessage={errors?.departureDate}
            isInvalid={!!errors.departureDate}


          />

          </>
          <InputLabel
            label={"Date de retour"}
            name="returnDate"
            value={data.returnDate}
            type="date"
            placeholder={""}
            onChange={(e) => updateField(e)}
            onBlur={handleBlur}
            errorMessage={errors?.returnDate}

          />
          <SelectLabel
            label="Nombre de passagers"
            name="numberOfPassengers"
            onChange={(e) => updateField(e)}
            onBlur={handleBlur}
            errorMessage={errors?.numberOfPassengers}
            isInvalid={!!errors.numberOfPassengers}

            value={data.numberOfPassengers} // Définit la valeur sélectionnée actuelle
          >
            <option value="" disabled>
              Sélectionnez le nombre de passagers
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </SelectLabel>
          <SelectLabel
            label={"Classe de voyage"}
            name="travelClass"
            onChange={(e) => updateField(e)}
            value={data.travelClass} // Définit la valeur sélectionnée actuelle
            errorMessage={errors?.travelClass}
            onBlur={handleBlur}
            isInvalid={!!errors.travelClass}

          >
            <option selected value="" disabled>
              Sélectionnez la classe de voyage
            </option>
            <option value="economique">Économique</option>
            <option value="premium_economique">Premium Économique</option>
            <option value="business">Affaires</option>
            <option value="first_class">Première Classe</option>
          </SelectLabel>
          <InputLabel
            label={"Compagnies aériennes préférées(facultative)"}
            name={"preferredAirlines"}
            value={data.preferredAirlines}
            type="text"
            placeholder={"Entrez"}
            onChange={(e) => updateField(e)}
            onBlur={handleBlur}
            errorMessage={errors?.preferredAirlines}


          />

          <div className="inputLabel">
            <label htmlFor="specialNeeds" className="inputLabelLabel">
              Demandes Spéciales
            </label>
            <textarea
              name={"specialRequests"}
              id="specialNeeds"
              value={data.specialRequests}
              className="inputLabelInput"
              onChange={(e) => updateField(e)}
              onBlur={handleBlur}

            ></textarea>
          </div>
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Copie du passeport"}
            acceptanceText={"Passeport, ID Carte"}
            fieldName="files[passport_copy]"
            fichier={data.passport}
            setFichier={updatePassport}
            onBlur={handleBlur}
            errorMessage={errors?.passport}
          />

        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Document additionnel"}
            acceptanceText={"Document Pdf"}
            fieldName="files[additional_pdf]"
            fichier={data.documentAdditionel}
            setFichier={updateDocument}
            onBlur={handleBlur}
            errorMessage={errors?.documentAdditionel}

          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={"Preuve de paiement"}
            acceptanceText={"Document Pdf"}
            fieldName="files[payment_proof]"
            fichier={data.payementPreuve}
            setFichier={updatePayement}
            onBlur={handleBlur}
            errorMessage={errors?.payementPreuve}

          />
        </div>
      </section>
      <section className="documentsATelecharger">
        <h1 className="student-attestation-title">Documents à télécharger</h1>
        <div className="documentsATelechargerContenu">
          <FileToDownload
            titre={" Accord préliminaire"}
            acceptanceText={"Document Pdf"}
            fieldName="files[preliminary_acceptance]"
            fichier={data.preuveAcceptance}
            setFichier={updateAcceptance}
            onBlur={handleBlur}
            errorMessage={errors?.preuveAcceptance}

          />
        </div>
      </section>
      <div className="submitSectionButton">
        <button type="submit" className="buttonComponent">
          {
            !loading ? (
              <>
                <span>{data?.id ? "Modifier" : "Soumettre"}</span>
                <svg
                  viewBox="0 0 29 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="profil-fleche"
                >
                  <rect y="0.5" width="29" height="29" rx="14.5" fill="white" />
                  <path
                    d="M16.7277 9.43604L22.2918 15.0002L16.7277 20.5644M6.7085 15.0002H22.136"
                    stroke="#015192"
                    strokeWidth="1.375"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </>
            ) : (
              <Spinner animation="border" size="md" />
            )
          }

        </button>
      </div>
    </form>
  );
}
export function PersonnalInfos({ data, updateField, title, setData }) {
  const [errors, setErrors] = useState({});

  const updateCodeCountry = (code) => {
    setData((data) => ({
      ...data,
      codeCountry: code,
    }));
  };

  const validNomPattern = /^[A-Za-z\- ]+$/;
  const validNumberPattern = /^\d{7-30}$/;

  const validateField = (name, value) => {
    switch (name) {
      case "firstname":
        if (!value.trim()) {
          return "Veuillez entrer votre prénom (pas d'espaces vides)";
        } else if (!validNomPattern.test(value)) {
          return "Le prénom ne doit contenir que des lettres et des apostrophes.";
        }
        break;
      case "lastname":
        if (!value.trim()) {
          return "Veuillez entrer votre nom (pas d'espaces vides)";
        } else if (!validNomPattern.test(value)) {
          return "Le nom ne doit contenir que des lettres et des apostrophes.";
        }

        break;
      case "email":
        if (!value.trim()) {
          return "Veuillez entrer votre adresse email";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          return "Veuillez entrer une adresse email valide";
        }
        break;
      case "phone":
        if (!value.trim()) {
          return "Veuillez entrer votre numéro de téléphone";
        }
        break;
      case "birthPlace":
        if (!value.trim()) {
          return "Veuillez entrer votre lieux de naissance";
        }

        break;


      case "birthDate":
        if (!value.trim()) {
          return "Veuillez entrer votre date de naissance";
        } else if (new Date().toISOString().split("T")[0] <= data.birthDate) {
          return "La date de naissance ne peut pas être postérieure à aujourd'hui";
        }

        break;


      case "nationality":
        if (!value.trim()) {
          return "Veuillez entrer votre ville";
        } else if (value.length < 3) {
          return "Veuillez entrer une ville avec au moins 3 caractères";
        }
        break;


      default:
        return "";
    }
    return ""; // Pas d'erreur

  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    const errorMessage = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  return (
    <section className="rechercheLogementSection ">
      <h1 className="student-attestation-title">{title}</h1>
      <div className="rechercheLogementForm">


        <InputLabel
          label={"Prénom"}
          name="firstname"
          placeholder={"Entrez votre prénom"}
          value={data.firstname}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.firstname}
          onBlur={handleBlur}

        />

        <InputLabel
          label={"Nom de famille"}
          name="lastname"
          placeholder={"Entrez votre nom de famille"}
          value={data.lastname}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.lastname}
          onBlur={handleBlur}

        />


        <div className="composedInput">
          <div className="inputLabel">
            <label htmlFor="codeCountry">Numéro de Téléphone</label>
            <div style={{ height: "45px" }}>
              <CustomCountrySelect
                name="codeCountry"
                codeCountry={updateCodeCountry}
                dialCode={data.codeCountry}
              />
            </div>
          </div>

          <InputLabel
            label={"Nom de famille"}
            name="phone"
            placeholder={"XX-XXX-XXX"}
            value={data.phone}
            onChange={(e) => updateField(e)}
            errorMessage={errors?.phone}
            onBlur={handleBlur}

          />

        </div>

        <InputLabel
          label={"Lieu de naissance"}
          name="birthPlace"
          placeholder={"Entrez le lieu de naissance"}
          value={data.birthPlace}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.birthPlace}
          onBlur={handleBlur}

        />
        <InputLabel
          label={"Date de naissance"}
          name="birthDate"
          type="date"
          placeholder={"Entrez le lieu de naissance"}
          value={data.birthDate}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.birthDate}
          onBlur={handleBlur}

        />
        <InputLabel
          label={"Adresse email"}
          name="email"
          placeholder={"Entrez votre adresse email"}
          value={data.email}
          onChange={(e) => updateField(e)}
          errorMessage={errors?.email}
          onBlur={handleBlur}

        />

        <SelectLabel
          label={"Nationalité"}
          name={"nationality"}
          // onBlur={handleBlur}
          isInvalid={!!errors.nationality}
          errorMessage={errors?.nationality}
          onBlur={handleBlur}

          onChange={(e) => setData({ ...data, nationality: e.target.value })}
        >
          <option value="">
            Sélectionnez votre nationalité
          </option>

          {countries.map((country) => (
            <option
              key={`${country}`}
              value={country}
              selected={
                country ==
                data.nationality
              }
            >
              {country}
            </option>
          ))}


        </SelectLabel>

      </div>
    </section>
  );
}
