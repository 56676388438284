import React, { useEffect, useState } from "react";
import avatar from "../../images/Avatar.png";
import { Col, Row, Table } from "react-bootstrap";
import api from "../api/axiosInstance";
import "./Parent.css"; // Possibly rename if you want a separate Admin CSS
import show from "../../images/ShowEtu.png";
import delet from "../../images/deleteEtu.png";
import edit from "../../images/editEtu.png";
import { useNavigate } from "react-router";
import Pagination from "../../Components/Pagination";
import sanitizeImageUrl from "../../StudentModule/utils/anitizeImageUrl";

// 1) Import FilterComponent (all filters in one place)
import FilterComponent from "../../Components/Filters/FilterComponent";

function AdminTable() {
  const navigate = useNavigate();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [itemsPages, setItemsPages] = useState({});
  const [loading, setLoading] = useState(true);

  // For bulk delete
  const [showDelete, setShowDelete] = useState(false);
  const [selectedElement, setSelectedElement] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // Original data & filtered data
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);

  // Confirmation modal states
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [deleteMultiple, setDeleteMultiple] = useState(false);

  // 2) Filter states from FilterComponent
  const [emailQuery, setEmailQuery] = useState(""); // email
  const [searchQuery, setSearchQuery] = useState(""); // name/phone
  const [countryFilter, setCountryFilter] = useState("");

  // Fetch data on mount
  useEffect(() => {
    async function fetchContacts() {
      try {
        const response = await api.get("/admin/list_Admin/");
        const adminData = response.data.map((item) => ({
          Sno: item.id,
          Name: item.first_name || "N/A",
          Last: item.last_name || "",
          Code: item.country_code || "N/A",
          PhoneNumber: item.phone_number || "N/A",
          EmailID: item.email || "N/A",
          Country: item.country || "N/A",
          Role: item.role || "N/A",
          Picture: item.picture || "",
        }));

        setContacts(adminData);
        setFilteredContacts(adminData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchContacts();
  }, []);

  // 3) Combined filtering: email, name/phone, country
  useEffect(() => {
    let temp = [...contacts];

    // a) Email search
    if (emailQuery) {
      temp = temp.filter((c) =>
        c.EmailID.toLowerCase().includes(emailQuery.toLowerCase())
      );
    }

    // b) Name/phone
    if (searchQuery) {
      const lower = searchQuery.toLowerCase();
      temp = temp.filter((c) => {
        const fullName = (c.Name + " " + c.Last).toLowerCase();
        const phone = c.PhoneNumber;
        return fullName.includes(lower) || phone.includes(searchQuery);
      });
    }

    // c) Country
    if (countryFilter) {
      temp = temp.filter((c) =>
        c.Country.toLowerCase().includes(countryFilter.toLowerCase())
      );
    }

    setFilteredContacts(temp);
  }, [contacts, emailQuery, searchQuery, countryFilter]);

  // 4) Update pagination on filteredContacts or itemsPerPage changes
  useEffect(() => {
    const totalPages = Math.ceil(filteredContacts.length / itemsPerPage);
    let current = 0;
    const newItemsPages = {};

    for (let i = 1; i <= totalPages; i++) {
      newItemsPages[i] = filteredContacts.slice(
        current,
        current + itemsPerPage
      );
      current += itemsPerPage;
    }

    setItemsPages(newItemsPages);
    setCurrentPage(1);
  }, [filteredContacts, itemsPerPage]);

  // Handlers: detail, edit, add
  const handleEdit = (id) => navigate(`modifier/${id}`);
  const handleShow = (id) => navigate(`detail/${id}`);
  const handleAjouter = () => navigate("ajouter");

  // Checkbox logic
  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleChangeCheckbox2 = (Sno) => {
    if (selectedElement.some((ele) => ele.Sno === Sno)) {
      const filtered = selectedElement.filter((stu) => stu.Sno !== Sno);
      setSelectedElement(filtered);
    } else {
      const newEle = filteredContacts.find((ele) => ele.Sno === Sno);
      setSelectedElement([...selectedElement, newEle]);
    }
  };

  // "Select all"
  useEffect(() => {
    if (isChecked) {
      setSelectedElement(filteredContacts);
    } else {
      setSelectedElement([]);
    }
  }, [isChecked, filteredContacts]);

  // Show/hide bulk delete
  useEffect(() => {
    setShowDelete(selectedElement.length > 0);
  }, [selectedElement]);

  // Single or multiple delete
  const handleDelete = async (id) => {
    setSelectedDeleteId(id);
    setShowConfirmModal(true);
    setDeleteMultiple(false);
  };

  const handleDeleteMany = () => {
    setDeleteMultiple(true);
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    try {
      if (deleteMultiple) {
        // Bulk delete
        for (const item of selectedElement) {
          await api.delete(`/admin/admin/${item.Sno}/delete/`);
        }
        const remaining = filteredContacts.filter(
          (c) => !selectedElement.some((item) => item.Sno === c.Sno)
        );
        setFilteredContacts(remaining);
      } else {
        // Single delete
        await api.delete(`/admin/admin/${selectedDeleteId}/delete/`);
        const updated = filteredContacts.filter(
          (c) => c.Sno !== selectedDeleteId
        );
        setFilteredContacts(updated);
      }
      setShowConfirmModal(false);
      setSelectedElement([]);
      setShowDelete(false);
      setIsChecked(false);
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  };

  const cancelDelete = () => setShowConfirmModal(false);

  // 5) Reset filters
  const handleReset = () => {
    setEmailQuery("");
    setSearchQuery("");
    setCountryFilter("");
  };

  // 6) Unique countries
  const uniqueCountries = [...new Set(contacts.map((c) => c.Country))];

  return (
    <div>
      <Row>
        <Col sm={12} className="btnflex1">
          <button className="addStu" onClick={handleAjouter}>
            Ajouter Un Nouveau
          </button>
        </Col>
      </Row>

      {/* Single unified FilterComponent with email, name/phone, country */}
      <FilterComponent
        emailQuery={emailQuery}
        setEmailQuery={setEmailQuery}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        countryFilter={countryFilter}
        setCountryFilter={setCountryFilter}
        showStatusFilter={false} // no account status in Admin
        handleReset={handleReset}
        countries={uniqueCountries}
      />

      <Row className="mt-4" style={{ marginBottom: "-15px" }}>
        <div className="d-flex align-items-center justify-content-between">
          {showDelete && (
            <div
              className="d-flex align-items-center"
              onClick={handleDeleteMany}
            >
              <img
                src={delet}
                className="actionEtu"
                style={{ marginTop: "-15px" }}
                alt="delete"
              />
              <p className="para-etu-admin"> Supprimer </p>
            </div>
          )}
          <div className="d-flex align-items-center">
            <p
              style={{ marginRight: "15px", marginLeft: "15px" }}
              className="para-etu-admin"
            >
              Total: {filteredContacts.length}
            </p>
            <p style={{ marginRight: "20px" }} className="para-etu-admin">
              Comptes sélectionnés: {selectedElement.length}
            </p>
          </div>
        </div>
      </Row>

      <Row>
        <Col className="TableParent">
          <Table responsive>
            <thead>
              <tr>
                <th className="tdEtuAdm">
                  <input
                    type="checkbox"
                    name="all"
                    checked={isChecked}
                    onChange={handleChangeCheckbox}
                  />
                </th>
                <th className="ThParent">S.NO</th>
                <th className="ThParent">NOM COMPLET</th>
                <th className="ThParent">CODE DE PAYS</th>
                <th className="ThParent">NUMERO DE TELEPHONE</th>
                <th className="ThParent">EMAIL ID</th>
                <th className="ThParent">COUNTRY</th>
                <th className="ThParent">Role</th>
                <th className="ThParent">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    style={{ textAlign: "center", width: "100%" }}
                    colSpan={9}
                  >
                    Chargement des données...
                  </td>
                </tr>
              ) : (
                itemsPages[currentPage]?.map((val) => (
                  <tr key={val.Sno}>
                    <td className="tdEtuAdm">
                      <input
                        checked={selectedElement.some(
                          (ele) => ele.Sno === val.Sno
                        )}
                        type="checkbox"
                        onChange={() => handleChangeCheckbox2(val.Sno)}
                      />
                    </td>
                    <td className="TdParent NO">{val.Sno}</td>
                    <td className="TdParent NAME">
                      <img
                        src={
                          val.Picture ? sanitizeImageUrl(val.Picture) : avatar
                        }
                        alt="Avatar"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                        }}
                      />
                      <span
                        className="spantdname"
                        style={{ marginRight: "15px" }}
                      >
                        {val.Name} {val.Last}
                      </span>
                    </td>
                    <td className="TdParent PH">{val.Code}</td>
                    <td className="TdParent PH">{val.PhoneNumber}</td>
                    <td className="TdParent EM">{val.EmailID}</td>
                    <td className="TdParent CT">{val.Country}</td>
                    <td className="TdParent REL">{val.Role}</td>
                    <td className="TdParent ACT">
                      <img
                        src={show}
                        onClick={() => handleShow(val.Sno)}
                        className="actionEtu"
                        alt="show"
                      />
                      <img
                        src={edit}
                        onClick={() => handleEdit(val.Sno)}
                        className="actionEtu"
                        alt="Edit"
                      />
                      <img
                        src={delet}
                        onClick={() => handleDelete(val.Sno)}
                        className="actionEtu"
                        alt="Delete"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <Pagination
            itemsPages={itemsPages}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Col>
      </Row>

      {showConfirmModal && (
        <div className="confirmation-modal">
          <div className="modal-content">
            <p>
              {deleteMultiple
                ? "Êtes-vous sûr de vouloir supprimer ces éléments ?"
                : "Êtes-vous sûr de vouloir supprimer cet élément ?"}
            </p>
            <div className="modal-buttons">
              <button onClick={confirmDelete} className="btn-confirm">
                Confirmer
              </button>
              <button onClick={cancelDelete} className="btn-cancel">
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminTable;
